// Components
import Header from 'components/molecules/Header/Header'
import CardItem from '../CardItem/CardItem'
import Select from 'components/molecules/Select/Select'
import {
  AvatarWithAddPhoto,
  InstrumentTrays,
  Notes,
  PositionSelector,
} from './CardViewComponents'
import TextInput from 'components/molecules/TextInput/TextInput'
import PaginatedSearch from 'components/organisms/PaginatedSearch/PaginatedSearch'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import Warning from 'components/molecules/Warning/Warning'
import Button from 'components/molecules/Button/Button'

// MUI
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'

// Logic
import useCardViewLogic from './CardView.logic'

// Types
import { Option } from 'common/types'

// Style
import './CardView.scss'

// Other
import classNames from 'classnames'
import { isDesktop } from 'react-device-detect'
import { RepItem } from '../RepItem/RepItem'

const CardView = ({ mode }: { mode: 'create' | 'view' }) => {
  const {
    implants,
    biologics,
    consumables,
    isMobile,
    canSave,
    isLoading,
    searchValue,
    isDrawerOpen,
    isLoadingCard,
    preferenceCard,
    equipmentItems,
    isCreatingCard,
    selectedVendor,
    isUpdatingCard,
    surgeonsOptions,
    instrumentTrays,
    procedureDetails,
    linkedSalesReps,
    selectedImplantsCompany,
    selectedBiologicCompany,
    selectedCompanies,
    isLoadingSurgeons,
    isLoadingCompanies,
    surgeonInformation,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    productsInput,
    groupedRepsByCompany,
    repsOptions,
    handleAddProducts,
    handleProductsInputChange,
    groupByCompany,
    customOptions,
    setConsumables,
    setSearchValue,
    setIsDrawerOpen,
    handleClickBack,
    handleAddSalesRep,
    setSelectedVendor,
    handleInputChange,
    handleCloseDrawer,
    setEquipmentItems,
    handleSearchSelect,
    setInstrumentTrays,
    handleDeleteProduct,
    handlePositionChange,
    createPreferenceCard,
    handleCheckboxChange,
    handleNotifySalesRep,
    handleDeleteSalesRep,
    handleEquipmentItemChange,
    handleSurgeonInformationChange,
  } = useCardViewLogic({ mode })

  const isCreateMode = mode === 'create'
  const isViewMode = mode === 'view'

  return (
    <div
      className={classNames('preference-card-view', {
        'is-mobile': isMobile,
      })}
    >
      <Header
        parent="Reports"
        title="Preference card"
        onBackClick={handleClickBack}
        hasBack
        isMobile={isMobile}
        actions={
          isCreateMode
            ? [
                {
                  title: 'Save',
                  onClick: () => createPreferenceCard(),
                  isLoading: isCreatingCard || isUpdatingCard,
                  disabled: !canSave,
                },
              ]
            : []
        }
      />

      <div className="preference-card-header">
        {isViewMode && (
          <CardItem
            isHeader
            surgeon={surgeonInformation?.physicianName?.name ?? ''}
            procedure={surgeonInformation?.procedureName ?? ''}
            isSaving={isCreatingCard || isUpdatingCard}
            isLoading={isLoadingCard}
          />
        )}
      </div>

      <div className="body">
        <div>
          {/* Surgeon information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Surgeon information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="section">
                  {isViewMode ? (
                    <TextField
                      label="Physician Name"
                      placeholder="Physician Name"
                      disabled={isViewMode}
                      value={surgeonInformation?.physicianName?.name}
                      InputLabelProps={{
                        shrink: surgeonInformation?.physicianName
                          ? true
                          : false,
                      }}
                    />
                  ) : (
                    <Select
                      isLoading={isLoadingSurgeons}
                      label="Physician Name"
                      options={surgeonsOptions || []}
                      onSelect={(value) => {
                        handleSurgeonInformationChange('physicianName', value)
                      }}
                      selectedValue={
                        (surgeonInformation?.physicianName?.name as string) ??
                        ''
                      }
                    />
                  )}
                  {isViewMode ? (
                    <TextField
                      label="Procedure Name"
                      placeholder="Procedure Name"
                      disabled={isViewMode}
                      value={surgeonInformation?.procedureName}
                      onChange={(e) => {
                        handleSurgeonInformationChange(
                          'procedureName',
                          e.target.value
                        )
                      }}
                      InputLabelProps={{
                        shrink: surgeonInformation?.procedureName
                          ? true
                          : false,
                      }}
                    />
                  ) : (
                    <Select
                      isLoading={isLoadingProcedureTypes}
                      label="Procedure Name"
                      options={procedureTypesOptions || []}
                      onSelect={(value) => {
                        handleSurgeonInformationChange(
                          'procedureName',
                          value.name
                        )
                      }}
                      selectedValue={surgeonInformation?.procedureName ?? ''}
                    />
                  )}

                  <Select
                    label="Dominant Hand"
                    options={[
                      { id: 1, name: 'Left' },
                      { id: 2, name: 'Right' },
                    ]}
                    onSelect={(value) => {
                      handleSurgeonInformationChange('dominantHand', value)
                    }}
                    selectedValue={surgeonInformation?.dominantHand?.name ?? ''}
                  />

                  <TextField
                    label="Glove Size and Type"
                    placeholder="Glove Size and Type"
                    value={surgeonInformation?.gloveSize}
                    onChange={(e) => {
                      handleSurgeonInformationChange(
                        'gloveSize',
                        e.target.value
                      )
                    }}
                    InputLabelProps={{
                      shrink: surgeonInformation?.gloveSize ? true : false,
                    }}
                  />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surgeonInformation?.gloveType === 'Single'}
                          onChange={(e) => {
                            handleSurgeonInformationChange(
                              'gloveType',
                              'Single'
                            )
                          }}
                        />
                      }
                      label="Single"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={surgeonInformation?.gloveType === 'Double'}
                          onChange={(e) => {
                            handleSurgeonInformationChange(
                              'gloveType',
                              'Double'
                            )
                          }}
                        />
                      }
                      label="Double"
                    />
                  </div>
                </div>
                <div className="section">
                  <AvatarWithAddPhoto
                    disabled={isLoading}
                    defaultImage={surgeonInformation?.picture}
                    onImageChange={(image) => {
                      if (image)
                        handleSurgeonInformationChange('picture', image)
                    }}
                  />
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={surgeonInformation?.notes}
                onNotesChange={(notes) =>
                  handleSurgeonInformationChange('notes', notes)
                }
              />
            </AccordionDetails>
          </Accordion>

          {/* Procedure information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Procedure information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="section">
                  <PositionSelector
                    onChange={handlePositionChange}
                    selected={preferenceCard.procedureDetails?.selectedPosition}
                  />

                  <div>
                    <div className="item">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              preferenceCard.procedureDetails?.tableRotation ===
                              true
                            }
                            onChange={(e) => {
                              handleInputChange(
                                'tableRotation',
                                e.target.checked
                              )
                            }}
                          />
                        }
                        label="Table Rotation"
                      />
                    </div>
                    {preferenceCard.procedureDetails?.tableRotation && (
                      <div className="table-rotation-options">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography>Left</Typography>
                          <Switch
                            onChange={(e) => {
                              handleInputChange(
                                'tableRotationSide',
                                e.target.checked ? 'Right' : 'Left'
                              )
                            }}
                            checked={
                              preferenceCard.procedureDetails
                                .tableRotationSide === 'Right' ?? false
                            }
                          />
                          <Typography>Right</Typography>
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '45' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '45' : ''
                                  )
                                }}
                              />
                            }
                            label="45°"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '90' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '90' : ''
                                  )
                                }}
                              />
                            }
                            label="90°"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  preferenceCard.procedureDetails
                                    .tableRotationDegree === '180' ?? false
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    'tableRotationDegree',
                                    e.target.checked ? '180' : ''
                                  )
                                }}
                              />
                            }
                            label="180°"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="section">
                  <TextField
                    label="Skin Prep"
                    placeholder="Skin Prep"
                    value={procedureDetails.skinPrep}
                    onChange={(e) =>
                      handleInputChange('skinPrep', e.target.value)
                    }
                    InputLabelProps={
                      procedureDetails.skinPrep
                        ? { shrink: true }
                        : { shrink: false }
                    }
                  />
                  <TextField
                    label="Medication"
                    placeholder="Medication"
                    value={procedureDetails.medication}
                    onChange={(e) =>
                      handleInputChange('medication', e.target.value)
                    }
                    InputLabelProps={
                      procedureDetails.medication
                        ? { shrink: true }
                        : { shrink: false }
                    }
                  />
                  <TextField
                    label="Padding/ Restraints"
                    placeholder="Padding/ Restraints"
                    value={procedureDetails.paddingRestrains}
                    onChange={(e) =>
                      handleInputChange('paddingRestrains', e.target.value)
                    }
                    InputLabelProps={
                      procedureDetails.paddingRestrains
                        ? { shrink: true }
                        : { shrink: false }
                    }
                  />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.urinaryDrain}
                          onChange={(e) =>
                            handleInputChange('urinaryDrain', e.target.checked)
                          }
                        />
                      }
                      label="Urinary Drain"
                    />
                  </div>
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.ptWarmer}
                          onChange={(e) =>
                            handleInputChange('ptWarmer', e.target.checked)
                          }
                        />
                      }
                      label="Patient Warmer"
                    />
                  </div>
                  <Divider />
                  <div className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={procedureDetails.DVTProhylaxis === true}
                          onChange={(e) =>
                            handleInputChange('DVTProhylaxis', e.target.checked)
                          }
                        />
                      }
                      label="DVT Prohylaxis"
                    />
                    {procedureDetails.DVTProhylaxis && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.stockings}
                              onChange={(e) =>
                                handleInputChange('stockings', e.target.checked)
                              }
                            />
                          }
                          label="Stockings"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.scd}
                              onChange={(e) =>
                                handleInputChange('scd', e.target.checked)
                              }
                            />
                          }
                          label="SCD"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.boots}
                              onChange={(e) =>
                                handleInputChange('boots', e.target.checked)
                              }
                            />
                          }
                          label="Boots"
                        />
                      </>
                    )}
                  </div>
                  {procedureDetails.DVTProhylaxis && (
                    <>
                      <div className="checkbox-group">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.anticoagSide === 'Left'}
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Left' : undefined
                                )
                              }
                            />
                          }
                          label="Left"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                procedureDetails.anticoagSide === 'Right'
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Right' : undefined
                                )
                              }
                            />
                          }
                          label="Right"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                procedureDetails.anticoagSide === 'Bilateral'
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  'anticoagSide',
                                  e.target.checked ? 'Bilateral' : undefined
                                )
                              }
                            />
                          }
                          label="Bilateral"
                        />
                      </div>
                      <div className="flex center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={procedureDetails.AnticoagulantRX}
                              onChange={(e) =>
                                handleInputChange(
                                  'AnticoagulantRX',
                                  e.target.checked
                                    ? 'AnticoagulantRX'
                                    : undefined
                                )
                              }
                            />
                          }
                          label="Anticoagulant Rx"
                        />
                        {procedureDetails.AnticoagulantRX && (
                          <TextField
                            label="Rx name"
                            placeholder="Rx name"
                            value={procedureDetails.RXName}
                            onChange={(e) =>
                              handleInputChange('RXName', e.target.value)
                            }
                          />
                        )}
                      </div>
                    </>
                  )}
                  {procedureDetails.DVTProhylaxis && (
                    <Notes
                      title="Anticoagulant Rx notes"
                      initialNotes={procedureDetails.anticoagNotes}
                      onNotesChange={(notes) =>
                        handleInputChange('anticoagNotes', notes)
                      }
                    />
                  )}
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={procedureDetails.notes}
                onNotesChange={(notes) => handleInputChange('notes', notes)}
              />
            </AccordionDetails>
          </Accordion>

          {/* Equipment information */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Equipment information</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container col half-width equipments">
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Monopolar as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Monopolar',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Monopolar"
                    />
                  </div>
                  {(equipmentItems.options[0]?.Monopolar as boolean) && (
                    <>
                      <div className="text-box item">
                        <Typography variant="h3">Cut</Typography>
                        <TextField
                          size="small"
                          label="Settings"
                          placeholder="Settings"
                          value={
                            equipmentItems.options[0]?.['Cut(Monopolar)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Cut(Monopolar)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className="text-box item">
                        <Typography variant="h3">Coag</Typography>
                        <TextField
                          size="small"
                          label="Settings"
                          placeholder="Settings"
                          value={
                            equipmentItems.options[0]?.['Coag(Monopolar)'] ?? ''
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Coag(Monopolar)',
                              e.target.value
                            )
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Bipolar as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Bipolar',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Bipolar"
                    />
                  </div>
                  {(equipmentItems.options[0]?.Bipolar as boolean) && (
                    <div className="text-box item">
                      <Typography variant="h3">Coag</Typography>
                      <TextField
                        size="small"
                        label="Settings"
                        placeholder="Settings"
                        value={
                          equipmentItems.options[0]?.['Coag(Bipolar)'] ?? ''
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Coag(Bipolar)',
                            e.target.value
                          )
                        }}
                      />
                    </div>
                  )}
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Spine table'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Spine table',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Spine table"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Hand table'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Hand table',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Hand table"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.Mayfield as boolean) ??
                            false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Mayfield',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Mayfield"
                    />
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'GI Cart'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'GI Cart',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="GI Cart"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Arthroscopy Tower'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Arthroscopy Tower',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Arthroscopy Tower"
                    />
                  </div>
                  <div className="item">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (equipmentItems.options[0]?.[
                              'Laparoscopy Tower'
                            ] as boolean) ?? false
                          }
                          onChange={(e) => {
                            handleEquipmentItemChange(
                              0,
                              'Laparoscopy Tower',
                              e.target.checked
                            )
                          }}
                        />
                      }
                      label="Laparoscopy Tower"
                    />
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Robotics as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Robotics',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Robotics"
                  />
                  {(equipmentItems.options[0]?.Robotics as boolean) && (
                    <TextField
                      size="small"
                      label="Settings"
                      placeholder="Settings"
                      value={
                        equipmentItems.options[0]?.['Robotics Settings'] ?? ''
                      }
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Robotics Settings',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Robotics Settings']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Drill as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Drill',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Drill"
                  />
                  {(equipmentItems.options[0]?.Drill as boolean) && (
                    <TextField
                      size="small"
                      label="Drill notes"
                      placeholder="Drill notes"
                      value={equipmentItems.options[0]?.['Drill notes'] ?? ''}
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Drill notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Drill notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Laser as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Laser',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Laser"
                  />
                  {(equipmentItems.options[0]?.Laser as boolean) && (
                    <TextField
                      size="small"
                      label="Laser notes"
                      placeholder="Laser notes"
                      value={equipmentItems.options[0]?.['Laser notes'] ?? ''}
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Laser notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Laser notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.[
                            'Cell Saver'
                          ] as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Cell Saver',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Cell Saver"
                  />
                  {(equipmentItems.options[0]?.['Cell Saver'] as boolean) && (
                    <TextField
                      size="small"
                      label="Cell Saver notes"
                      placeholder="Cell Saver notes"
                      value={
                        equipmentItems.options[0]?.['Cell Saver notes'] ?? ''
                      }
                      onChange={(e) => {
                        handleEquipmentItemChange(
                          0,
                          'Cell Saver notes',
                          e.target.value
                        )
                      }}
                      InputLabelProps={
                        equipmentItems.options[0]?.['Cell Saver notes']
                          ? { shrink: true }
                          : { shrink: false }
                      }
                    />
                  )}
                </div>
                <Divider />
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.[
                            'C Arm/ Flouro'
                          ] as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'C Arm/ Flouro',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="C Arm/ Flouro"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Microscope as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Microscope',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Microscope"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]
                            ?.Neuromonitoring as boolean) ?? false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Neuromonitoring',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Neuromonitoring"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Stealth as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Stealth',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Stealth"
                  />
                </div>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          (equipmentItems.options[0]?.Bookwalter as boolean) ??
                          false
                        }
                        onChange={(e) => {
                          handleEquipmentItemChange(
                            0,
                            'Bookwalter',
                            e.target.checked
                          )
                        }}
                      />
                    }
                    label="Bookwalter"
                  />
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={equipmentItems.notes}
                onNotesChange={(notes) => {
                  setEquipmentItems((prev) => ({
                    ...prev,
                    notes,
                  }))
                }}
              />
            </AccordionDetails>
          </Accordion>

          {/* Instrument Trays */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Instrument Trays</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container col">
                <InstrumentTrays
                  selectedInstruments={instrumentTrays?.instruments}
                  options={[
                    'Basic',
                    'Burn',
                    'Basic Urology',
                    'Craniotomy',
                    'Special Plastic',
                    'Ortho Basic',
                    'GYN',
                    'Midface',
                    'Basic General',
                    'Spine',
                    'Cardiac',
                    'Mandible',
                    'Laparotomy',
                    'Ortho Fx',
                    'ENT',
                  ]}
                  onChange={(selectedOptions) => {
                    setInstrumentTrays((prev) => ({
                      ...prev,
                      instruments: selectedOptions,
                    }))
                  }}
                />
                <Divider />
                <Notes
                  title="Other Notes"
                  initialNotes={instrumentTrays?.notes}
                  onNotesChange={(notes) => {
                    setInstrumentTrays((prev) => ({
                      ...prev,
                      notes,
                    }))
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Implants */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Implants</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="col">
                  <TextInput
                    id="companyName"
                    label="Search companies"
                    name="companyName"
                    variant="outlined"
                    handleChangeFormData={() => {}}
                    value={selectedImplantsCompany?.name}
                    onClick={() => {
                      setIsDrawerOpen({ isOpen: true, type: 'implantsCompany' })
                    }}
                  />

                  <div className="implant-products-container">
                    <Typography variant="h2">Add Boxed</Typography>
                    <TextInput
                      id="boxedProducts"
                      placeholder="Add Boxed Products"
                      name="boxedProducts"
                      variant="outlined"
                      disabled={!(selectedImplantsCompany?.id as number)}
                      handleChangeFormData={(e) =>
                        handleProductsInputChange(e.target.value, 'boxed')
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddProducts(
                            productsInput?.boxed as string,
                            'boxed'
                          )
                        }
                      }}
                      value={productsInput?.boxed}
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={() =>
                              handleAddProducts(
                                productsInput?.boxed as string,
                                'boxed'
                              )
                            }
                            color="primary"
                            endIcon={isDesktop ? <KeyboardReturnIcon /> : null}
                          >
                            Add
                          </Button>
                        ),
                      }}
                    />
                    {Number(implants?.boxed?.length) > 0 ? (
                      <div className="selected-products">
                        {Object.entries(groupByCompany(implants?.boxed)).map(
                          ([companyName, products], index) => (
                            <div key={index} className="company-group">
                              <Typography variant="h4">
                                {companyName}
                              </Typography>
                              {products.map((item, productIndex) => (
                                <Product
                                  key={productIndex}
                                  item={item}
                                  onDelete={() =>
                                    handleDeleteProduct('boxed', item)
                                  }
                                />
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="implant-products-container">
                    <Typography variant="h2">Add Trays</Typography>
                    <TextInput
                      id="trayProducts"
                      placeholder="Add Tray Products"
                      name="trayProducts"
                      variant="outlined"
                      disabled={!(selectedImplantsCompany?.id as number)}
                      handleChangeFormData={(e) =>
                        handleProductsInputChange(e.target.value, 'trays')
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddProducts(
                            productsInput?.trays as string,
                            'trays'
                          )
                        }
                      }}
                      value={productsInput?.trays}
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={() =>
                              handleAddProducts(
                                productsInput?.trays as string,
                                'trays'
                              )
                            }
                            color="primary"
                            endIcon={isDesktop ? <KeyboardReturnIcon /> : null}
                          >
                            Add
                          </Button>
                        ),
                      }}
                    />
                    {Number(implants?.trays?.length) > 0 ? (
                      <div className="selected-products">
                        {Object.entries(groupByCompany(implants?.trays)).map(
                          ([companyName, products], index) => (
                            <div key={index} className="company-group">
                              <Typography variant="h4">
                                {companyName}
                              </Typography>
                              {products.map((item, productIndex) => (
                                <Product
                                  key={productIndex}
                                  item={item}
                                  onDelete={() =>
                                    handleDeleteProduct('trays', item)
                                  }
                                />
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="implant-products-container">
                    <Typography variant="h2">Add Other</Typography>
                    <TextInput
                      id="otherProducts"
                      placeholder="Add Other Products"
                      name="otherProducts"
                      variant="outlined"
                      disabled={!(selectedImplantsCompany?.id as number)}
                      handleChangeFormData={(e) =>
                        handleProductsInputChange(e.target.value, 'other')
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddProducts(
                            productsInput?.other as string,
                            'other'
                          )
                        }
                      }}
                      value={productsInput?.other}
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={() =>
                              handleAddProducts(
                                productsInput?.other as string,
                                'other'
                              )
                            }
                            color="primary"
                            endIcon={isDesktop ? <KeyboardReturnIcon /> : null}
                          >
                            Add
                          </Button>
                        ),
                      }}
                    />
                    {Number(implants?.other?.length) > 0 ? (
                      <div className="selected-products">
                        {Object.entries(groupByCompany(implants?.other)).map(
                          ([companyName, products], index) => (
                            <div key={index} className="company-group">
                              <Typography variant="h4">
                                {companyName}
                              </Typography>
                              {products.map((item, productIndex) => (
                                <Product
                                  key={productIndex}
                                  item={item}
                                  onDelete={() =>
                                    handleDeleteProduct('other', item)
                                  }
                                />
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Biologics */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Biologics</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="products-container">
                <TextInput
                  id="companyName"
                  label="Search companies"
                  name="companyName"
                  variant="outlined"
                  handleChangeFormData={() => {}}
                  value={selectedBiologicCompany?.name}
                  onClick={() => {
                    setIsDrawerOpen({ isOpen: true, type: 'biologicsCompany' })
                  }}
                />

                <div className="implant-products-container">
                  <Typography variant="h2">Add Biologic Products</Typography>
                  <TextInput
                    id="biologicProducts"
                    placeholder="Add Biologic Products"
                    name="biologicProducts"
                    variant="outlined"
                    disabled={!(selectedBiologicCompany?.id as number)}
                    handleChangeFormData={(e) =>
                      handleProductsInputChange(e.target.value, 'biologic')
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAddProducts(
                          productsInput?.biologic as string,
                          'biologic'
                        )
                      }
                    }}
                    value={productsInput?.biologic}
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={() =>
                            handleAddProducts(
                              productsInput?.biologic as string,
                              'biologic'
                            )
                          }
                          color="primary"
                          endIcon={isDesktop ? <KeyboardReturnIcon /> : null}
                        >
                          Add
                        </Button>
                      ),
                    }}
                  />
                  {Number(biologics?.length) > 0 ? (
                    <div className="selected-products">
                      {Object.entries(groupByCompany(biologics)).map(
                        ([companyName, products], index) => (
                          <div key={index} className="company-group">
                            <Typography variant="h4">{companyName}</Typography>
                            {products.map((item, productIndex) => (
                              <Product
                                key={productIndex}
                                item={item}
                                onDelete={() =>
                                  handleDeleteProduct('biologic', item)
                                }
                              />
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Consumables */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Consumables</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="details-container">
                <div className="row">
                  {/* 1 */}
                  <div className="col">
                    <div className="section">
                      <Notes
                        initialNotes={consumables.pack}
                        title="Pack"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            pack: notes,
                          }))
                        }}
                      />
                    </div>

                    <div className="col">
                      <div className="section">
                        <Notes
                          initialNotes={consumables.blades}
                          title="Blades"
                          onNotesChange={(notes) => {
                            setConsumables((prev) => ({
                              ...prev,
                              blades: notes,
                            }))
                          }}
                        />
                      </div>
                      <div className="row center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 10
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(10, e.target.checked)
                              }
                            />
                          }
                          label="10"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 11
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(11, e.target.checked)
                              }
                            />
                          }
                          label="11"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                consumables.bladesCount?.some(
                                  (count) => count === 15
                                ) ?? false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(15, e.target.checked)
                              }
                            />
                          }
                          label="15"
                        />
                      </div>
                    </div>
                    <div className="section">
                      <Notes
                        initialNotes={consumables.burrs}
                        title="Burrs"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            burrs: notes,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div className="col">
                  <div className="section">
                    <Notes
                      initialNotes={consumables.drains}
                      title="Drains"
                      onNotesChange={(notes) => {
                        setConsumables((prev) => ({
                          ...prev,
                          drains: notes,
                        }))
                      }}
                    />
                  </div>
                  <div className="col">
                    <div className="section">
                      <Notes
                        initialNotes={consumables.sutures}
                        title="Sutures"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            sutures: notes,
                          }))
                        }}
                      />
                    </div>
                    <div className="row center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.skinStapler ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                skinStapler: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Skin Stapler"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.steriStrips ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                steriStrips: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Steri Strips"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={consumables.dermabond ?? false}
                            onChange={(e) => {
                              setConsumables((prev) => ({
                                ...prev,
                                dermabond: e.target.checked,
                              }))
                            }}
                          />
                        }
                        label="Dermabond"
                      />
                    </div>
                    <div className="section">
                      <Notes
                        initialNotes={consumables.dressings}
                        title="Dressings"
                        onNotesChange={(notes) => {
                          setConsumables((prev) => ({
                            ...prev,
                            dressings: notes,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <Notes
                title="Other Notes"
                initialNotes={consumables.consumablesNotes}
                onNotesChange={(notes) => {
                  setConsumables((prev) => ({
                    ...prev,
                    consumablesNotes: notes,
                  }))
                }}
              />
            </AccordionDetails>
          </Accordion>

          {/* Link to Sales Rep */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Link to Sales Reps</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {!selectedCompanies?.length && (
                <Warning
                  type="info"
                  text="Please select the used implants to access the vendors."
                />
              )}
              <AutocompleteInput
                disabled={!selectedCompanies?.length}
                options={selectedCompanies || []}
                handleChangeFormData={(e) =>
                  setSelectedVendor(e.target.value as Option)
                }
                variant="outlined"
                placeholder="Select Vendor"
                value={selectedVendor}
              />
              <AutocompleteInput
                disabled={!selectedCompanies?.length}
                handleChangeFormData={handleAddSalesRep}
                options={repsOptions}
                variant="outlined"
                placeholder="Search Sales Reps"
                showEmail
                sx={{
                  mt: 0,
                }}
              />

              <div className="selected-products">
                {Object.values(groupedRepsByCompany).map(
                  ({ companyName, reps }) => (
                    <div key={companyName} className="company-group">
                      <h3>{companyName}</h3>
                      {reps.map((item) => (
                        <RepItem
                          key={item?.email}
                          rep={item}
                          notifyEmail={item.sendEmail}
                          notifySms={item.sendSms}
                          companyName={companyName}
                          handleSendSurgeryToRep={handleNotifySalesRep}
                          isLoading={false}
                          selectedType="EMAIL"
                          handleDelete={handleDeleteSalesRep}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <PaginatedSearch
        key={isDrawerOpen.type}
        isOpen={isDrawerOpen.isOpen}
        onClose={handleCloseDrawer}
        value={searchValue}
        isPagination
        isFetching={false}
        name={isDrawerOpen.type}
        placeholder="Search"
        searchValue={searchValue}
        onChange={(value) => setSearchValue(value)}
        isLoading={isLoadingCompanies}
        options={customOptions()}
        onSelect={(option) => {
          handleSearchSelect(option)
          setSearchValue('')
          setIsDrawerOpen({ isOpen: false })
        }}
      />
    </div>
  )
}

const Product = ({
  item,
  hasSwitch = false,
  value,
  onDelete,
  onChange,
}: {
  item: Option
  hasSwitch?: boolean
  value?: boolean
  onDelete: (item: Option) => void
  onChange?: (bool: boolean) => void
}) => (
  <div className="product">
    <div className="name">{item.name}</div>
    <div className="actions">
      {hasSwitch && (
        <div className="invite-switch">
          <Typography variant="caption">Notify</Typography>
          <Switch
            checked={value}
            onChange={(e) => onChange?.(e.target.checked)}
          />
        </div>
      )}
      <IconButton onClick={() => onDelete(item)}>
        <DeleteIcon />
      </IconButton>
    </div>
  </div>
)

export default CardView
