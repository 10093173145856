import { useEffect } from 'react'

// Services
import { useCreateSurgery, useGetPatients } from 'lib/apollo/hooks'
import { useGetSurgeons } from 'lib/services/api/reports-service/preference-card/getSurgeons'

// Types
import { useGetProcedureTypes } from 'lib/services/api/reports-service/preference-card/getProcedureTypes'
import { ProcedureFormValues } from './AddProcedure.types'
import { Option } from 'common/types'

// Router
import { useNavigate } from 'react-router-dom'

// Other
import dayjs from 'lib/dayjs'
import { useForm } from 'react-hook-form'
import { rawLocations } from './locations'
import { enqueueSnackbar } from 'notistack'

const useAddProcedureLogic = () => {
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProcedureFormValues>({
    defaultValues: {
      patient: null,
      mrNumber: null,
      patientDob: null,
      timeOfProcedure: null,
      surgeon: null,
      procedure: null,
      procedureLocation: null,
    },
    mode: 'onChange',
  })

  const [create, { loading: isLoadingCreateSurgery }] = useCreateSurgery()
  const { data: rawPatients, loading: isLoadingPatients } = useGetPatients({
    filter: undefined,
  })
  const { data: rawSurgeons, isLoading: isLoadingSurgeons } = useGetSurgeons()
  const { data: rawProcedures, isLoading: isLoadingProcedures } =
    useGetProcedureTypes()
  const patients: Option[] =
    rawPatients?.getPatients?.map((item) => ({
      id: item._id,
      name: `${item.firstName} ${item.lastName}`,
    })) ?? []
  const surgeons: Option[] =
    rawSurgeons?.surgeons?.map((item) => ({
      id: item.id,
      name: `${item.firstName} ${item.lastName}`,
    })) ?? []
  const procedures =
    rawProcedures?.procedures
      ?.filter((item) => item)
      ?.map((item) => ({
        id: item,
        name: item,
      })) || []

  const locations: Option[] =
    rawLocations
      ?.filter((location) => !location.isAssignableToDepartment)
      ?.map((item) => ({
        id: item.id,
        name: item.name,
      })) ?? []

  const selectedPatient = rawPatients?.getPatients.find(
    (item) => item._id === watch('patient')?.id
  )

  const onSubmit = (data: ProcedureFormValues) => {
    if (!data) return

    const {
      patient,
      dateOfProcedure,
      procedure,
      procedureLocation,
      surgeon,
      operatingRoomNumber,
    } = data
    create({
      variables: {
        surgery: {
          patientId: patient?.id as string,
          patientDob: selectedPatient?.dateOfBirth as string,
          surgeonId: surgeon?.id as string,
          procedureName: procedure?.name as string,
          procedureLocation: procedureLocation?.name as string,
          dateOfProcedure: dayjs(dateOfProcedure).format(
            'YYYY-MM-DD'
          ) as string,
          // timeOfProcedure: '14:30:00',
          operatingRoom: operatingRoomNumber,
        },
      },
    })
      .then((res) => {
        if (res.data?.createSurgery.success) {
          enqueueSnackbar('Procedure has been created successfully.', {
            variant: 'success',
          })
          navigate('/surgeries')
        } else {
          enqueueSnackbar(
            'Something went wrong white creating the procedure, Please try again.',
            {
              variant: 'error',
            }
          )
        }
      })
      .catch((err) => console.log(err))
  }

  const handleNavigateHome = () => {
    navigate('/surgeries')
  }

  useEffect(() => {
    if (selectedPatient) {
      setValue('patientDob', selectedPatient.dateOfBirth)
      setValue('mrNumber', selectedPatient.idMR)
    }
  }, [selectedPatient])

  return {
    errors,
    control,
    patients,
    surgeons,
    locations,
    procedures,
    isLoadingPatients,
    isLoadingSurgeons,
    isLoadingProcedures,
    isLoadingCreateSurgery,
    onSubmit,
    handleSubmit,
    handleNavigateHome,
  }
}

export default useAddProcedureLogic
