import { FC } from 'react'

// MUI
import { ButtonBase } from '@mui/material'

// Components
import Header from 'components/molecules/Header/Header'
import ProcessModal from './process-modal/ProcessModal'
import BottomBar from 'components/molecules/BottomBar/BottomBar'
import ScannedItems from './scanned-items/ScannedItems'
import Scanner from './scanner/Scanner'
import Button from 'components/molecules/Button/Button'

// Logic
import { useLogic } from './Scan.logic'

// Style
import './Scan.css'

// Icons
import BackArrow from 'assets/icons/svgs/BackArrow.svg'
import { Camera, ListAlt } from '@mui/icons-material'

// Context
import { ReceivingProvider } from '../receiveContext'

// Other
import { ProcessModalProvider } from './process-modal/ProcessModalProvider'
import ToastProvider from 'common/ToastProvider'

const ScanContent: FC = () => {
  const {
    itemType,
    randomKey,
    hasNewItems,
    scannedItem,
    selectedTab,
    processedItems,
    isLoadingSearch,
    otherItemsCount,
    implantItemsCount,
    processModalState,
    hardWareItemsCount,
    productRequestErrors,
    isLoadingReceiveItems,
    isScannerScreenMidSize,
    totalScannedItemsCount,
    isLoadingRequestProducts,
    navigate,
    handleScan,
    handleSave,
    setSelectedTab,
    handelDeleteItem,
    handleReceiveItems,
    handleCloseProcessModal,
  } = useLogic()
  return (
    <>
      <div
        className={`scanner-container ${
          isScannerScreenMidSize ? 'is-tablet' : ''
        }`}
        style={{
          height: isScannerScreenMidSize ? window.innerHeight - 120 : '',
          overflow: 'hidden',
        }}
      >
        {isScannerScreenMidSize ? (
          <div className="mobile-header">
            <div className="actions">
              <ButtonBase
                className="arrow"
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={BackArrow} alt="BackArrow" />
              </ButtonBase>
              {selectedTab === 0 ? (
                <div className="counter-flag">{totalScannedItemsCount}</div>
              ) : (
                <Button
                  isLoading={isLoadingReceiveItems || isLoadingRequestProducts}
                  disabled={!!!processedItems.length}
                  onClick={handleReceiveItems}
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        ) : (
          <Header
            title="Capture Products"
            parent="Receiving"
            hasBack
            onBackClick={() => navigate(-1)}
            actions={[
              {
                title: 'Submit',
                onClick() {
                  handleReceiveItems()
                },
                isLoading: isLoadingReceiveItems || isLoadingRequestProducts,
                disabled: !!!processedItems.length,
              },
            ]}
          />
        )}
        <div
          className={`body-container ${
            isScannerScreenMidSize ? 'is-tablet' : ''
          }`}
        >
          {isScannerScreenMidSize ? (
            <>
              {selectedTab === 0 && (
                <Scanner
                  errors={productRequestErrors || []}
                  processedItems={processedItems}
                  handleScan={handleScan}
                  hasNewItems={hasNewItems}
                  isLoadingSearch={isLoadingSearch}
                />
              )}
              {selectedTab === 1 && (
                <ScannedItems
                  errors={productRequestErrors}
                  handelDeleteItem={handelDeleteItem}
                  implantItemsCount={implantItemsCount}
                  otherItemsCount={otherItemsCount}
                  totalCount={totalScannedItemsCount}
                  processedItems={processedItems}
                  hardwareItemsCount={hardWareItemsCount}
                />
              )}
            </>
          ) : (
            <>
              <Scanner
                errors={productRequestErrors}
                processedItems={processedItems}
                handleScan={handleScan}
                hasNewItems={hasNewItems}
                isLoadingSearch={isLoadingSearch}
              />
              <ScannedItems
                errors={productRequestErrors}
                handelDeleteItem={handelDeleteItem}
                implantItemsCount={implantItemsCount}
                otherItemsCount={otherItemsCount}
                totalCount={totalScannedItemsCount}
                processedItems={processedItems}
                hardwareItemsCount={hardWareItemsCount}
              />
            </>
          )}
        </div>

        <ProcessModal
          key={randomKey}
          udi={scannedItem?.term.value}
          open={processModalState.isOpen}
          onClose={handleCloseProcessModal}
          expirationDate={
            scannedItem?.productionIdentifier?.expirationDate || null
          }
          lotBatch={scannedItem?.productionIdentifier?.lotBatch}
          modelNumber={scannedItem?.assets[0]?.versionModelNumber}
          companyId={scannedItem?.assets[0]?.companyId}
          name={scannedItem?.assets[0]?.gmdnPTName}
          serialNumber={scannedItem?.productionIdentifier?.serialNumber}
          manufacturingDate={
            scannedItem?.productionIdentifier?.manufacturingDate || null
          }
          description={scannedItem?.assets[0]?.deviceDescription}
          onSave={handleSave}
          company={scannedItem?.assets[0]?.company?.name}
          quantity={1}
          onCancel={handleCloseProcessModal}
          type={itemType}
        />
      </div>

      {isScannerScreenMidSize && (
        <BottomBar
          selected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
          buttons={[
            {
              icon: <Camera />,
              label: 'Scan',
            },
            {
              icon: <ListAlt />,
              label: 'Scanned Items',
            },
          ]}
        />
      )}
    </>
  )
}

const Scan = () => {
  return (
    <ReceivingProvider>
      <ProcessModalProvider>
        <ScanContent />
      </ProcessModalProvider>
    </ReceivingProvider>
  )
}

export default Scan
