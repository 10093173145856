import { useEffect, useState } from 'react'

import { useUpdateTrayItem } from 'lib/apollo/hooks'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'

import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { TrayStatus, UseEditTrayModalProps } from './EditTrayModal.types'
import { BetterIDTrayScrew, TrayPlate } from 'views/SPDLayout/SPD/SPD.types'
import { ItemResponse, Surgery } from 'common/types'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useUser } from 'app/User'

/**
 * Custom hook to handle the state and logic for editing a tray modal.
 *
 * @param {UseEditTrayModalProps} props - The props for the hook.
 * @param {ItemResponse} props.trayData - The data of the tray to be edited.
 * @param {BetterIDTrayScrew[]} [props.trayScrews] - The screws associated with the tray.
 * @returns {{
 *   traySurgeryId: string;
 *   setTraySurgeryId: React.Dispatch<React.SetStateAction<string>>;
 *   handleTraySurgeryIdChange: (id: string) => void;
 *   handleUpdateTrayItem: () => Promise<void>;
 *   updating: boolean;
 *   success: boolean;
 *   setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
 *   failed: boolean;
 *   trayStatus: TrayStatus | undefined;
 *   setTrayStatus: React.Dispatch<React.SetStateAction<TrayStatus | undefined>>;
 *   handleSurgeryChange: (surgery: Surgery) => void;
 * }} The state and functions for editing the tray item's data from our iservices database.
 */
const useEditTrayModal = ({
  trayData,
  trayScrews = [],
}: UseEditTrayModalProps) => {
  const { editTrayItem } = useUpdateTrayItem()
  const {
    trayScrews: spdTrayScrews,
    setTrayData,
    trayPlates,
    setTrayPlates,
  } = useSPD()
  const { user } = useUser()
  const { setProductData } = useSPDScan()

  const trayDetails = extractTrayJSONData(trayData)
  const initialSurgeryId = trayDetails?.surgeryId || ''
  const [traySurgeryId, setTraySurgeryId] = useState<string>(initialSurgeryId)
  const [success, setSuccess] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [trayStatus, setTrayStatus] = useState<TrayStatus>(undefined)
  const [selectedTraySurgery, setSelectedTraySurgery] = useState<
    Surgery | undefined
  >(undefined)

  useEffect(() => {
    handleUpdateTrayPlates(trayDetails?.plates)
  }, [trayDetails?.plates?.length])

  /**
   * Parses the product details from a string.
   *
   * @param {string} details - The product details as a string.
   * @returns {TrayItemProductDetails} The parsed product details.
   */
  const parseProductDetails = (details: string): TrayItemProductDetails =>
    JSON.parse(details)

  /**
   * Builds the new tray details.
   *
   * @param {TrayItemProductDetails} previousDetails - The previous tray details.
   * @param {BetterIDTrayScrew[]} screws - The screws associated with the tray.
   * @param {TrayPlate[]} plates - The plates associated with the tray.
   * @param {string} surgeryId - The ID of the surgery.
   * @param {Surgery | undefined} surgery - The details of the surgery.
   * @param {TrayStatus | undefined} status - The status of the tray.
   * @returns {TrayItemProductDetails} The new tray details.
   */
  const buildTrayDetails = (
    previousDetails: TrayItemProductDetails,
    screws: BetterIDTrayScrew[],
    plates: TrayPlate[],
    surgeryId: string,
    surgery: Surgery | undefined,
    status: TrayStatus | undefined
  ): TrayItemProductDetails => ({
    ...previousDetails,
    statusDetails: {
      ...previousDetails.statusDetails,
      lastUpdatedBy: user?.email,
      lastUpdatedAt: new Date().toISOString(),
    },
    surgeryId,
    screws,
    trayImage: previousDetails.trayImage || '',
    trayCategory: previousDetails.trayCategory || '',
    status,
    surgeryDetails: surgery,
    trayType: previousDetails.trayType,
    plates,
  })

  /**
   * Handles updating the tray item.
   */
  const handleUpdateTrayItem = async () => {
    setUpdating(true)

    const previousProductDetails = parseProductDetails(
      trayData.productDetails as string
    )
    const selectedSurgeryId =
      traySurgeryId || previousProductDetails.surgeryId || ''
    const selectedSurgery =
      selectedTraySurgery || previousProductDetails.surgeryDetails

    const newTrayDetails = buildTrayDetails(
      previousProductDetails,
      trayScrews.length
        ? trayScrews
        : spdTrayScrews.length
        ? spdTrayScrews
        : previousProductDetails.screws,
      trayPlates,
      selectedSurgeryId,
      selectedSurgery,
      trayStatus || previousProductDetails.status
    )

    const input: ItemResponse = {
      ...trayData,
      productDetails: JSON.stringify(newTrayDetails),
    }

    const isSuccess = await editTrayItem(input)

    setUpdating(false)
    if (isSuccess.editTrayItem) {
      setSuccess(true)
      setTrayStatus(undefined)
      setProductData([])
      setTrayData(null)
    } else {
      console.error('Failed to edit tray item')
      setFailed(true)
    }
  }

  /**
   * Handles changing the tray surgery ID.
   *
   * @param {string} id - The new surgery ID.
   */
  const handleTraySurgeryIdChange = (id: string) => {
    setTraySurgeryId((prevId) => (id === prevId ? '' : id))
  }

  /**
   * Handles changing the surgery.
   *
   * @param {Surgery} surgery - The new surgery.
   */
  const handleSurgeryChange = (surgery: Surgery) => {
    setTrayStatus('assigned')
    setSelectedTraySurgery(surgery)
    handleTraySurgeryIdChange(surgery._id)
  }

  /**
   * Handles updating the state of the trayPlates based on the tray details.
   *
   * @param {TrayPlate[]} plates The plates array in the tray details.
   */
  const handleUpdateTrayPlates = (plates: TrayPlate[] | undefined) => {
    if (plates) {
      setTrayPlates(plates)
    } else {
      setTrayPlates([])
    }
  }

  return {
    traySurgeryId,
    setTraySurgeryId,
    handleTraySurgeryIdChange,
    handleUpdateTrayItem,
    updating,
    success,
    setSuccess,
    failed,
    trayStatus,
    setTrayStatus,
    handleSurgeryChange,
  }
}

export default useEditTrayModal
