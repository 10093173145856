import { Route, Routes } from 'react-router-dom'

import InventorySheets from './InventorySheets/InventorySheets'
import MainTrayInfo from './MainTrayInfo/MainTrayInfo'

import './AddTray.scss'
import AnalyzeTray from './AnalyzeTray/AnalyzeTray'
import { useAddTrayContext } from './AddTray.context'
import { Alert, Snackbar } from '@mui/material'
import SubTrays from './SubTrays/SubTrays'
import TrayContent from './TrayContent/TrayContent'

const AddTray = () => {
  const {
    snackbarIcon,
    snackbarMessage,
    snackbarOpen,
    snackbarState,
    handleSnackbarClose,
  } = useAddTrayContext()
  return (
    <div className="add-tray-layout_wrapper">
      <Routes>
        <Route path="/" element={<MainTrayInfo />} />
        <Route path="/sub-trays" element={<SubTrays />} />
        <Route path="/sub-trays/:index" element={<TrayContent />} />
        <Route path="/inventory-sheets" element={<InventorySheets />} />
        <Route path="/analyze" element={<AnalyzeTray />} />
      </Routes>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AddTray
