import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { ArrowBack, QrCode, Cached } from '@mui/icons-material'
import { LoadingButton } from 'components/mui'

import useTrayConfiguration from 'views/TrayConfiguration/TrayConfiguration.logic'
import useAddTrayDetailsLogic from './AddTrayDetails.logic'
import { useUser } from 'app/User'

import './AddTrayDetails.scss'
import { useInventorySheetsContext } from 'views/TrayManagementLayout/AddTray/InventorySheets/InventorySheets.context'
import { useAddTrayContext } from 'views/TrayManagementLayout/AddTray/AddTray.context'
import { TrayType } from '../AddTraySelectType/AddTraySelectType.types'
import { StickerPrintContainer, printSticker } from '../TraySticker/TraySticker'
import { AddTrayDetailsConfirmation } from './AddTrayDetailsConfirmation'

const AddTrayDetails = () => {
  const { isRep, orgName } = useUser()
  const {
    trayBarcode,
    handleTrayBarcodeChange,
    handleShowScannerButtonClick,
    trayDescription,
    handleTrayDescriptionChange,
    selectedLocationId,
    handleSelectedLocationChange,
    trayVendor,
    handleTrayVendorChange,
    trayWeight,
    handleTrayWeightChange,
    renderDropOffDateTime,
    renderScanner,
    handleInventorySheetsClick,
    navigateToTrayManagement,
    handleAnalyzeTrayClick,
    isCreatingTrayItem,
    handleCreateTrayItem,
    generateTrayId,
  } = useAddTrayDetailsLogic()
  const {
    selectedTrayCategory,
    setSelectedTrayCategory,
    traySurgery,
    trayAddConfirmation,
    setTrayAddConfirmation,
  } = useAddTrayContext()
  const { locationsWithParents, findLocation } = useTrayConfiguration()
  const { inventorySheetFiles, isThereAnImageUploading } =
    useInventorySheetsContext()
  const boundPrintSticker = () =>
    printSticker({
      ownership: selectedTrayCategory || 'facility',
      vendor: trayVendor,
      description: trayDescription,
      id: trayBarcode,
      caseNumber: `CN-${traySurgery?.surgicalCase?.number}`,
    })

  return (
    <div className="add-tray-details_wrapper">
      <div className="header_wrapper">
        <Button
          size="small"
          className="back-button"
          onClick={navigateToTrayManagement}
        >
          <ArrowBack sx={{ mr: 0.5 }} /> BACK
        </Button>

        <Typography variant="h5">Tray Details</Typography>
      </div>

      {renderDropOffDateTime()}
      {renderScanner()}

      <TextField
        autoComplete="off"
        placeholder="Enter Tray ID (required)"
        fullWidth
        type="text"
        value={trayBarcode}
        onChange={handleTrayBarcodeChange}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    handleTrayBarcodeChange(
                      generateTrayId({
                        // TODO: Replace with org prefix when made available
                        prefix: orgName?.toUpperCase(),
                      })
                    )
                  }}
                  color="primary"
                >
                  <Cached />
                </IconButton>
              </InputAdornment>
              <InputAdornment position="end">
                <LoadingButton
                  className="show-scanner_button"
                  onClick={handleShowScannerButtonClick}
                >
                  <QrCode />
                </LoadingButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <FormControl fullWidth>
        <Select
          fullWidth
          variant="outlined"
          displayEmpty
          renderValue={(selected) =>
            !selected ? (
              <Typography variant="body1" color="gray">
                Select Tray Storage Location (required)
              </Typography>
            ) : (
              findLocation(selected as number)?.name
            )
          }
          value={selectedLocationId || ''}
          onChange={handleSelectedLocationChange}
        >
          <MenuItem disabled value="">
            Select Tray Storage Location
          </MenuItem>
          {locationsWithParents.map((location) => (
            <MenuItem
              data-testid="hardware-menuitem"
              style={{ cursor: 'pointer', fontSize: 16 }}
              key={location.id}
              value={location.id}
            >
              {location.name},{' '}
              {findLocation(location.parentLocationId as number)?.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          You can create new locations from the location management dashboard
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth>
        <Select
          data-testid="add-tray-location-storage_input"
          fullWidth
          variant="outlined"
          displayEmpty
          renderValue={(selected) =>
            !selected ? (
              <Typography variant="body1" color="gray">
                Select Tray {!isRep ? 'Manufacturer' : 'Vendor'} (required)
              </Typography>
            ) : (
              selected
            )
          }
          value={trayVendor}
          onChange={handleTrayVendorChange}
        >
          <MenuItem disabled value="">
            Select Tray {!isRep ? 'Manufacturer' : 'Vendor'}
          </MenuItem>
          {[
            'Arthrex',
            'B. Braun Melsungen',
            'Boston Scientific',
            'DePuy Synthes (Johnson & Johnson)',
            'Globus Medical',
            'Integra LifeSciences',
            'Medtronic',
            'NuVasive',
            'Smith & Nephew',
            'Stryker Corporation',
            'Wright Medical Group',
            'Zimmer Biomet',
          ].map((vendor) => (
            <MenuItem
              key={vendor}
              style={{ cursor: 'pointer', fontSize: 16 }}
              value={vendor}
            >
              {vendor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        autoComplete="off"
        placeholder="Enter Tray Details/ Description (required)"
        fullWidth
        multiline
        minRows={4}
        maxRows={7}
        type="text"
        value={trayDescription}
        onChange={handleTrayDescriptionChange}
        variant="outlined"
      />

      <TextField
        autoComplete="off"
        placeholder="Tray Weight lbs (optional)"
        fullWidth
        type="number"
        value={trayWeight || ''}
        onChange={handleTrayWeightChange}
        variant="outlined"
      />

      <div className="tray-category_selection">
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={selectedTrayCategory}
            onChange={(e) =>
              setSelectedTrayCategory(e.target.value as TrayType['category'])
            }
          >
            {(isRep ? ['consigned', 'loaner'] : ['facility']).map(
              (trayCategory) => (
                <FormControlLabel
                  key={trayCategory}
                  value={trayCategory}
                  control={<Radio />}
                  label={`${trayCategory
                    .charAt(0)
                    .toUpperCase()}${trayCategory.slice(1)}`}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
      </div>

      <div className="buttons_container">
        <div className="row">
          <LoadingButton
            loading={isThereAnImageUploading}
            variant="contained"
            onClick={handleInventorySheetsClick}
            endIcon={
              <Typography
                variant="subtitle2"
                className="inventory-sheets_count"
              >
                {inventorySheetFiles.length}
              </Typography>
            }
          >
            View/ Add {isRep && 'IFU Files +'} Inventory Sheets
          </LoadingButton>

          <LoadingButton
            disabled={!selectedTrayCategory}
            variant="contained"
            onClick={handleAnalyzeTrayClick}
          >
            View/ Capture Tray(s)
          </LoadingButton>
        </div>
        {typeof trayAddConfirmation === 'boolean' && (
          <>
            <Button
              className="print-sticker_button"
              variant="outlined"
              onClick={boundPrintSticker}
              disabled={
                !trayBarcode ||
                !trayDescription ||
                !trayVendor ||
                !selectedLocationId ||
                !selectedTrayCategory
              }
            >
              Print Tray Label
            </Button>
          </>
        )}
        <LoadingButton
          dataTestId="add-tray-submit_button"
          disabled={
            // isCreatingTrayItem ||
            !trayBarcode ||
            !trayDescription ||
            !selectedLocationId ||
            // isThereAnImageUploading ||
            !trayVendor // ||
            // (hasImplantTrays &&
            //   firstImplantTray &&
            //   firstImplantTray.content.screws.length === 0 &&
            //   selectedTrayCategory !== 'facility') ||
            // (isRep && inventorySheetFiles.length === 0)
          }
          loading={isCreatingTrayItem}
          onClick={() => {
            if (typeof trayAddConfirmation === 'boolean') {
              setTrayAddConfirmation(true)
            } else {
              handleCreateTrayItem()
            }
          }}
        >
          Submit Tray(s)
        </LoadingButton>
      </div>
      <AddTrayDetailsConfirmation
        isOpen={Boolean(trayAddConfirmation)}
        onClose={() => setTrayAddConfirmation(false)}
        printSticker={boundPrintSticker}
        onSubmit={handleCreateTrayItem}
      />
      <StickerPrintContainer />
    </div>
  )
}

export default AddTrayDetails
