import { useCallback, useEffect, useState } from 'react'
import dayjs from 'lib/dayjs'
import { animateScroll as scroll } from 'react-scroll'
import {
  useAssignTrayStatusAsCaseCompleteMutation,
  useSetSurgeryStatus,
} from 'lib/apollo/hooks'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { submitToEmrValidationSchema } from './submitToEmrValidationSchema'
import { AssetData, DerivedSurgeryStatus, Surgery } from 'common/types'
import { getExpiredAssetCount } from 'lib/utils/getExpiredAssetCount'
import { useUser } from 'app/User'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useRfSpongeCount } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext'
import { getUDIsWithQuantity } from 'lib/utils/data'
import useDecrementUsageMutation from 'lib/services/api/product-service/useUseItems'

interface SubmitToEMRProps {
  surgery: Surgery
  isGroupedAssetsEmpty: boolean
  dtmHardwareAssetData: AssetData[]
}

const SubmitToEMR = ({
  surgery,
  isGroupedAssetsEmpty,
  dtmHardwareAssetData,
}: SubmitToEMRProps) => {
  const { user } = useUser()
  const { assignTrayStatusAsCaseComplete } =
    useAssignTrayStatusAsCaseCompleteMutation()

  const [setSurgeryStatusSubmitted, setSurgeryStatusSubmittedMutation] =
    useSetSurgeryStatus(surgery._id)
  const { mutateAsync: decrementUsage } = useDecrementUsageMutation()

  const userName = `${user?.firstName} ${user?.lastName}`

  const numberOfExpired = getExpiredAssetCount(surgery.assetGroups)

  const { rfSpongeAssetGroupCount } = useRfSpongeCount()

  const { undocumentedScanCount, undocumentedScans } =
    useUndocumentedAssetsContext()

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [dtmTrayIds, setDTMTrayIds] = useState<number[]>([])

  useEffect(() => {
    const dtmTrayIdsArr = dtmHardwareAssetData
      ?.flatMap((asset) => asset.scans)
      ?.filter((scan) => scan.dtmScrewData)
      ?.map((scan) => Number(scan.dtmScrewData?.trayId))

    setDTMTrayIds(dtmTrayIdsArr)
  }, [undocumentedScans?.length])

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const handleSubmitRecord = useCallback(() => {
    setSurgeryStatusSubmitted({
      variables: {
        surgeryId: surgery._id,
        status: 'SUBMITTED',
        rep: userName,
        dateTime: dayjs().toISOString(),
      },
    })
      .then(() => {
        const assetsToDecrement = getUDIsWithQuantity(surgery.assetGroups)
        decrementUsage({
          items: assetsToDecrement,
        })
      })
      .finally(() => {
        scrollToTop()
      })
  }, [
    setSurgeryStatusSubmitted,
    surgery._id,
    surgery.assetGroups,
    userName,
    decrementUsage,
  ])

  const {
    isFormSubmitted,
    handleValidation,
    isSubmitDisabled,
    FormValidationAlertToasters,
    isFormValidated,
  } = useFormValidation({
    validationSchema: submitToEmrValidationSchema,
  })
  const [isSubmittedToEmr, setIsSubmittedToEmr] = useState(false)
  const handleSubmitToEmr = async (e: any) => {
    e.preventDefault()

    handleValidation(e)

    if (isFormValidated) {
      await handleUpdateTrayAssignment()
      handleSubmitRecord()
    }
  }
  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isSubmittedToEmr) {
      handleSubmitRecord()
      setIsSubmittedToEmr(true)
    }
  }, [isFormSubmitted, isFormValidated, isSubmittedToEmr, handleSubmitRecord])

  useEffect(() => {
    const hasMissingSpongeCount = rfSpongeAssetGroupCount.some((sponge) => {
      return (
        !sponge.accurateDeviceCount && !sponge.deviceCountCorrectiveActionTaken
      )
    })

    setDisableSubmit(
      setSurgeryStatusSubmittedMutation.loading
        ? false
        : isSubmitDisabled || isFormSubmitted || hasMissingSpongeCount
    )
  }, [
    setSurgeryStatusSubmittedMutation.loading,
    isSubmitDisabled,
    isFormSubmitted,
    rfSpongeAssetGroupCount,
  ])

  const handleUpdateTrayAssignment = async () => {
    if (dtmTrayIds.length === 0) {
      return
    }

    try {
      const trayId = dtmTrayIds[0]

      const assignResult = await assignTrayStatusAsCaseComplete(trayId)

      if (!assignResult) {
        throw new Error('Failed to assign tray status as case complete.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {FormValidationAlertToasters()}

      <form onSubmit={handleSubmitToEmr}>
        <input
          type="hidden"
          name="numberOfExpired"
          value={numberOfExpired?.expiredTotal ?? '0'}
        />

        <input
          type="hidden"
          name="undocumentedScansCount"
          value={undocumentedScanCount}
        />

        <input type="hidden" name="submitToEmrConfirm" value="false" />

        <LoadingButton
          type="submit"
          disabled={disableSubmit}
          loading={setSurgeryStatusSubmittedMutation.loading}
          loadingText="Submitting record to emr"
          sx={{ mb: 1.5 }}
        >
          {isGroupedAssetsEmpty ? 'Close Case' : 'Submit record to emr'}
        </LoadingButton>
      </form>
    </>
  )
}

export default SubmitToEMR
