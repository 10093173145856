import { useEffect, useRef, useState } from 'react'

// Components
import { TableRowProps } from '@mui/material'

// Services
import { useGetProcedureTypes } from 'lib/services/api/reports-service/preference-card/getProcedureTypes'

// Types
import {
  AddNewLaborCostProps,
  INewLaborCost,
  IUploadedLaborCost,
} from '../LaborCost.types'

// Other
import * as XLSX from 'xlsx'
import { useForm } from 'react-hook-form'

const useAddNewLaborCostLogic = ({
  onSave,
  mode,
  data,
}: AddNewLaborCostProps) => {
  const [uploadOption, setUploadOption] = useState<'manual' | 'upload'>(
    'manual'
  )
  const [isDisabled, setIsDisabled] = useState(false)
  const isManual = uploadOption === 'manual' || mode === 'create'
  const isUpload = uploadOption === 'upload'
  const isUpdate = mode === 'update'

  const { data: rawProcedures, isLoading: isLoadingProcedures } =
    useGetProcedureTypes()

  const procedures =
    rawProcedures?.procedures
      ?.filter((item) => item && item !== '-')
      ?.map((item) => ({
        id: item,
        name: item,
      })) || []

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<INewLaborCost>({
    defaultValues: {
      procedureType: null,
      rnCost: null,
      scrubTechCost: null,
    },
    mode: 'onChange',
  })

  const onSubmit = (data: INewLaborCost) => {
    if (!data && !isManual) return
    onSave(data, isUpdate ? 'update' : 'create')
  }

  const [uploadedData, setUploadedData] = useState<any[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleChangeUploadOption = () =>
    setUploadOption((prev) => (prev === 'manual' ? 'upload' : 'manual'))

  // Function to handle file upload and parsing
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target?.result
        const workbook = XLSX.read(data, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet)
        setUploadedData(jsonData)
      }
      reader.readAsBinaryString(file)
    }
  }

  const handleSubmitUploadedData = () => {
    if (!isUpload) return
    const formattedData: IUploadedLaborCost[] = uploadedData.map((item) => ({
      procedureType: item['Procedure Type'],
      rnCost: item['RN cost/Hour'],
      scrubTechCost: item['nurse cost/hour'],
    }))
    setUploadedData([])
    setUploadOption('manual')
    onSave(formattedData, 'upload')
  }

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const proceduresData = uploadedData.map((item) => ({
    procedureType: item['Procedure Type'],
    rnCost: item['RN cost/Hour'],
    scrubTechCost: item['nurse cost/hour'],
  })) as TableRowProps[]

  useEffect(() => {
    if (isUpdate && data) {
      const { procedureType, rnCost, scrubTechCost } = data
      setValue('procedureType', { id: procedureType, name: procedureType })
      setValue('rnCost', rnCost)
      setValue('scrubTechCost', scrubTechCost)
    }
  }, [mode, data])

  useEffect(() => {
    if (isUpdate && data) {
      const { procedureType, rnCost, scrubTechCost } = data
      setIsDisabled(
        procedureType === watch('procedureType.name') &&
          rnCost === watch('rnCost') &&
          scrubTechCost === watch('scrubTechCost')
      )
    }
  }, [
    isDisabled,
    data,
    watch('procedureType'),
    watch('rnCost'),
    watch('scrubTechCost'),
  ])

  return {
    errors,
    control,
    isUpdate,
    isManual,
    isUpload,
    isDisabled,
    procedures,
    fileInputRef,
    uploadOption,
    uploadedData,
    proceduresData,
    isLoadingProcedures,
    onSubmit,
    handleSubmit,
    setUploadedData,
    handleFileUpload,
    triggerFileUpload,
    handleChangeUploadOption,
    handleSubmitUploadedData,
  }
}

export default useAddNewLaborCostLogic
