import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableRowProps,
  styled,
} from '@mui/material'
import { theme } from 'assets/styles/customTheme'
import { useRef, useState, useMemo, MouseEvent } from 'react'
import { HeadCell, Order, TableHeader, getComparator } from './TableHeader'

interface DataTableProps {
  tableHeaders: HeadCell[]
  tableRows: TableRowProps[]
  isLoading: boolean
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>, row: any) => void
  selectable?: boolean
  isErrored?: boolean
  disableSorting?: boolean
  errorMessage?: string
}

export const DataTable = ({
  tableHeaders,
  tableRows,
  isLoading,
  onClick,
  selectable,
  isErrored = false,
  disableSorting,
  errorMessage = 'Error loading data...please try again',
}: DataTableProps) => {
  // TODO: khanh to fix, ignoring console warning for now
  console.error = (message) => {
    if (
      typeof message === 'string' &&
      message.includes('<table> cannot appear as a descendant of <p>')
    ) {
      return
    }
  }

  const defaultKeyRef = useRef<keyof TableRowProps>('' as keyof TableRowProps)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof TableRowProps>(
    defaultKeyRef.current
  )

  useMemo(() => {
    const rows = tableRows[0] && Object.keys(tableRows[0])
    if (rows?.length > 0 && !disableSorting) {
      defaultKeyRef.current = rows[0] as keyof TableRowProps
    }
    setOrderBy(defaultKeyRef.current)
  }, [tableRows, disableSorting])

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof TableRowProps
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedRows = useMemo(
    () =>
      tableRows
        ?.slice()
        ?.sort((a, b) => getComparator(order, orderBy)(a as any, b as any)),
    [order, orderBy, tableRows]
  )

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grayscale.lightest,
    },
    '&:nth-of-type(odd):hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grayscale.light,
    },
    '&:nth-of-type(even):hover': {
      backgroundColor: theme.palette.primary.light,
    },
  }))

  return (
    <Box display="flex">
      <TableContainer
        sx={{
          borderRadius: 2,
          margin: 2,
          mt: 0,
          boxShadow: 4,
        }}
      >
        <Table padding="normal" stickyHeader>
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            tableHeaders={tableHeaders}
            disableSorting={disableSorting}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    padding: 4,
                  }}
                  colSpan={tableHeaders.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : isErrored ? (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    padding: 4,
                  }}
                  colSpan={tableHeaders.length}
                >
                  {errorMessage}
                </TableCell>
              </TableRow>
            ) : sortedRows.length > 0 ? (
              sortedRows.map((row, index) => (
                <StyledTableRow
                  onClick={(event) => onClick && onClick(event, row)}
                  hover
                  tabIndex={-1}
                  key={index}
                  sx={{ cursor: selectable ? 'pointer' : 'default' }}
                >
                  {Object.values(row).map((value, cellIndex) => (
                    <TableCell align="left" key={cellIndex}>
                      {value}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    padding: 4,
                  }}
                  colSpan={tableHeaders.length}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
