import type { TrayStickerProps } from './TraySticker.types'
import { render } from 'react-dom'

export function TraySticker({
  id,
  vendor,
  description,
  ownership,
  type = 'implantable',
  createdAt = new Date(),
  caseNumber,
}: TrayStickerProps) {
  return (
    <div className="tray-sticker">
      <div className="tray-sticker_datetime">
        {createdAt.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })}
      </div>
      <div className="tray-sticker_content">
        <div>
          <div className="tray-sticker_qr-code">
            <img
              alt="QR Code"
              src={`https://api.qrserver.com/v1/create-qr-code/?size=76x76&data=${id}`}
            />
          </div>
          <div className="tray-sticker_ownership">{ownership} Tray</div>
        </div>
        <div className="tray-sticker_details">
          <div>{vendor}</div>
          <div>{description}</div>
          <div>{id}</div>
          <div>{caseNumber}</div>
        </div>
        <div className="tray-sticker_type">{type}</div>
      </div>
    </div>
  )
}

export function StickerPrintContainer() {
  return (
    <iframe
      id="print-sticker-view"
      style={{ display: 'none' }}
      title="Print Sticker View"
    />
  )
}

export function printSticker(props: TrayStickerProps) {
  const stickerElement = <TraySticker {...props} />
  const iframe = document.getElementById(
    'print-sticker-view'
  ) as HTMLIFrameElement
  if (iframe && iframe.contentWindow) {
    const iframeDocument = iframe.contentWindow.document
    iframeDocument.open()
    iframeDocument.write(
      `
      <html>
        <head>
          <style>
          .tray-sticker {
            width: 300px;
            padding-bottom: 100px;
            border: 1px solid black;
            padding: 2px 4px;
            position: relative;
            display: flex;
            flex-direction: column;
          }
          .tray-sticker .tray-sticker_datetime {
            text-align: center;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: -0.25px;
          }
          .tray-sticker .tray-sticker_content {
            display: flex;
            align-items: center;
            flex-grow: 1;
            padding: 8px 0;
          }
          .tray-sticker .tray-sticker_qr-code {
            margin-right: 12px;
          }
          .tray-sticker .tray-sticker_details {
            max-width: 196px;
            flex: 1;
            font-size: 14px;
            padding-right: 12px;
            & > div {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 1.25;
            }
          }
          .tray-sticker .tray-sticker_ownership {
            margin-top: 2px;
            font-size: 12px;
            letter-spacing: -0.5px;
            text-transform: uppercase;
          }
          .tray-sticker .tray-sticker_type {
            width: 0;
            flex-shrink: 1;
            margin-right: 4px;
            transform: translateY(-350%) translateX(0) rotate(90deg);
            font-size: 12px;
            font-weight: bold;
            white-space: nowrap;
            text-transform: uppercase;
          }

          </style>
        </head>
        <body>
          <div id="root"></div>
        </body>
      </html>`
    )
    iframeDocument.close()
    render(stickerElement, iframeDocument.getElementById('root') as HTMLElement)

    // wait for QR code to render
    setTimeout(() => {
      if (iframe.contentWindow) {
        iframe.contentWindow.print()
      }
    }, 500)
  }
}
