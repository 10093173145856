import { Typography } from '@material-ui/core'
import { Box, Button, Modal } from '@mui/material'
import type { AddTrayDetailsConfirmationProps } from './AddTrayDetails.types'

export function AddTrayDetailsConfirmation({
  isOpen,
  onClose,
  printSticker,
  onSubmit,
}: AddTrayDetailsConfirmationProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-tray-confirmation"
      aria-describedby="Print your tray sticker and add the tray to the inventory."
      className="custom-modal"
    >
      <Box className="parent-modal-box">
        <Box className="header-box">
          <Typography variant="h6">Add New Tray</Typography>
        </Box>
        <Box id="alert-dialog-description" className="child-box" sx={{ my: 1 }}>
          <Typography variant="body1">
            It looks like you have generated a new tray ID. If you need a label
            for this tray, please click the "Print Label" button. Otherwise,
            click "Add New Tray" to add the tray to the inventory.
          </Typography>
        </Box>
        <Box className="button-box" sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="outlined" onClick={printSticker} autoFocus>
            Print Label
          </Button>
          <Button variant="contained" onClick={onSubmit} autoFocus>
            Add New Tray
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
