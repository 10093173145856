import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { IconButton, Typography, Box } from '@mui/material'
import { NavigateBefore, NavigateNext, Search } from '@mui/icons-material'
import ErrorDisplay from 'components/ErrorDisplay'
import dayjs from 'lib/dayjs'
import { useDisablePolling } from 'lib/useDisablePolling'
import { useGetSurgeriesQuery } from 'lib/apollo/hooks'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'
import { ProcedureListTable } from './ProcedureListTable'
import { useNavigate } from 'react-router-dom'
import Button from 'components/molecules/Button/Button'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { useFlags } from 'launchdarkly-react-client-sdk'
import TextInput from 'components/molecules/TextInput/TextInput'
import { Surgery } from 'common/types'

const initialBefore = dayjs().endOf('day').toDate()
const initialAfter = dayjs().startOf('day').toDate()

function ProceduresNurse() {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const disablePolling = useDisablePolling()
  const [beforeDate, setBeforeDate] = useState(initialBefore)
  const [afterDate, setAfterDate] = useState(initialAfter)
  const flags = useFlags()
  const [search, setSearch] = useState<string>()
  const [surgeries, setSurgeries] = useState<Surgery[]>()
  const { loading, error, data, refetch } = useGetSurgeriesQuery(
    {
      before: beforeDate,
      after: afterDate,
      limit: 0,
    },
    {
      disablePolling,
    }
  )

  const isErrored = !loading && error

  const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const handleBackDay = () => {
    setBeforeDate(dayjs(beforeDate).subtract(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).subtract(1, 'day').toDate())
  }

  const handleForwardDay = () => {
    setBeforeDate(dayjs(beforeDate).add(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).add(1, 'day').toDate())
  }

  const handleNavigate = () => navigate('/new-procedure')

  const handleSearch = useCallback(
    (searchTerm: string) => {
      setSearch(searchTerm)
      if (surgeries) {
        const filteredSurgeries = data.getSurgeries.results.filter(
          (surgery: Surgery) => {
            const surgeonName = `${
              surgery.visit?.attendingProvider?.firstName || ''
            } ${surgery.visit?.attendingProvider?.lastName || ''}`.toLowerCase()
            const patientName =
              `${surgery.patient.firstName} ${surgery.patient.lastName}`.toLowerCase()
            const mrn = surgery.patient.idMR.toLowerCase()
            const roomNumber = `${surgery.visit?.location?.room}`.toLowerCase()

            // Check if search term includes "room" to specifically search for a room number
            if (searchTerm.toLowerCase().includes('room')) {
              return roomNumber.includes(
                searchTerm.toLowerCase().replace('room', '').trim()
              )
            }

            return (
              surgeonName.includes(searchTerm.toLowerCase()) ||
              patientName.includes(searchTerm.toLowerCase()) ||
              mrn.includes(searchTerm.toLowerCase())
            )
          }
        )
        setSurgeries(filteredSurgeries)
      }
    },
    [surgeries]
  )

  useEffect(() => {
    if (data?.getSurgeries?.results) {
      setSurgeries(data?.getSurgeries?.results)
    }
  }, [data?.getSurgeries?.results])

  return (
    <Box
      aria-label="procedure-list-container"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      width="100%"
    >
      <Box
        aria-label="procedures-nurse-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h1" fontSize={isMd ? 20 : 24}>
          Procedure List
        </Typography>

        {!isMobile ? (
          <Typography
            variant="subtitle1"
            fontSize={isMd ? 16 : 20}
            fontWeight={500}
          >
            {dayjs().format('ddd MMM D, YYYY')}
          </Typography>
        ) : null}
        {flags?.allowAddingProceduresManually ? (
          <Button onClick={handleNavigate} variant="contained">
            Add Procedure
          </Button>
        ) : null}
      </Box>

      <Box
        aria-label="date-picker-box"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        display="flex"
      >
        <IconButton onClick={handleBackDay} size="small">
          <NavigateBefore fontSize="large" />
        </IconButton>

        <CustomDatePicker
          label="Procedure List Date"
          date={afterDate}
          renderDatePicker={false}
        />

        <IconButton onClick={handleForwardDay} size="small">
          <NavigateNext fontSize="large" />
        </IconButton>
      </Box>

      {/* If there is no surgery data and there is an error, show the 
      server connection message */}
      {isErrored && !surgeries && <ErrorDisplay pathForward={refetch} />}

      {/* If there is an error, but there are available surgeries, indicate to 
      the user without interrupting the normal app workflow, that one or more 
      of the surgeries could ot be displayed due to server errors, and to check 
      with the administrator.*/}
      {isErrored && surgeries && (
        <Typography variant="h4">
          Our server encountered an issue with 1 or more surgeries that could
          not be displayed. Please contact the administrator for further
          assistance.
        </Typography>
      )}

      <TextInput
        variant="outlined"
        size="small"
        startAdornment={<Search scale={12} />}
        value={search}
        placeholder={`Search ${
          isMobile
            ? '(Patient, MRN, Surgeon, or Room)'
            : '(by Patient Name, MRN, Surgeon, or Room Number)'
        }`}
        handleChangeFormData={(e) => handleSearch(e.target.value)}
      />
      <ProcedureListTable events={surgeries} loading={loading} />
    </Box>
  )
}

export default ProceduresNurse
