import { CircularProgress, Switch } from '@mui/material'
import QRCode from 'react-qr-code'
import {
  ErrorMessage,
  Info,
  MainContainer,
  QRCodeContainer,
  SwitchContainer,
} from './InviteRep.styles'
import { TInviteRepProps } from './InviteRep.types'
import { useInviteRepLogic } from './InviteRep.logic'

const InviteRep: React.FC<TInviteRepProps> = (props) => {
  const { error, canAddAsset, loading, navigationUrl, handleSwitchChange } =
    useInviteRepLogic(props)

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <MainContainer>
      <SwitchContainer>
        Allow sales rep to add assets?
        <Switch checked={canAddAsset} onChange={handleSwitchChange} />
      </SwitchContainer>
      <QRCodeContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <QRCode fgColor="var(--primary)" value={navigationUrl} />
        )}
      </QRCodeContainer>
      <Info>
        A sales rep can capture this code to approve the list of assets.
      </Info>
    </MainContainer>
  )
}

export default InviteRep
