// useLogic.ts
import { useSendProductRepInviteMutation } from 'lib/apollo/hooks'
import { useGetBidCompanyId } from 'lib/utils/getBidCompanyId'
import { FormEvent, useEffect, useState } from 'react'
import {
  TRepSignUpFormData,
  TRepSignUpFormProps,
  repSignUpSchema,
  TValidateFormResponse,
} from './repSignupForm.types'
import { formatForSubmission, formatPhoneNumber } from './repSignupForm.utils'
import { getZodError } from 'common/utils'

export const initialFormData: TRepSignUpFormData = {
  email: '',
  emailValidation: '',
  firstName: '',
  lastName: '',
  mobile: '',
  companyName: '',
  bidCompanyId: null,
}

export const useRepSignupFormLogic = (props: TRepSignUpFormProps) => {
  const { surgeryId, resetForm, setResetForm, onClose } = props
  const [formData, setFormData] = useState<TRepSignUpFormData>(initialFormData)
  const [formErrors, setFormErrors] = useState<TValidateFormResponse>()
  const [canAddAsset, setCanAddAsset] = useState(false)
  const [showSuccessContainer, setShowSuccessContainer] = useState(false)

  const [
    sendProductRepInviteMutation,
    {
      data: productRepInviteMutationData,
      loading: productRepInviteMutationIsLoading,
      error: productRepInviteMutationError,
    },
  ] = useSendProductRepInviteMutation()

  useEffect(() => {
    if (productRepInviteMutationData?.sendProductRepInvite.success) {
      setShowSuccessContainer(true)
    }
  }, [productRepInviteMutationData])

  const bidCompanyResult = useGetBidCompanyId({
    companyNameInput: formData.companyName,
  })

  bidCompanyResult
    ? (formData.bidCompanyId = bidCompanyResult)
    : (formData.bidCompanyId = null)

  const updateFormData = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleMobileInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const input = e.target.value.replace(/\D/g, '')

    updateFormData(e.target.name, formatPhoneNumber(input))
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const result = repSignUpSchema.safeParse(formData)
    if (!result.success) {
      setFormErrors(getZodError(result.error))
      return
    }

    sendProductRepInviteMutation({
      variables: {
        email: formData.email,
        bidCompanyId: formData.bidCompanyId,
        surgeryId,
        accessLevel: canAddAsset ? 1 : 0,
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobile: formatForSubmission(formData.mobile),
        companyName: formData.companyName,
      },
      onCompleted: (data) => {
        if (!data.sendProductRepInvite.success) {
          setFormErrors({
            email: data.sendProductRepInvite.message,
          })
        }
      },
    })
  }

  const closeSuccessContainer = () => {
    setShowSuccessContainer(false)
    onClose?.()
  }

  useEffect(() => {
    setFormErrors(null)
  }, [formData])

  useEffect(() => {
    if (resetForm) {
      setFormData(initialFormData)
      setFormErrors(null)
      setShowSuccessContainer(false)
      setResetForm?.(false)
    }
  }, [resetForm, setResetForm])

  return {
    formData,
    formErrors,
    canAddAsset,
    productRepInviteMutationData,
    productRepInviteMutationError,
    productRepInviteMutationIsLoading,
    setCanAddAsset,
    handleSubmit,
    updateFormData,
    handleMobileInputChange,
    closeSuccessContainer,
    showSuccessContainer,
  }
}
