// Components
import { Typography } from '@mui/material'
import LandingIcon from './LandingIcon/LandingIcon'

// Types
import { MainIconFlowState } from 'common/types'

// Logic
import { useMainIconLanding } from './MainIconLanding.logic'

// Styles
import classNames from 'classnames'
import './MainIconLanding.scss'

// Other
import { Helmet } from 'react-helmet'

const MainIconLanding = () => {
  const { handleChangeAssetType, icons, isMobile } = useMainIconLanding()

  return (
    <div
      className={classNames('main-icon-landing_wrapper', {
        'is-mobile': isMobile,
      })}
    >
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Typography variant="h1" align="center">
        Select Product Type
      </Typography>

      <div className="icons-container">
        {Object.entries(icons).map(
          ([key, { IconComponent, label, dataTestId, isVisible }]) =>
            isVisible === false ? null : (
              <div key={key}>
                <LandingIcon
                  isMobile={isMobile}
                  onClick={() =>
                    handleChangeAssetType(
                      label?.toUpperCase() as MainIconFlowState['assetTypeLabel']
                    )
                  }
                  icon={IconComponent}
                  label={label}
                  dataTestId={dataTestId}
                />
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default MainIconLanding
