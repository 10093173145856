import { TransformComponent } from 'react-zoom-pan-pinch'
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  KeyboardBackspace,
  Replay,
} from '@mui/icons-material'
import classNames from 'classnames'

import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'

import useTrayProcedureEditModeLogic from './TrayProcedureEditMode.logic'
import getIsMobileOrTablet from 'lib/utils/getIsMobileOrTablet'

import { TrayProcedureEditModeProps } from './TrayProcedureEditMode.types'
import './TrayProcedureEditMode.scss'

const TrayProcedureEditMode = ({
  mapContent,
  trayType,
  onClose,
  onClickActions,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  handlePlateUsage,
  getPlateCount,
}: TrayProcedureEditModeProps) => {
  const { handlePan, zoomIn, zoomOut, resetTransform } =
    useTrayProcedureEditModeLogic()

  return (
    <div
      className={classNames('tray-procedure-edit-mode-organism_wrapper', {
        'is-mobile': getIsMobileOrTablet(),
      })}
    >
      <button className="back-button" onClick={onClose}>
        <KeyboardBackspace />
      </button>

      <div className="joystick-container">
        <button onClick={() => handlePan('right')}>
          <ArrowDropUp />
        </button>
        <div>
          <button onClick={() => handlePan('up')}>
            <ArrowLeft />
          </button>
          <button onClick={() => handlePan('down')}>
            <ArrowRight />
          </button>
        </div>
        <button onClick={() => handlePan('left')}>
          <ArrowDropDown />
        </button>
      </div>

      <div className="tray-map_wrapper">
        <TransformComponent
          contentStyle={{ width: '100%', height: '100%' }}
          wrapperStyle={{ width: '100%', height: '100%' }}
        >
          <TrayMap
            trayType={trayType}
            mapContent={mapContent}
            onClickActions={onClickActions}
            isScrewEnabled={isScrewEnabled}
            isScrewImplanted={isScrewImplanted}
            isScrewWasted={isScrewWasted}
            handlePlateUsage={handlePlateUsage}
            getPlateCount={getPlateCount}
            disablePlateIncrease={true}
          />
        </TransformComponent>
      </div>

      <div className="zoom-controls">
        <button onClick={() => zoomIn(0.5)}>+</button>
        <button onClick={() => zoomOut(0.5)}>-</button>
        <button onClick={() => resetTransform()}>
          <Replay />
        </button>
      </div>
    </div>
  )
}

export default TrayProcedureEditMode
