import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Detector } from 'react-detect-offline'
import { getUserRoleString, useUser } from 'app/User'
import { MedGEOLogoComponent } from 'assets/MedGEOlogo'
import { procedureStatusBarHeight } from 'components/ProcedureStatusBar'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  Tooltip,
  ListItem,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { Edit, ExpandLess, Logout, Menu } from '@mui/icons-material'
import styles from './floatingBar.module.css'
import { DASHBOARD } from 'app/ROUTES_CONSTANTS'
import { useDashboardContext } from 'lib/context/DashboardContext'
import { useIsRouteActive } from 'lib/utils/isRouteActive'
import { useFlags } from 'launchdarkly-react-client-sdk'

const isStaging = process.env.REACT_APP_ENV === 'staging'

function FloatingBar() {
  const flags = useFlags()
  const { setDarkMode, darkMode } = useDashboardContext()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { user, isNurseAdmin } = useUser()
  const { isActive } = useIsRouteActive()
  const navigate = useNavigate()
  const location = useLocation()

  const userRoleString = user && getUserRoleString(user)
  const isSPD = user?.roles?.includes('MGAT_SPD')

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleLogout = () => {
    setIsMenuOpen(false)
    navigate('/logout')
  }

  const handleNavigate = (route: string) => {
    setIsMenuOpen(false)
    navigate(`/${route}`)
  }

  // Close menu on any route change
  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  const renderLogo = () => {
    return (
      <Button
        onClick={() => handleNavigate(isSPD ? 'tray-management' : 'surgeries')}
        sx={{ m: 0, p: 0, width: '120px' }}
      >
        <Box display="flex">
          <MedGEOLogoComponent color="white" scale={0.6} />
        </Box>
      </Button>
    )
  }

  return (
    <AppBar
      id="medgeo-app-header"
      position="fixed"
      elevation={0}
      sx={{
        bgcolor: isStaging ? 'darkgreen' : 'primary.main',
      }}
    >
      <Toolbar>
        <Box className={styles.toolbar}>
          <Box className={styles.heading} sx={{ width: 1, color: 'white' }}>
            {renderLogo()}
            <Typography sx={{ mx: 1, fontSize: '1.5rem', fontWeight: 300 }}>
              |
            </Typography>
            <Typography>Dashboard</Typography>
          </Box>
          {user && (
            <Box flexDirection={'row'} display="flex" alignItems="center">
              {isActive('dashboard') && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          value={darkMode}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setDarkMode(e.target.checked)
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="h5"
                          sx={{ color: 'white', textWrap: 'nowrap' }}
                        >
                          Dark Mode
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <Typography
                    sx={{
                      mx: 1,
                      fontSize: '1.5rem',
                      fontWeight: 300,
                      color: 'white',
                    }}
                  >
                    |
                  </Typography>
                </>
              )}
              <Tooltip title="User Menu" placement="left">
                <IconButton
                  data-testid="menu-button"
                  sx={{ color: 'primary.lightest' }}
                  onClick={handleMenuToggle}
                >
                  <Menu />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Toolbar>

      {/* Menu */}
      <Drawer
        open={isMenuOpen}
        onClose={handleMenuToggle}
        variant="temporary"
        anchor="top"
        sx={{ zIndex: 1000 }}
      >
        <List disablePadding>
          <ListItem
            sx={{
              bgcolor: isStaging ? 'darkgreen' : 'primary.main',
              color: 'white',
              justifyContent: 'space-between',
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'white', color: 'primary.main' }} />
            </ListItemAvatar>
            <ListItemText>
              {`${userRoleString?.toUpperCase()}`}
              {(user?.firstName || user?.lastName) &&
                ` | ${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
            </ListItemText>
            <IconButton onClick={handleMenuToggle} sx={{ color: 'white' }}>
              <ExpandLess />
            </IconButton>
          </ListItem>

          {/* USER & ACTIONS */}
          {user?.id && (
            <ListItemButton
              data-testid="profile-button"
              onClick={() => handleNavigate('profile')}
              sx={{ pt: 2 }}
            >
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: 'grayscale.light' }}>
                  <Edit
                    sx={{
                      color: 'primary.main',
                      backgroundColor: 'grayscale.light',
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Profile</ListItemText>
            </ListItemButton>
          )}
          <ListItemButton
            data-testid="logout-button"
            divider
            onClick={handleLogout}
          >
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: 'grayscale.light' }}>
                <Logout
                  sx={{
                    color: 'primary.main',
                    backgroundColor: 'grayscale.light',
                  }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Logout" />
          </ListItemButton>

          {/* APP NAVIGATION */}
          {!isSPD && (
            <ListItemButton
              data-testid="surgery-list"
              onClick={() => handleNavigate('surgeries')}
            >
              <ListItemText>Procedure List</ListItemText>
            </ListItemButton>
          )}
          {flags.navigationMenuTrayManagement && (
            <ListItemButton
              data-testid="surgery-list"
              onClick={() => handleNavigate('tray-management')}
            >
              <ListItemText>Tray Management {'(DTM)'}</ListItemText>
            </ListItemButton>
          )}
          {flags.navigationMenuSpd && isSPD && (
            <ListItemButton
              data-testid="surgery-list"
              onClick={() => handleNavigate('spd')}
            >
              <ListItemText>SPD {'(DTM)'}</ListItemText>
            </ListItemButton>
          )}
          {isNurseAdmin && (
            <ListItemButton onClick={() => handleNavigate(DASHBOARD)}>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          )}
          {isNurseAdmin && (
            <ListItemButton onClick={() => handleNavigate('inventory-report')}>
              <ListItemText>Inventory</ListItemText>
            </ListItemButton>
          )}
          {isNurseAdmin && (
            <ListItemButton onClick={() => handleNavigate('receive')}>
              <ListItemText>Receive Products</ListItemText>
            </ListItemButton>
          )}
          {isNurseAdmin && flags.navigationMenuPreferenceCards && (
            <ListItemButton onClick={() => handleNavigate('preference-cards')}>
              <ListItemText>Preference Cards</ListItemText>
            </ListItemButton>
          )}
          {isNurseAdmin && (
            <ListItemButton
              onClick={() => handleNavigate('referring-physicians')}
            >
              <ListItemText>Referring Physicians</ListItemText>
            </ListItemButton>
          )}
        </List>
      </Drawer>

      {/* Offline Indicator */}
      {user && (
        <Detector
          render={({ online }: { online: boolean }) => (
            <Collapse in={!online} className={styles.offlineIndicator}>
              <Box
                sx={{
                  backgroundColor: 'error.main',
                  height: procedureStatusBarHeight + 1,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    my: 0,
                    mx: 2,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  You are offline
                </Typography>
              </Box>
            </Collapse>
          )}
        />
      )}
    </AppBar>
  )
}

export default FloatingBar
