import { Button, CircularProgress, Typography } from '@mui/material'

import { useTrayDetails } from './TrayDetails.logic'

import './TrayDetails.scss'
import { Link } from 'react-router-dom'
import { ArrowBack, Delete } from '@mui/icons-material'
import { LoadingButton } from 'components/mui'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import ApproveRejectTray from 'components/molecules/ApproveRejectTray/ApproveRejectTray'
import dayjs from 'lib/dayjs'
import useTrayManagementLogic from '../TrayManagement/TrayManagement.logic'

const TrayDetails = () => {
  const {
    loading,
    error,
    trayItem,
    deleting,
    handleDeleteTray,
    subTraysTableBodyCells,
    subTraysTableHeaderCells,
    isApproveRejectTrayOpen,
    handleApproveRejectTrayClick,
    isApproving,
    isRejecting,
    handleUpdateTrayApprovalStatus,
    isSPD,
    trayDetails,
    handleViewTrayFiles,
    notAssignedButSelectedSurgeon,
  } = useTrayDetails()
  const { getTrayStatusDisplay } = useTrayManagementLogic()

  return (
    <div className="tray-details_wrapper">
      <div data-testid="header" className="header">
        <div className="buttons_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            to=".."
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <div className="right_buttons">
            {isSPD && trayDetails?.trayCategory !== 'facility' && (
              <LoadingButton
                variant="outlined"
                onClick={handleApproveRejectTrayClick}
              >
                Approve/ Reject Tray
              </LoadingButton>
            )}

            <LoadingButton
              className="delete"
              dataTestId="delete-tray-button"
              loading={deleting}
              disabled={deleting}
              mode="delete"
              sx={{ width: deleting ? '50px' : '30px' }}
              onClick={handleDeleteTray}
            >
              <Delete sx={{ width: '17px', height: '17px' }} />
            </LoadingButton>
          </div>
        </div>

        <Typography variant="h1">Tray Details</Typography>
      </div>

      <ApproveRejectTray
        open={isApproveRejectTrayOpen}
        onClose={handleApproveRejectTrayClick}
        isApproving={isApproving}
        isRejecting={isRejecting}
        handleApproval={() => handleUpdateTrayApprovalStatus('approved')}
        handleRejection={(rejectionReason) =>
          handleUpdateTrayApprovalStatus('rejected', rejectionReason)
        }
      />

      <div className="content">
        {loading && !error && <CircularProgress />}

        {error && !loading && (
          <Typography className="error" variant="body1">
            {error.message}
          </Typography>
        )}

        {!error && !loading && trayItem && (
          <>
            <div className="tray-details_box">
              <Typography variant="subtitle1">
                Description: {trayItem?.description}
              </Typography>
              <Typography variant="subtitle1">
                Last updated:{' '}
                {trayItem?.statusDetails?.lastUpdated
                  ? dayjs(trayItem?.statusDetails?.lastUpdated).format(
                      'MM/DD/YYYY hh:mm A'
                    )
                  : 'N/A'}
              </Typography>
              <Typography variant="subtitle1">
                Last updated by:{' '}
                {trayItem?.statusDetails?.lastUpdatedBy
                  ? trayItem?.statusDetails?.lastUpdatedBy
                  : 'N/A'}
              </Typography>
              {notAssignedButSelectedSurgeon && (
                <Typography variant="subtitle1">
                  Selected Surgeon: {trayDetails?.selectedSurgeon}
                </Typography>
              )}
              <Typography>
                Status: {getTrayStatusDisplay(trayDetails?.status)}
              </Typography>

              <LoadingButton
                variant="outlined"
                size="small"
                onClick={handleViewTrayFiles}
              >
                View {trayDetails?.trayCategory !== 'facility' && 'IFU Files +'}{' '}
                Inventory Sheets
              </LoadingButton>
            </div>

            <div className="sub-trays-table_container">
              <DataTable
                tableHeaders={subTraysTableHeaderCells}
                tableRows={subTraysTableBodyCells(trayItem.subItems)}
                isLoading={false}
                isErrored={false}
                disableSorting
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TrayDetails
