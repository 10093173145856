import {
  useAddReferringPhysician,
  useEditReferringPhysician,
} from 'lib/apollo/hooks'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  physicianDetailsFormSchema,
  TNewReferringPhysicianState,
} from './NewReferringPhysician.types'
import { getZodError } from 'common/utils'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { enqueueSnackbar } from 'notistack'
import { REFERRING_PHYSICIANS } from 'app/ROUTES_CONSTANTS'

export const useNewReferringPhysicianLogic = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const state = useLocation().state as TNewReferringPhysicianState
  const { firstName, lastName, email, edit, _id } = state

  const [formData, setFormData] = useState({
    _id: _id,
    firstName: firstName,
    lastName: lastName,
    email: email,
    confirmEmail: email,
  })

  const [formErrors, setFormErrors] = useState<Record<string, string>>()

  const [editReferringPhysician, { loading: editLoading }] =
    useEditReferringPhysician()

  const [addReferringPhysician, { loading: addLoading }] =
    useAddReferringPhysician()

  const isLoading = edit ? editLoading : addLoading
  const headerTitle = `Referring Physicians / ${
    edit
      ? `Edit ${!isMobile ? 'Physician' : ''}`
      : `Add ${!isMobile ? 'Physician' : ''}`
  }`
  const successAlertTitle = `Referring physician was ${
    edit ? 'updated' : 'created'
  } successfully`

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = physicianDetailsFormSchema.safeParse(formData)
    if (!result.success) {
      setFormErrors(getZodError(result.error))
      return
    }
    const { confirmEmail, ...restFormData } = formData
    edit
      ? editReferringPhysician({ variables: { physician: restFormData } }).then(
          (response) => {
            if (response.data?.editPhysician) {
              enqueueSnackbar(successAlertTitle, { variant: 'success' })
            }
            navigate(`/${REFERRING_PHYSICIANS}`)
          }
        )
      : addReferringPhysician({ variables: { physician: restFormData } })
          .then((response) => {
            if (response.data?.createPhysician) {
              enqueueSnackbar(successAlertTitle, { variant: 'success' })
            }
            navigate(`/${REFERRING_PHYSICIANS}`)
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' })
          })
  }

  const handleChangeUpdatedPhysicianDetails = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormErrors((prev) => ({ ...prev, [e.target.name]: '' }))
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return {
    formData,
    isLoading,
    formErrors,
    headerTitle,
    navigate,
    handleFormSubmit,
    handleChangeUpdatedPhysicianDetails,
  }
}
