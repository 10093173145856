import { Box, Button, TableRowProps } from '@mui/material'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import dayjs from 'lib/dayjs'
import {
  Patient,
  Surgery,
  Procedures,
  Visit,
  SurgicalCase,
  Location,
  Provider,
  ProcedureStatus,
} from 'common/types'
import { ReadMore } from '@mui/icons-material'
import { FilterBar } from 'components/organisms/FilterBar/FilterBar'
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbHeader } from 'components/molecules/BreadcrumbHeader/BreadcrumbHeader'
import useGetUsageReports from 'lib/services/api/reports-service/usageReports/getUsageReports'
import { DefaultFilterParams } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import Header from 'components/molecules/Header/Header'

const dateFormat = 'MMM DD YYYY'

export interface ListSurgeriesInput {
  mrn?: Patient['idMR']
  caseNumber?: SurgicalCase['number']
  procedureType?: Procedures['description']
  surgeonName?: Provider['lastName'] | Provider['firstName']
  patientName?: Patient['lastName'] | Patient['firstName']
  costRangeLower?: number
  costRangeUpper?: number
  location?: Location['department']
  page?: number
  pageSize?: number
  startDate?: dayjs.Dayjs
  endDate?: dayjs.Dayjs
  status?: ProcedureStatus['name']
}

export interface SurgeriesOutput {
  surgeryId: Surgery['_id']
  patientMRN: Patient['idMR']
  patientFirstName: Patient['firstName']
  patientLastName: Patient['lastName']
  procedures: {
    dateTime: Procedures['dateTime']
    description: Procedures['description']
  }[]
  visitDate: Visit['visitDateTime']
  totalCost: Surgery['totalSurgeryCost']
  surgicalCaseStatus: SurgicalCase['status']
  location: Location['department']
  surgeonLastName: Provider['lastName']
  surgeonFirstName: Provider['firstName']
}

export const UsageReportsPage = () => {
  const navigate = useNavigate()

  const { beforeDate, afterDate, filters, setFilters, setReportData } =
    useReportFiltersContext()

  const queryParams = useMemo(() => {
    const usageFilters = (filters as DefaultFilterParams) || {}
    const params = {} as ListSurgeriesInput

    if (usageFilters.mrn) params.mrn = usageFilters.mrn
    if (usageFilters.patientName) params.patientName = usageFilters.patientName
    if (usageFilters.surgeonName) params.surgeonName = usageFilters.surgeonName
    if (usageFilters.procedureType)
      params.procedureType = usageFilters.procedureType
    if (usageFilters.department) params.location = usageFilters.department
    if (afterDate)
      params.startDate = afterDate.format(
        'YYYY-MM-DD'
      ) as unknown as dayjs.Dayjs
    if (beforeDate)
      params.endDate = beforeDate.format('YYYY-MM-DD') as unknown as dayjs.Dayjs
    params.pageSize = 1000
    params.status = 'SUBMITTED'

    return params
  }, [filters, beforeDate, afterDate])

  const { listSurgeries, surgeries, isLoading, error } =
    useGetUsageReports(queryParams)

  useEffect(() => {
    listSurgeries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (surgeries) {
      setReportData(surgeries)
    }
  }, [surgeries, setReportData])

  const headCells: HeadCell[] = [
    {
      id: 'date',
      label: 'Date',
      numeric: true,
    },
    {
      id: 'patient',
      label: 'Patient',
      numeric: false,
    },
    {
      id: 'mrn',
      label: 'MRN',
      numeric: true,
    },
    {
      id: 'surgeon',
      label: 'Surgeon',
      numeric: false,
    },
    {
      id: 'procedureType',
      label: 'Procedure',
      numeric: false,
    },
    {
      id: 'totalSurgeryCost',
      label: 'Total Cost',
      numeric: true,
    },
    {
      id: 'procedureDetails',
      label: 'Procedure Details',
      numeric: false,
    },
  ]

  useEffect(() => {
    setFilters({
      open: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface MappedSurgeries extends TableRowProps {
    date: SurgeriesOutput['visitDate']
    patient:
      | SurgeriesOutput['patientLastName']
      | SurgeriesOutput['patientFirstName']
    mrn: SurgeriesOutput['patientMRN']
    surgeon:
      | SurgeriesOutput['surgeonLastName']
      | SurgeriesOutput['surgeonFirstName']
    procedureType: SurgeriesOutput['procedures'][0]['description']
    totalSurgeryCost: SurgeriesOutput['totalCost'] | string
    procedureDetails: JSX.Element
  }

  const mappedData: MappedSurgeries[] = surgeries?.map(
    (surgery: SurgeriesOutput) => {
      return {
        date: dayjs(surgery?.procedures[0].dateTime).format(dateFormat),
        patient: `${surgery?.patientLastName}, ${surgery?.patientFirstName}`,
        mrn: surgery?.patientMRN || '-',
        surgeon: surgery?.surgeonLastName || '-',
        procedureType: surgery?.procedures[0]?.description || '-',
        totalSurgeryCost: surgery?.totalCost || '$XXXX.00',
        procedureDetails: (
          <Button
            onClick={() => {
              navigate(`/usage-reports/${surgery?.surgeryId}`)
            }}
          >
            <ReadMore />
          </Button>
        ),
      }
    }
  )

  return (
    <Box className="usage-reports-container">
      <Header parent="Reports" title="Case Cost" />
      <FilterBar
        renderMrnFilter
        renderPatientNameFilter
        renderSurgeonFilter
        renderProcedureTypeFilter
        renderDatePicker
        condenseButtons
        isLoading={isLoading}
      />
      <DataTable
        tableHeaders={headCells}
        tableRows={mappedData || []}
        isLoading={isLoading}
        isErrored={!!error}
      />
    </Box>
  )
}
