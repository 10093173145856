import { FC } from 'react'
import { Typography } from '@mui/material'
import { RepItem } from '../RepItem/RepItem'
import { RepsListProps } from './RepsList.types'
import './RepsList.scss'

const RepsList: FC<RepsListProps> = ({
  selectedCompany,
  surgery,
  setToSalesRep,
}) => (
  <div className="reps-container">
    {selectedCompany.reps.length ? (
      selectedCompany?.reps.map((rep) => (
        <RepItem
          key={rep?.email}
          rep={rep}
          hasSentEmail={setToSalesRep?.emails.includes(rep.email) as boolean}
          hasSentTextMessage={
            setToSalesRep?.phoneNumbers.includes(
              rep.phoneNumber || ''
            ) as boolean
          }
          companyName={selectedCompany.name}
          surgeryId={surgery._id}
        />
      ))
    ) : (
      <Typography variant="h3" textAlign="center">
        No sales reps found for the selected company.
      </Typography>
    )}
  </div>
)

export default RepsList
