import { useParams } from 'react-router-dom'
import { SubTray } from '../SubTrays/SubTrays.types'
import { useAddTrayContext } from '../AddTray.context'
import { useEffect } from 'react'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useTrayContentLogic = () => {
  const { index } = useParams()
  const { subTrays } = useAddTrayContext()
  const { setTrayScrews, setTrayPlates } = useSPD()

  const subTray: SubTray | undefined = index
    ? subTrays[Number(index)]
    : undefined

  useEffect(() => {
    if (index && subTray) {
      setTrayScrews(subTray.content.screws)
      setTrayPlates(subTray.content.plates)
    } else {
      setTrayScrews([])
      setTrayPlates([])
    }
  }, [index])

  return { subTray }
}

export default useTrayContentLogic
