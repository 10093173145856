import * as React from 'react'
import {
  Drawer,
  Box,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { CSSObject, Theme, styled } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AutoGraph,
  ChevronLeft,
  ChevronRight,
  Assessment,
  Inventory2Outlined,
  Summarize,
  Assignment,
  Analytics,
  AttachMoney,
} from '@mui/icons-material'
import { useIsMobile } from 'lib/utils/useIsMobile'
import {
  ASSET_REPORTS,
  DASHBOARD,
  ANALYTICS,
  INVENTORY_REPORT,
  LOCATION_MANAGEMENT,
  RECEIVE,
  USAGE_REPORTS,
  REFERRING_PHYSICIANS,
  LABOR_COST,
} from 'app/ROUTES_CONSTANTS'
import { useIsRouteActive } from 'lib/utils/isRouteActive'
import { ReceivingSalesRepIcon } from 'assets/icons/ReceivingSalesRepIcon'
import { PreferenceCardIcon } from 'assets/icons/PreferenceCardIcon'
import { useFlags } from 'launchdarkly-react-client-sdk'

const DRAWER_WIDTH = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 500,
  position: 'inherit',
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface DashboardSideBarProps {
  sidebarWidth: number
}

export const DashboardSideBar = ({ sidebarWidth }: DashboardSideBarProps) => {
  const flags = useFlags()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useIsMobile()
  const isScannerPage = location.pathname.includes('scan')
  const { isActive } = useIsRouteActive()

  // This is only for scanning into receiving.
  // The screen is too small to have a side bar when scanning.
  const hideSideBar = isMobile && isScannerPage

  const handleDrawerToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleNavigation = (route: string) => {
    setIsMenuOpen(false)
    navigate(route)
  }

  const navigateDashboard = () => {
    handleNavigation(`/${DASHBOARD}`)
  }

  const navigateAnalytics = () => {
    handleNavigation(`/${ANALYTICS}`)
  }

  // const navigateAuditLogs = () => {
  //   handleNavigation('/audit-logs')
  // }

  // const navigateManageReps = () => {
  //   handleNavigation('/manage-reps')
  // }

  const navigateReceive = () => {
    handleNavigation(`/${RECEIVE}`)
  }

  const navigateInventoryReport = () => {
    handleNavigation(`/${INVENTORY_REPORT}`)
    // resetFilters()
  }

  const navigateLocationsManagement = () => {
    handleNavigation(`/${LOCATION_MANAGEMENT}`)
  }

  const navigatePreferenceCards = () => {
    handleNavigation('/preference-cards')
  }

  const navigateUsageReport = () => {
    handleNavigation(`/${USAGE_REPORTS}`)
  }

  const navigateAssetReport = () => {
    handleNavigation(`/${ASSET_REPORTS}`)
  }

  type TabLabels =
    | '$dashboard'
    // | '$auditLogs'
    // | '$manageReps'
    | '$receiveProducts'
    | '$analytics'
    | '$inventoryReport'
    | '$preferenceCards'
    | '$locationsManagement'
    | '$caseCost'
    | '$laborCost'
    | '$assetReports'
    | '$referringPhysicians'

  const Tabs: {
    [K in TabLabels]: {
      label: string
      icon: JSX.Element
      onClick: () => void
      isVisible?: boolean
    }
  } = {
    $dashboard: {
      label: 'Dashboard',
      icon: <AutoGraph color={isActive('dashboard') ? 'primary' : 'action'} />,
      onClick: navigateDashboard,
    },
    $caseCost: {
      label: 'Case Cost',
      icon: (
        <Summarize color={isActive(USAGE_REPORTS) ? 'primary' : 'action'} />
      ),
      onClick: navigateUsageReport,
      isVisible: flags.adminDashboardSideNavigationUsageReportsLink,
    },
    $laborCost: {
      label: 'Labor Cost',
      icon: <AttachMoney color={isActive(LABOR_COST) ? 'primary' : 'action'} />,
      onClick: () => handleNavigation(`/${LABOR_COST}`),
    },
    $analytics: {
      label: 'Analytics',
      icon: <Analytics color={isActive(ANALYTICS) ? 'primary' : 'action'} />,
      onClick: navigateAnalytics,
      isVisible: flags.analyticsDashboard,
    },
    $assetReports: {
      label: 'Product Details',
      icon: (
        <Assignment color={isActive(ASSET_REPORTS) ? 'primary' : 'action'} />
      ),
      onClick: navigateAssetReport,
      isVisible: flags.adminDashboardSideNavigationProductDetailsLink,
    },
    // $auditLogs: {
    //   label: 'Audit Logs',
    //   icon: (
    //     <Description
    //       color={isRouteActive('audit-logs') ? 'primary' : 'action'}
    //     />
    //   ),
    //   onClick: navigateAuditLogs,
    // },
    // $manageReps: {
    //   label: 'Manage Reps',
    //   icon: (
    //     <ManageAccounts
    //       color={isRouteActive('manage-reps') ? 'primary' : 'action'}
    //     />
    //   ),
    //   onClick: navigateManageReps,
    // },
    $receiveProducts: {
      label: 'Receive Products',
      icon: <ReceivingSalesRepIcon active={isActive(`${RECEIVE}`)} dark />,
      onClick: navigateReceive,
    },
    $inventoryReport: {
      label: 'Inventory Report',
      icon: (
        <Assessment
          color={isActive('inventory-report') ? 'primary' : 'action'}
        />
      ),
      onClick: navigateInventoryReport,
    },
    $preferenceCards: {
      label: 'Preference Cards',
      icon: <PreferenceCardIcon active={isActive('preference-cards')} />,
      onClick: navigatePreferenceCards,
      isVisible: flags.adminDashboardSideNavigationPreferenceCardsLink,
    },
    $locationsManagement: {
      label: 'Inventory Locations',
      icon: (
        <Inventory2Outlined
          color={isActive('location-management') ? 'primary' : 'action'}
        />
      ),
      onClick: navigateLocationsManagement,
    },
    $referringPhysicians: {
      label: 'Referring Physicians',
      icon: (
        <img
          src={
            isActive(REFERRING_PHYSICIANS)
              ? '/physicianActive.svg'
              : '/physician.svg'
          }
          alt="physician"
          height="24"
        />
      ),
      onClick: () => handleNavigation(`/${REFERRING_PHYSICIANS}`),
    },
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isMenuOpen ? 'flex-end' : 'center',
    ...theme.mixins.toolbar,
  }))

  return (
    <Box sx={{ display: 'flex' }}>
      {hideSideBar ? null : (
        <StyledDrawer
          variant="permanent"
          open={isMenuOpen}
          sx={{
            position: 'static',
            '& .MuiPaper-root': {
              minHeight: `calc(100vh - ${sidebarWidth}px)`,
              paddingTop: `${sidebarWidth}px`,
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerToggle}>
              {isMenuOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {Object.entries(Tabs).map(
              ([key, { label, icon, onClick, isVisible }]) => {
                if (isVisible === false) {
                  return null
                }

                return (
                  <ListItem key={key} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isMenuOpen ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={onClick}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isMenuOpen ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={label}
                        sx={{ opacity: isMenuOpen ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              }
            )}
          </List>
        </StyledDrawer>
      )}
    </Box>
  )
}
