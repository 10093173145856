import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'

import { useSubTrayDetails } from './SubTrayDetails.logic'

import './SubTrayDetails.scss'
import { ArrowBack } from '@mui/icons-material'
import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'
import { LoadingButton } from 'components/mui'

const SubTrayDetails = () => {
  const {
    loading,
    error,
    subTrayItem,
    subTrayDetails,
    handleEditTrayClick,
    handleBackClick,
    loadingParentTray,
    parentTrayError,
    shouldEditButtonBeVisible,
  } = useSubTrayDetails()

  return (
    <div className="sub-tray-details_wrapper">
      <div data-testid="header" className="header">
        <div className="buttons_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            onClick={handleBackClick}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <div className="right_buttons">
            {shouldEditButtonBeVisible && (
              <LoadingButton variant="outlined" onClick={handleEditTrayClick}>
                Edit Sub Tray
              </LoadingButton>
            )}
          </div>
        </div>

        <Typography variant="h1">Sub Tray Details</Typography>
      </div>

      <div className="content">
        {(loading || loadingParentTray) && !error && !parentTrayError && (
          <CircularProgress />
        )}

        {(error || parentTrayError) && !loading && !loadingParentTray && (
          <Typography className="error" variant="body1">
            {error.message || parentTrayError?.message}
          </Typography>
        )}

        {!error &&
          !parentTrayError &&
          !loading &&
          !loadingParentTray &&
          subTrayItem && (
            <>
              <div className="sub-tray-details_box">
                <Typography variant="subtitle1">
                  Description: {subTrayItem?.description}
                </Typography>
                <Typography>
                  Screws: {subTrayDetails?.screws?.length}
                </Typography>
              </div>

              <div className="img_container">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  className="skeleton"
                />

                <img
                  src={subTrayDetails?.trayImg}
                  alt={subTrayItem.description}
                />
              </div>

              {subTrayDetails?.screws.length === 0 && (
                <Typography variant="body2">No screws in this tray</Typography>
              )}

              {subTrayDetails?.screws.map((screw, _index) => (
                <TrayProduct
                  key={`${screw.deviceId}${_index}`}
                  {...screw}
                  showDispositionStatus={false}
                />
              ))}
            </>
          )}
      </div>
    </div>
  )
}

export default SubTrayDetails
