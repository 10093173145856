import Header from 'components/molecules/Header/Header'
import TextInput from 'components/molecules/TextInput/TextInput'
import { FC } from 'react'
import { LoadingButton } from 'components/mui'
import { useNewReferringPhysicianLogic } from './NewReferringPhysician.logic'
import './NewReferringPhysician.scss'
import { useIsMobile } from 'lib/utils/mediaQueries'

const NewReferringPhysician: FC = () => {
  const {
    formData,
    isLoading,
    formErrors,
    headerTitle,
    navigate,
    handleFormSubmit,
    handleChangeUpdatedPhysicianDetails,
  } = useNewReferringPhysicianLogic()

  return (
    <div className="new-referring-physician-main-container">
      <Header
        isMobile={useIsMobile()}
        hasBack
        onBackClick={() => navigate(-1)}
        parent="Reports"
        title={headerTitle}
      />
      <form onSubmit={handleFormSubmit} className="content">
        <div className="input-container">
          <TextInput
            disabled={isLoading}
            id="firstName"
            name="firstName"
            label="Physician First Name"
            variant="outlined"
            value={formData.firstName}
            fullWidth
            handleChangeFormData={handleChangeUpdatedPhysicianDetails}
            helperText={formErrors?.firstName}
            error={!!formErrors?.firstName}
          />

          <TextInput
            id="lastName"
            disabled={isLoading}
            label="Physician Last Name"
            name="lastName"
            variant="outlined"
            value={formData.lastName}
            fullWidth
            handleChangeFormData={handleChangeUpdatedPhysicianDetails}
            helperText={formErrors?.lastName}
            error={!!formErrors?.lastName}
          />

          <TextInput
            disabled={isLoading}
            id="email"
            name="email"
            label="Physician Email"
            variant="outlined"
            value={formData.email}
            handleChangeFormData={handleChangeUpdatedPhysicianDetails}
            fullWidth
            validateEmail
            helperText={formErrors?.email}
            error={!!formErrors?.email}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />
          <TextInput
            disabled={isLoading}
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Physician Email"
            variant="outlined"
            value={formData.confirmEmail}
            handleChangeFormData={handleChangeUpdatedPhysicianDetails}
            fullWidth
            validateEmail
            helperText={formErrors?.confirmEmail}
            error={!!formErrors?.confirmEmail}
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
          />
        </div>
        <div className="submit-button-container">
          <LoadingButton
            className="submit-button"
            type="submit"
            variant="contained"
            size="large"
            sx={{ marginTop: '10px' }}
            loading={isLoading}
          >
            SUBMIT
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

export default NewReferringPhysician
