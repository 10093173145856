/* eslint-disable react-hooks/exhaustive-deps */
import { SelectChangeEvent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ErrorIcon } from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'

import {
  matchMiddleFaceProduct,
  matchProduct,
  matchUpperFaceProduct,
} from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import useTrayMap from 'views/SPDVendorLayout/SPDVendor/TrayMap/TrayMap.logic'
import { useInventorySheetsContext } from '../InventorySheets/InventorySheets.context'
import { useAddTrayContext } from '../AddTray.context'

import { InventorySheetFile } from '../InventorySheets/InventorySheets.types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'
import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { SubTray } from '../SubTrays/SubTrays.types'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useAnalyzeTrayLogic = () => {
  const navigate = useNavigate()

  const {
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()
  const { isSubmitting } = useCaptureTrayContext()
  const { trayPlates } = useSPD()
  const { trayScrews, setTrayScrews, findScrew } = useTrayMap()
  const {
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
    setTrayAnalysisScrews,
    trayImage,
    setTrayImage,
    selectedTrayType,
    isAnalysisComplete,
    setIsAnalysisComplete,
    setAnalysisFileName,
    handleSubmitAnalysis,
    analysisScrews,
    setIsAnalysisFromSPD,
    isComplete,
    setIsComplete,
    analysisError,
    setAnalysisError,
    setSelectedTrayType,
    subTrays,
    setSubTrays,
  } = useAddTrayContext()
  const { uploadFile } = useInventorySheetsContext()

  const [isTrayMapDrawerOpen, setIsTrayMapDrawerOpen] = useState<boolean>(false)
  const [parLevel, setParLevel] = useState<number>(0)
  const [description, setDescription] = useState<string>('')

  const isTrayEmpty = trayScrews.length === 0
  const hasPlates =
    selectedTrayType === 'stryker middle face' ||
    selectedTrayType === 'stryker upper face'
  const shouldConfirmButtonBeDisabled =
    !trayImage ||
    (trayImage && trayImage.isUploading) ||
    isSubmitting ||
    !description
  const isInstrumentTray = selectedTrayType === 'instrument'

  useEffect(() => {
    if (selectedTrayType) {
      const analysisFileName = getAnalysisJSONFileName(selectedTrayType)

      setAnalysisFileName(analysisFileName)
    }
  }, [selectedTrayType])

  useEffect(() => {
    if (image) {
      const saveTrayImage = async () => {
        const imgId: string = uuidv4()
        const newTrayImage: InventorySheetFile = {
          id: imgId,
          src: image,
          isUploading: true,
          isUploaded: false,
          mimeType: 'image/jpeg',
        }
        setTrayImage(newTrayImage)

        await uploadAndUpdateTrayImage(imgId)
      }

      saveTrayImage()
    }
  }, [image])

  useEffect(() => {
    if (isComplete) {
      handleAddScrewsToVendorSPDMap()
    }
  }, [isComplete])

  useEffect(() => {
    if (analysisError) {
      setSnackbarMessage(
        'Analysis failed. Please try again with a different image'
      )
      setSnackbarIcon(<ErrorIcon color="error" />)
      setSnackbarState('error')
      setSnackbarOpen(true)
    }
  }, [analysisError])

  useEffect(() => {
    setIsAnalysisFromSPD(true)
  }, [])

  /**
   * Uploads the tray image and updates the tray image state.
   * @param {string} imgId - The ID of the image to upload.
   */
  const uploadAndUpdateTrayImage = async (imgId: string) => {
    const trayImageURL = await uploadFile(imgId, image, 'image/jpeg', false)

    if (trayImageURL) {
      const newTrayImage: InventorySheetFile = {
        id: imgId,
        src: trayImageURL,
        isUploading: false,
        isUploaded: true,
        mimeType: 'image/jpeg',
      }

      setTrayImage(newTrayImage)
    }
  }

  /**
   * Selects the correct function to match the pre-indexed product data to the analyzed screws.
   */
  const getProductMatchingFunction = (trayType: TrayType) => {
    switch (trayType) {
      case 'stryker upper face':
        return matchUpperFaceProduct
      case 'stryker middle face':
        return matchMiddleFaceProduct
      default:
        return matchProduct
    }
  }

  /**
   * Handles adding screws to the vendor SPD map.
   */
  const handleAddScrewsToVendorSPDMap = () => {
    const matchScrewsToProductData =
      getProductMatchingFunction(selectedTrayType)

    const mappedAnalysisScrews: BetterIDTrayScrew[] = analysisScrews.map(
      (analysisScrew: any) => {
        const productData = matchScrewsToProductData(
          analysisScrew.label,
          analysisScrew.column
        )

        return {
          row: analysisScrew.row + 1,
          column: analysisScrew.column,
          label: analysisScrew.label,
          wasted: analysisScrew.wasted,
          x: analysisScrew.x,
          deviceId: productData.deviceID,
          deviceDescription: productData.title,
          company: {
            name: 'Stryker',
            id: 0,
            regEmail: '',
          },
          expirationDate: null,
        }
      }
    )

    const newScrewsFromAnalysis: BetterIDTrayScrew[] =
      mappedAnalysisScrews.filter(
        (mappedAnalysisScrew) =>
          !findScrew(
            mappedAnalysisScrew.label,
            mappedAnalysisScrew.row,
            mappedAnalysisScrew.x
          )
      )

    setTrayScrews((prevTrayScrews) => [
      ...prevTrayScrews,
      ...newScrewsFromAnalysis,
    ])
    setTrayAnalysisScrews((prevTrayScrews) => [
      ...prevTrayScrews,
      ...newScrewsFromAnalysis,
    ])
    setIsComplete(false)
    setIsAnalysisComplete(true)
    setIsTrayMapDrawerOpen(true)
  }

  /**
   * Handles click event for analysis.
   */
  const handleAnalysisClick = () => {
    if (isAnalysisComplete) {
      setIsTrayMapDrawerOpen(true)
      return
    }
    setAnalysisError('')
    if (trayImage) {
      handleSubmitAnalysis(trayImage.src)
    }
  }

  /**
   * Handles closing the tray map drawer.
   */
  const handleTrayMapDrawerClose = () => {
    setIsTrayMapDrawerOpen(false)
  }

  /**
   * Handles capturing the tray image.
   */
  const handleImageCapture = (skipRotate?: boolean) => {
    setIsAnalysisComplete(false)
    handleSPDCaptureImage(skipRotate)
  }

  /**
   * Handles changes to the tray type.
   * @param e - The event object for the select change.
   */
  const handleTrayTypeChange = (e: SelectChangeEvent<string>) => {
    setSelectedTrayType(e.target.value.toLowerCase() as TrayType)
  }

  /**
   * Handles the user's back click.
   */
  const handleBackClick = () => {
    setSelectedTrayType(undefined)
    setTrayImage(undefined)
    setTrayScrews([])
    navigate('../sub-trays')
  }

  /**
   * Handles the logic for adding a new sub-tray.
   *
   * - Generates a new tray number based on the current number of sub-trays.
   * - Creates a new sub-tray object if `trayImage` is available, using the selected tray type and default par level.
   * - Adds the newly created sub-tray to the existing list of sub-trays and updates the state.
   *
   * @remarks
   * The new sub-tray is only added if a valid `trayImage` is present.
   *
   * @see handleConfirm
   */
  const handleAddSubTray = () => {
    const trayNumber = subTrays.length + 1

    if (trayImage) {
      const newSubTray: SubTray = {
        trayNumber,
        trayImg: trayImage.src,
        trayType: selectedTrayType,
        parLevel,
        content: {
          screws: trayScrews,
          plates: trayPlates,
        },
        isInstrumentTray,
        description,
      }

      const newSubTrays = [...subTrays, newSubTray]

      setSubTrays(newSubTrays)
    }
  }

  /**
   * Handles the user induced confirmation action.
   *
   * - Adds a sub tray via `handleAddSubTray`.
   * - Navigates back via `handleBackClick`.
   *
   * @see handleAddSubTray
   * @see handleBackClick
   */
  const handleConfirm = () => {
    handleAddSubTray()
    handleBackClick()
  }

  /**
   * Handles the onChange event for the Par Level Textfield.
   *
   * This function updates the `parLevel` with the numeric value from the input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the number input field.
   */
  const handleTrayParLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    const parLevelValue = value !== '' ? parseFloat(value) : 0

    setParLevel(parLevelValue)
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  return {
    handleImageCapture,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    isTrayMapDrawerOpen,
    handleTrayMapDrawerClose,
    handleAnalysisClick,
    isSubmitting,
    isAnalysisComplete,
    handleTrayTypeChange,
    handleBackClick,
    isTrayEmpty,
    handleConfirm,
    shouldConfirmButtonBeDisabled,
    hasPlates,
    parLevel,
    handleTrayParLevelChange,
    isInstrumentTray,
    description,
    handleDescriptionChange,
  }
}

export default useAnalyzeTrayLogic
