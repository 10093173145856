import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUpdateUserCorrectionsMutation } from 'lib/apollo/hooks'
import { useAssignedDigitalTrays } from '../AssignedDigitalTrays.context'

import { AnalysisResultInput } from 'common/types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { TrayScrew } from './TrayVisualization.types'
import useTrayMapLogic from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import {
  MapContent,
  OnClickActions,
  PlateKey,
} from 'views/SPDLayout/SPD/TrayMap/TrayMap.types'
import { UsagePlate } from '../AssignedDigitalTrays.types'

const useTrayVisualizationLogic = () => {
  const navigate = useNavigate()

  const { updateUserCorrections } = useUpdateUserCorrectionsMutation()
  const {
    trayDetails,
    spdScrews,
    usageScrews,
    setUsageScrews,
    usagePlates,
    setUsagePlates,
  } = useAssignedDigitalTrays()
  const {
    trayPlates,
    setTrayPlates,
    trayScrews,
    setTrayScrews,
    selectedScrew,
    setSelectedScrew,
    selectedPlate,
    setSelectedPlate,
  } = useSPD()
  const { getSelectedJSONFile, getInitialPlateCount, handlePlateCountChange } =
    useTrayMapLogic({ trayType: trayDetails?.trayType })

  const [trayHeight, setTrayHeight] = useState<number | string>('70vh')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedStatus, setSelectedStatus] = useState<'implanted' | 'wasted'>(
    'implanted'
  )
  const [confirmChangesModalOpen, setConfirmChangesModalOpen] = useState(false)
  const [isConfirmScrewRemovalModalOpen, setIsConfirmScrewRemovalModalOpen] =
    useState(false)
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false)

  const trayType = trayDetails?.trayType
  const mapContent: MapContent = {
    screws: trayDetails?.screws,
    plates: trayDetails?.plates,
  }

  useEffect(() => {
    const handleResize = () => {
      const headerHeight =
        document.getElementById('medgeo-app-header')?.offsetHeight || 0
      const statusBarHeight =
        document.getElementById('procedure-status-bar')?.offsetHeight || 0
      const footerHeight =
        document.getElementById('asset-list-footer')?.offsetHeight || 0
      const remainingHeight =
        window.innerHeight - headerHeight - footerHeight - statusBarHeight
      setTrayHeight(remainingHeight > 0 ? remainingHeight : '70vh') // Fallback to initial height if calculation fails
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial call to set the correct height

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (trayDetails) {
      setTrayPlates(trayDetails.plates || [])
    }
  }, [trayDetails?.plates?.length])

  const isScrewUsed = (label: string, row: number, column: number) => {
    return usageScrews.some(
      (screw: any) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const isScrewUsedAndWasted = (label: string, row: number, column: number) => {
    return usageScrews.some(
      (screw: any) =>
        screw.label === label &&
        screw.row === row &&
        screw.x === column &&
        screw.wasted
    )
  }

  const isScrewLoadedFromSPD = (label: string, row: number, column: number) => {
    return spdScrews?.some(
      (screw: BetterIDTrayScrew) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }
  const trayData = getSelectedJSONFile(trayType)
  const screwAreas = trayData.regions[0].areas

  const handleScrewSelection = (
    label: string,
    rowIndex: number,
    column: number,
    x: number
  ) => {
    if (!isInEditMode) {
      return
    }
    const newSelectedScrew = {
      label: label,
      row: rowIndex,
      column: column,
      x,
    }
    setSelectedScrew(newSelectedScrew)

    // see if screw is already in trayScrews array
    if (
      isScrewUsed(
        newSelectedScrew.label,
        newSelectedScrew.row,
        newSelectedScrew.x
      ) ||
      isScrewUsedAndWasted(
        newSelectedScrew.label,
        newSelectedScrew.row,
        newSelectedScrew.x
      )
    ) {
      // remove screw from list
      setIsConfirmScrewRemovalModalOpen(true)
    } else {
      setModalOpen(true)
    }
  }

  const findScrew = (label: string, row: number, column: number) => {
    return spdScrews?.find(
      (screw: BetterIDTrayScrew) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const handleStatusChange = () => {
    // Update status of selected screw
    const updatedScrews = [
      ...trayScrews,
      {
        ...selectedScrew,
        wasted: selectedStatus === 'wasted' ? true : false,
      },
    ] as BetterIDTrayScrew[]
    const screwFromSPD = findScrew(
      selectedScrew?.label as string,
      selectedScrew?.row as number,
      selectedScrew?.x as number
    ) as BetterIDTrayScrew

    setUsageScrews((prevUsageScrews) => {
      if (screwFromSPD !== undefined) {
        return [
          ...prevUsageScrews,
          {
            ...screwFromSPD,
            wasted: selectedStatus === 'wasted' ? true : false,
          },
        ]
      }
      return [...prevUsageScrews]
    })
    setTrayScrews(updatedScrews)
    setModalOpen(false)
    setSelectedScrew(null)
    setSelectedStatus('implanted')
  }

  const handleUpdateUserCorrections = async (
    id: number,
    userCorrections: AnalysisResultInput[]
  ) => {
    try {
      const response = await updateUserCorrections(id, userCorrections)
      localStorage.setItem('analysis-data', JSON.stringify(response))
    } catch (error) {
      console.error('Error updating user corrections:', error)
    }
  }

  const handleConfirm = async () => {
    const analysisResults = await JSON.parse(
      localStorage.getItem('analysis-data') || '{}'
    )
    const userCorrections = trayScrews.map((screw: any) => ({
      column: screw.x,
      size: screw.column,
      label: screw.label,
      row: screw.row,
      wasted: screw.wasted,
    }))

    analysisResults.user_corrections = userCorrections

    localStorage.setItem('analysis-data', JSON.stringify(analysisResults))
    localStorage.setItem('tray-products', JSON.stringify(trayScrews))
    await handleUpdateUserCorrections(analysisResults.id, userCorrections)
    setConfirmChangesModalOpen(false)
    navigate('../tray-products')
  }

  const handleSaveChangesCancellation = () => {
    setConfirmChangesModalOpen(false)
    navigate('../tray-products')
  }

  const handleReloadScrew = () => {
    const newScrews = trayScrews.filter(
      (screw: TrayScrew) =>
        !(
          screw.label === selectedScrew?.label &&
          screw.row === selectedScrew?.row &&
          screw.x === selectedScrew?.x
        )
    )
    const newUsageScrews = usageScrews?.filter(
      (screw: BetterIDTrayScrew) =>
        !(
          screw.label === selectedScrew?.label &&
          screw.row === selectedScrew?.row &&
          screw.x === selectedScrew?.x
        )
    )
    setSelectedScrew(null)
    setUsageScrews(newUsageScrews)
    setTrayScrews(newScrews)
    setIsConfirmScrewRemovalModalOpen(false)
  }

  const handleHelpModalClose = () => {
    setIsHelpModalOpen(false)
  }

  const handleReloadScrewModalClose = () => {
    setIsConfirmScrewRemovalModalOpen(false)
    setSelectedScrew(null)
  }

  const handleConfirmChangesModalClose = () => {
    setConfirmChangesModalOpen(false)
  }

  const handleRemoveScrewModalClose = () => {
    setSelectedScrew(null)
    setModalOpen(false)
  }

  const updatePlateCount = (plateName: PlateKey) => {
    const trayPlateMatch = trayPlates.find(
      (plate) => plate.plateName === plateName
    )

    if (trayPlateMatch) {
      const newUsagePlate: UsagePlate = {
        deviceId: trayPlateMatch.deviceId,
        plateName: trayPlateMatch.plateName,
        wasted: selectedStatus === 'wasted',
      }
      setUsagePlates([...usagePlates, newUsagePlate])
    }
  }

  const handlePlateUsage = (plateName: PlateKey, newCount: number) => {
    const plateMatch = trayPlates.find(
      (trayPlate) => trayPlate.plateName === plateName
    )
    if (plateMatch && plateMatch.plateCount > 0) {
      setSelectedPlate(plateMatch)
      setModalOpen(true)
    }
  }

  const handlePlateStatusChange = () => {
    if (selectedPlate) {
      updatePlateCount(selectedPlate.plateName)

      const newPlateCountInSPD: number = selectedPlate.plateCount - 1
      handlePlateCountChange(selectedPlate.plateName, newPlateCountInSPD)

      setModalOpen(false)
      setSelectedPlate(null)
    }
  }

  const onClickActions: OnClickActions = {
    screw: handleScrewSelection,
    plates: handlePlateUsage,
  }

  return {
    selectedScrew,
    setSelectedScrew,
    trayHeight,
    setTrayHeight,
    modalOpen,
    setModalOpen,
    selectedStatus,
    setSelectedStatus,
    confirmChangesModalOpen,
    setConfirmChangesModalOpen,
    isConfirmScrewRemovalModalOpen,
    setIsConfirmScrewRemovalModalOpen,
    isHelpModalOpen,
    setIsHelpModalOpen,
    trayScrews,
    setTrayScrews,
    isScrewUsed,
    isScrewUsedAndWasted,
    isScrewLoadedFromSPD,
    screwAreas,
    handleScrewSelection,
    handleStatusChange,
    handleConfirm,
    handleSaveChangesCancellation,
    handleReloadScrew,
    handleReloadScrewModalClose,
    handleHelpModalClose,
    handleConfirmChangesModalClose,
    handleRemoveScrewModalClose,
    findScrew,
    isInEditMode,
    setIsInEditMode,
    trayType,
    getInitialPlateCount,
    handlePlateCountChange,
    handlePlateUsage,
    trayDetails,
    mapContent,
    onClickActions,
    selectedPlate,
    updatePlateCount,
    handlePlateStatusChange,
  }
}

export default useTrayVisualizationLogic
