import { ArrowBack } from '@mui/icons-material'
import { Button, Skeleton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'

import useTrayContentLogic from './TrayContent.logic'

import './TrayContent.scss'

const TrayContent = () => {
  const { subTray } = useTrayContentLogic()

  return (
    <div className="tray-content_wrapper">
      <div className="tray-content_header">
        <div className="left_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            to="../sub-trays"
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <Typography variant="h1">View Tray Content</Typography>
        </div>

        <div className="right_container">
          {!subTray ? (
            <Skeleton variant="rounded" animation="wave" className="skeleton" />
          ) : (
            <img src={subTray.trayImg} alt={subTray.trayType} />
          )}
        </div>
      </div>

      {!subTray && (
        <Typography className="error" variant="body1">
          This sub tray does not exist.
        </Typography>
      )}

      {subTray && subTray.trayType !== 'instrument' && (
        <div className="map_container">
          <TrayMap trayType={subTray.trayType} />
        </div>
      )}

      {subTray?.trayType === 'instrument' && (
        <Typography variant="body1" className="instrument-text">
          Tray maps are not available for instrument trays.
        </Typography>
      )}
    </div>
  )
}

export default TrayContent
