import React, { createContext, useContext } from 'react'
import { useAuth0 } from 'app/Auth'
import { User } from 'common/types'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import ErrorDisplay from 'components/ErrorDisplay'
import { captureWarning } from 'lib/monitoring'

type UserProviderProps = { children: React.ReactNode }

export interface UserStateProps {
  user?: User
  isAuthenticated: boolean
  isRep?: boolean
  isNurseAdmin?: boolean
  isNurse?: boolean
  orgName?: string
  orgId?: string
}

enum UserRole {
  REP = 'MGAT_REP',
  NURSE_ADMIN = 'MGAT_NURSEADMIN',
  NURSE = 'MGAT_NURSE',
}

const UserStateContext = createContext(({} as UserStateProps) || undefined)

function UserProvider({ children }: UserProviderProps) {
  const {
    user: authUser,
    logout,
    error: authError,
    isLoading,
    isAuthenticated,
  } = useAuth0()

  const handleLogout = () => {
    logout({ returnTo: `${window.location.origin}/?status=loggedout` })
  }

  const contextValue = {
    user: {
      id: authUser?.sub,
      email: authUser?.email,
      emailVerified: authUser?.email_verified,
      firstName: authUser?.app_metadata?.firstName,
      lastName: authUser?.app_metadata?.lastName,
      mobileNumber: authUser?.app_metadata?.mobile,
      bidCompanyId: authUser?.app_metadata?.bidCompanyId,
      companyName: authUser?.app_metadata?.companyName,
      name: authUser?.name,
      nickname: authUser?.nickname,
      orgId: authUser?.org_id,
      picture: authUser?.picture,
      roles: authUser?.roles,
      updatedAt: authUser?.updated_at,
      invitePending: authUser?.app_metadata?.invitePending,
      inviteCode: authUser?.app_metadata?.inviteCode,
      parentCompanyId: authUser?.app_metadata?.parentCompanyId,
      subsidiariesCompaniesIds:
        authUser?.app_metadata?.subsidiariesCompaniesIds,
      siblingCompaniesIds: authUser?.app_metadata?.siblingCompaniesIds,
    },
    isAuthenticated,
    isRep: authUser?.roles?.includes(UserRole.REP),
    isNurseAdmin: authUser?.roles?.includes(UserRole.NURSE_ADMIN),
    isNurse: authUser?.roles?.includes(UserRole.NURSE),
    orgName: authUser?.organization_name,
    orgId: authUser?.org_id,
  }

  if (isLoading) return <LoadingIndicator text="Loading user" />

  if (authError) {
    // @ts-ignore
    captureWarning(authError || userQuery.error)
    return (
      <ErrorDisplay
        pathForward={() => window.location.reload()}
        pathForwardText="Reload page"
        message="We're having trouble authenticating the user right now. Reload the page or try again later."
        secondaryPathForward={handleLogout}
        secondaryPathForwardText={'Logout'}
      />
    )
  }

  return (
    <UserStateContext.Provider value={contextValue as UserStateProps}>
      {children}
    </UserStateContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserStateContext)

  if (context === undefined)
    throw new Error('useUser must be used with UserProvider')

  return context
}

function getUserRoleString(user: User) {
  if (user.roles?.includes(UserRole.REP)) return 'Rep'
  if (user.roles?.includes(UserRole.NURSE_ADMIN)) return 'Nurse Administrator'

  return 'Nurse'
}

export { UserProvider, useUser, getUserRoleString }
