import { ZodError } from 'zod'

export const getZodError = (error: ZodError) => {
  const errorObject = error.issues.reduce((acc, issue) => {
    const path = issue.path.join('.')
    acc[path] = issue.message
    return acc
  }, {} as Record<string, string>)
  return errorObject
}
