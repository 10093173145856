import { useNavigate } from 'react-router-dom'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { TAlertDialog } from './ReferringPhysicians.types'
import {
  useDeleteReferringPhysicians,
  useGetReferringPhysicians,
} from 'lib/apollo/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { REFERRING_PHYSICIANS } from 'app/ROUTES_CONSTANTS'
import { ReferringPhysician } from 'common/types'
import { enqueueSnackbar } from 'notistack'

const useReferringPhysiciansLogic = () => {
  const navigate = useNavigate()

  const [isSendingBulkEmail, setIsSendingBulkEmail] = useState(false)
  const [searchLabel, setSearchLabel] = useState('Search Physician...')
  const [searchString, setSearchString] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState<TAlertDialog>({
    isOpen: false,
  })

  const referringPhysiciansHeadCells: HeadCell[] = [
    {
      id: 'lastName',
      label: 'Last Name',
      numeric: false,
      sx: {
        minWidth: '10%',
        width: '20%',
      },
    },
    {
      id: 'firstName',
      label: 'First Name',
      numeric: false,
    },
    {
      id: 'email',
      label: 'Email',
      numeric: false,
    },
  ]

  const {
    data: physiciansData,
    loading: isLoadingPhysicians,
    refetch: refetchPhysiciansData,
    error: errorGettingPhysiciansData,
  } = useGetReferringPhysicians()

  const [deleteReferringPhysicians, { loading: isDeletingReferringPhysician }] =
    useDeleteReferringPhysicians()

  const physicians = useMemo(
    () => physiciansData?.getPhysicians?.physicians ?? [],
    [physiciansData]
  )
  const [filteredPhysicians, setFilteredPhysicians] = useState<
    (ReferringPhysician & { checked: boolean })[]
  >([])

  const resetFilteredPhysicians = useCallback(() => {
    if (physicians && physicians.length > 0) {
      const updatedPhysicians = physicians.map((physician) => ({
        ...physician,
        checked: true,
      }))
      setFilteredPhysicians(updatedPhysicians)
    }
  }, [physicians])

  useEffect(() => {
    resetFilteredPhysicians()
    refetchPhysiciansData()
  }, [resetFilteredPhysicians, refetchPhysiciansData])

  useEffect(() => {
    if (filteredPhysicians.some((physician) => !physician.checked)) {
      setSelectAll(false)
    }
    if (filteredPhysicians.every((physician) => physician.checked)) {
      setSelectAll(true)
    }
  }, [filteredPhysicians])

  const handleSearchFunction = (searchString: string) => {
    setSearchString(searchString)
    if (searchString === '') {
      resetFilteredPhysicians()
      return
    }

    setFilteredPhysicians(
      physicians
        .filter(({ firstName, lastName, email }) =>
          `${firstName} ${lastName} ${email}`.includes(
            searchString.toLowerCase()
          )
        )
        .map((physician) => ({
          ...physician,
          checked: true,
        }))
    )
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchFunction(event.target.value)
  }

  const handleFocus = (focus: boolean) => () => {
    setSearchLabel(focus ? 'Search Physician' : 'Search Physician...')
  }

  const closeAlertDialog = () => setIsAlertOpen({ isOpen: false })

  const openAlertDialog = (id: string) => () =>
    setIsAlertOpen({
      isOpen: true,
      id,
      description: `Are you sure you want to delete ${
        physicians.find(({ _id }) => _id === id)?.email
      }?`,
    })

  const navigateToReferringPhysician =
    (state: {
      _id: string
      firstName: string
      lastName: string
      email: string
      edit: boolean
    }) =>
    () => {
      navigate(`/${REFERRING_PHYSICIANS}/${state._id}`, { state })
    }

  const handleDeleteReferringPhysician = async () => {
    if (isAlertOpen && isAlertOpen.id) {
      deleteReferringPhysicians({
        variables: { physicianIds: [isAlertOpen.id] },
      }).then(({ data }) => {
        if (data?.deletePhysicians.success) {
          refetchPhysiciansData()
          setSearchString('')
          setIsAlertOpen({
            isOpen: false,
          })
          enqueueSnackbar(
            `${
              physicians.find(({ _id }) => _id === isAlertOpen.id)?.email
            } was deleted successfully`,
            {
              variant: 'success',
            }
          )
        } else {
          setIsAlertOpen((prev) => ({
            ...prev,
            description: data?.deletePhysicians.message,
          }))
        }
      })
    }
  }

  const handleCreateReferringPhysician = () => {
    navigate(`/${REFERRING_PHYSICIANS}/new`, {
      state: {
        firstName: '',
        lastName: '',
        email: '',
        edit: false,
      },
    })
  }

  const handleBulkSelection = () => {
    resetFilteredPhysicians()
    if (isSendingBulkEmail) {
      setIsSendingBulkEmail(false)
      handleSearchFunction(searchString)
    } else {
      setIsSendingBulkEmail(true)
    }
  }

  const handleCheckPhysicianClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (event.target.id === 'selectAll') {
      setSelectAll(checked)
      setFilteredPhysicians((prev) =>
        prev.map((physician) => ({
          ...physician,
          checked: checked ? true : false,
        }))
      )
      return
    }
    setFilteredPhysicians((prev) => {
      const physicians = [...prev]
      const selectedPhysician = physicians.find(
        (item) => item._id === event.target.id
      )
      if (!selectedPhysician) return prev
      selectedPhysician.checked = checked
      return physicians
    })
  }

  const sendEmail = (id: string) => () => {
    const email = physicians.find(({ _id }) => _id === id)?.email
    if (email) {
      window.open(`mailto:${email}`)
    }
  }

  const sendBulkEmail = () => {
    const emails = filteredPhysicians
      .filter((physician) => physician.checked)
      .map((physician) => physician.email)
    if (emails.length) {
      window.open(`mailto:${emails.join(',')}`)
    }
  }

  return {
    selectAll,
    physicians,
    searchLabel,
    isAlertOpen,
    searchString,
    filteredPhysicians,
    isSendingBulkEmail,
    isLoadingPhysicians,
    errorGettingPhysiciansData,
    referringPhysiciansHeadCells,
    isDeletingReferringPhysician,
    sendEmail,
    handleFocus,
    handleSearch,
    sendBulkEmail,
    openAlertDialog,
    closeAlertDialog,
    handleBulkSelection,
    setIsSendingBulkEmail,
    handleCheckPhysicianClick,
    navigateToReferringPhysician,
    handleCreateReferringPhysician,
    handleDeleteReferringPhysician,
  }
}

export default useReferringPhysiciansLogic
