import { useEffect, useState, useMemo } from 'react'

// Services
import { useCreateLaborCost } from 'lib/services/api/reports-service/labor-cost/useCreateLaborCost'
import { useUpdateLaborCost } from 'lib/services/api/reports-service/labor-cost/useUpdateLaborCost'
import { useBulkUpsertLaborCost } from 'lib/services/api/reports-service/labor-cost/useBulkUpsertLaborCost'
import { useDeleteLaborCost } from 'lib/services/api/reports-service/labor-cost/useDeleteLaborCost'
import { useGetLaborCost } from 'lib/services/api/reports-service/labor-cost/useGetLaborCost'

// Types
import { TableRowProps, Typography } from '@mui/material'
import { INewLaborCost, IUploadedLaborCost } from './LaborCost.types'

// Components
import Button from 'components/molecules/Button/Button'
import { Delete, Edit } from '@mui/icons-material'

// Other
import dayjs from 'lib/dayjs'
import { v4 as uuid } from 'uuid'
import { enqueueSnackbar } from 'notistack'
import { useGetProcedureTypes } from 'lib/services/api/reports-service/preference-card/getProcedureTypes'
import { Option } from 'common/types'

const useLaborCostLogic = () => {
  const [modal, setModal] = useState<{
    isOpen: boolean
    mode?: 'create' | 'update'
  }>({ isOpen: false })
  const [modalId, setModalId] = useState(uuid())
  const [deletedCost, setDeletedCost] = useState<{
    procedureType?: string
    isOpen: boolean
  }>({ isOpen: false })
  const [editedCost, setEditedCost] = useState<IUploadedLaborCost>()
  const [selectedProcedure, setSelectedProcedure] = useState<Option>()

  // Services
  const { mutateAsync: create, isLoading: isLoadingCreate } =
    useCreateLaborCost()
  const { mutateAsync: bulkCreate, isLoading: isLoadingBulkCreate } =
    useBulkUpsertLaborCost()
  const { mutateAsync: update, isLoading: isLoadingUpdate } =
    useUpdateLaborCost()
  const { mutateAsync: deleteLaborCost, isLoading: isLoadingDelete } =
    useDeleteLaborCost()
  const {
    data,
    isLoading: isLoadingLaborCost,
    isError: isErrorLaborCost,
    refetch,
  } = useGetLaborCost()

  const { data: procedureTypesData, isLoading: isLoadingProcedureTypes } =
    useGetProcedureTypes()
  const procedureTypesOptions = procedureTypesData?.procedures
    ?.filter((item) => item && item !== '-')
    .map((item, index) => ({
      id: index,
      name: item,
    }))

  // Filter the data based on search input
  const filteredData = useMemo(() => {
    if (!selectedProcedure) return data

    return data?.filter((item) =>
      item.procedureType
        .toLowerCase()
        .includes(selectedProcedure.name.toLowerCase())
    )
  }, [data, selectedProcedure])

  // Prepare the table data
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const tableData = filteredData?.map((item) => ({
    procedureType: item.procedureType,
    rnCost: currencyFormatter.format(item.rnCost),
    scrubTechCost: currencyFormatter.format(item.scrubTechCost),
    updatedAt: item.updatedAt
      ? dayjs(item.updatedAt).format('MM-DD-YYYY')
      : '-',
    actions: (
      <div className="actions">
        <Button
          size="small"
          endIcon={<Edit color="primary" />}
          onClick={() => {
            setModal({ isOpen: true, mode: 'update' })
            setEditedCost(item)
          }}
        >
          <Typography color="primary" variant="h4">
            Edit
          </Typography>
        </Button>

        <Button
          size="small"
          endIcon={<Delete color="error" />}
          onClick={() => handleConfirmDelete(item.procedureType)}
        >
          <Typography color="error" variant="h4">
            Delete
          </Typography>
        </Button>
      </div>
    ),
  })) as TableRowProps[]

  // Handlers
  const handleSave = (
    data: INewLaborCost | IUploadedLaborCost[],
    mode: 'create' | 'update' | 'upload'
  ) => {
    const isUpload = mode === 'upload'
    const isCreate = mode === 'create'
    const isUpdate = mode === 'update'

    if (isUpload && Array.isArray(data)) {
      bulkCreate(
        data.map((item) => ({
          procedureType: item.procedureType,
          rnCost: item.rnCost || 0,
          scrubTechCost: item.scrubTechCost || 0,
        }))
      )
        .then((res) => {
          enqueueSnackbar(res.message, { variant: 'success' })
          refetch()
          setModal({ isOpen: false })
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.error, {
            variant: 'error',
          })
        })
    }

    if ((isCreate || isUpdate) && typeof data === 'object') {
      const action = isCreate ? create : update
      const { procedureType, rnCost, scrubTechCost } = data as INewLaborCost

      if (procedureType && rnCost !== null && scrubTechCost !== null) {
        action({ procedureType: procedureType.name, rnCost, scrubTechCost })
          .then((res) => {
            enqueueSnackbar(res.message, { variant: 'success' })
            refetch()
            setModal({ isOpen: false })
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.error, {
              variant: 'error',
            })
          })
      }
    }
  }

  const handleConfirmDelete = (procedureType: string) => {
    setDeletedCost({ procedureType, isOpen: true })
  }

  const handleDelete = () => {
    deleteLaborCost({
      procedureType: deletedCost?.procedureType as string,
    })
      .then((res) => {
        refetch()
        setDeletedCost({ isOpen: false })
        enqueueSnackbar(res.message, { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, {
          variant: 'error',
        })
      })
      .finally(() => {
        setDeletedCost({ isOpen: false })
      })
  }

  useEffect(() => {
    if (!modal.isOpen) {
      setModalId(uuid())
    }
  }, [modal.isOpen])

  return {
    modal,
    modalId,
    isLoading: isLoadingCreate || isLoadingBulkCreate || isLoadingUpdate,
    tableData,
    editedCost,
    deletedCost,
    isLoadingDelete,
    isErrorLaborCost,
    isLoadingLaborCost,
    selectedProcedure,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    setModal,
    handleSave,
    handleDelete,
    setDeletedCost,
    setSelectedProcedure,
  }
}

export default useLaborCostLogic
