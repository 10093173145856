import { Location, Surgery } from 'common/types'
import { useUser } from 'app/User'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { Box, Chip, Typography } from '@mui/material'
import './ProcedureListTable.css'
import { ProcedureListTableProps } from './ProcedureList.types'
import { formatSurgeryProviderName } from 'lib/utils/formatSurgeryProviderName'
import { useSetSurgeryStatusAsStarted } from 'lib/apollo/hooks'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useProcedureListTableLogic = ({
  events,
}: ProcedureListTableProps) => {
  const flags = useFlags()
  const navigate = useNavigate()
  const { isNurse } = useUser()
  const hasItems = events && events?.length > 0
  const isMobile = useIsMobile()
  const [setStatus] = useSetSurgeryStatusAsStarted()

  const handleStartSurgery = (surgery: Surgery) => {
    navigate(`/surgeries/${surgery?._id}`)
    if (surgery.status.name !== 'SCANNING') return
    setStatus({
      variables: {
        surgeryId: surgery._id,
      },
    })
  }

  const RoomInformation: React.FC<{
    room: Location['room']
    isManuallyAdded: boolean | null
  }> = ({ room, isManuallyAdded }) => (
    <Box className="room" data-testid="room-number">
      {isManuallyAdded ? (
        <Chip
          label="M"
          color="warning"
          sx={{
            color: 'var(--primary)',
            backgroundColor: 'white',
            fontWeight: 'bold',
          }}
        />
      ) : null}
      <Typography variant="h2">Room</Typography>
      <Typography variant="h2">{room}</Typography>
    </Box>
  )

  const PatientInformation: React.FC<{
    surgery: Surgery
    isNurse: boolean
  }> = ({ surgery, isNurse }) => (
    <Box className="patient" data-testid="patient-procedure-info">
      {isNurse && (
        <>
          <Typography variant="h3">
            {`${
              surgery?.patient?.lastName
            }, ${surgery?.patient?.firstName?.charAt(0)}`}
          </Typography>
          <Typography variant="h6">
            DOB: {surgery?.patient?.dateOfBirth}
          </Typography>
          <Typography variant="h6">MRN: {surgery?.patient?.idMR}</Typography>
          <br />
        </>
      )}
      {flags.procedureListSurgeryType && (
        <Typography variant="h6">
          Surgery: {surgery?.procedures?.[0]?.description}
        </Typography>
      )}
      <Typography variant="h6">
        Surgeon: {formatSurgeryProviderName(surgery)}
      </Typography>
    </Box>
  )

  const caseInformation: React.FC<{ surgery: Surgery; isNurse: boolean }> = ({
    surgery,
    isNurse,
  }) => (
    <>
      <RoomInformation
        room={surgery.visit?.location?.room || ''}
        isManuallyAdded={surgery.isManuallyAdded}
      />
      <PatientInformation surgery={surgery} isNurse={isNurse} />
    </>
  )

  const eventsArray = events && [...events]
  const sortByRoom = eventsArray?.sort((a: Surgery, b: Surgery) => {
    const roomA = a?.visit?.location?.room || ''
    const roomB = b?.visit?.location?.room || ''
    if (roomA < roomB) {
      return -1
    }
    if (roomA > roomB) {
      return 1
    }
    return 0
  })

  return {
    isNurse,
    hasItems,
    isMobile,
    caseInformation,
    sortByRoom,
    handleStartSurgery,
  }
}
