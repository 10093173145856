import { useNavigate } from 'react-router-dom'

import { HeadCell } from 'components/molecules/DataTable/TableHeader'

import { MappedSubTrays, SubTray } from './SubTrays.types'
import { Typography } from '@mui/material'
import { formatToTitleCase } from 'lib/utils/formatToTitleCase'
import { Delete, Visibility } from '@mui/icons-material'
import Button from 'components/molecules/Button/Button'
import { useAddTrayContext } from '../AddTray.context'
import { LoadingButton } from 'components/mui'

const useSubTraysLogic = () => {
  const { subTrays, setSubTrays } = useAddTrayContext()
  const navigate = useNavigate()

  /**
   * Defines the header cells for the tray management table.
   */
  const subTraysTableHeaderCells: HeadCell[] = [
    { id: 'trayNumber', label: 'ID', numeric: true },
    { id: 'trayImg', label: 'Tray Image', numeric: false },
    { id: 'trayType', label: 'Tray Type', numeric: false },
    { id: 'parLevel', label: 'Par Level', numeric: false },
    { id: 'viewDetails', label: 'Tray Details', numeric: false },
    { id: 'deleteSubTray', label: 'Delete Sub-Tray', numeric: false },
  ]

  /**
   * Maps locally created sub trays into structured cells for the sub trays table body.
   * @param {SubTrays[]} subTrays  Array of sub trays to be mapped into table rows.
   * @returns Mapped sub trays data formatted for table display.
   */
  const subTraysTableBodyCells = (subTrays: SubTray[]): MappedSubTrays[] => {
    return subTrays.map((subTray, _index) => {
      return {
        trayNumber: `${_index + 1}`,
        trayImg: <img className="tray_img" src={subTray.trayImg} />,
        trayType: (
          <Typography variant="h4">
            {formatToTitleCase(subTray.trayType || 'Unknown Tray Type')}
          </Typography>
        ),
        parLevel: `${subTray.parLevel}`,
        viewDetails: (
          <Button
            startIcon={<Visibility />}
            onClick={() => handleViewDetailsClick(_index)}
          >
            View Details
          </Button>
        ),
        deleteSubTray: (
          <LoadingButton
            className="delete-sub-tray-button"
            mode="delete"
            sx={{ width: '30px', minWidth: '0px', maxWidth: '30px' }}
            onClick={() => handleDeleteSubTray(_index)}
          >
            <Delete sx={{ width: '17px', height: '17px' }} />
          </LoadingButton>
        ),
      }
    })
  }

  /**
   * Function to handle the "Add Sub Tray" button click.
   */
  const handleAddSubTrayClick = () => {
    navigate('../analyze')
  }

  /**
   * Handles the event when the user clicks to view details of a specific sub-tray.
   *
   * This function navigates to the detailed view of the selected sub-tray by appending
   * the sub-tray index to the current route.
   *
   * @param {number} subTrayIndex - The index of the sub-tray whose details are being viewed.
   *
   * @example
   * handleViewDetailsClick(2)
   * // Navigates to './2' where the details of the third sub-tray can be viewed.
   */
  const handleViewDetailsClick = (subTrayIndex: number) => {
    navigate(`./${subTrayIndex}`)
  }

  const handleDeleteSubTray = (subTrayIndex: number) => {
    const updatedSubTrays = subTrays.filter(
      (_subTray, _index) => _index !== subTrayIndex
    )

    setSubTrays(updatedSubTrays)
  }

  return {
    handleAddSubTrayClick,
    subTraysTableHeaderCells,
    subTraysTableBodyCells,
  }
}

export default useSubTraysLogic
