import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { CameraAlt, KeyboardArrowDown } from '@mui/icons-material'
import moment from 'moment'

import { useAssignedDigitalTrays } from '../AssignedDigitalTrays.context'

import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { TrayComponentProps, TrayData } from './TrayComponent.types'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import './TrayComponent.scss'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'

const TrayComponent = ({
  trayName,
  trayID,
  status,
  assetCount,
  contents,
  vendor,
  procedureID,
  products,
  trayData: tray,
  details,
}: TrayComponentProps) => {
  const navigate = useNavigate()
  const { setSelectedTray } = useAssignedDigitalTrays()

  const [viewDetails, setViewDetails] = useState<boolean>(false)
  const [viewPreviousTrayMap, setViewPreviousTrayMap] = useState<boolean>(false)
  const [isPreviousTrayLoading, setIsPreviousTrayLoading] = useState(true)

  const trayProductDetails = extractTrayJSONData(tray)
  const subItemProductDetails = extractSubTrayJSONData(tray.subItems?.[0])
  const trayData: TrayData[] = [
    { fieldName: 'Tray Barcode', data: trayID },
    { fieldName: 'Status', data: status },
    {
      fieldName: 'Total Product Count',
      data: subItemProductDetails?.screws.length || 0,
    },
    { fieldName: 'Content', data: contents },
    { fieldName: 'Vendor', data: vendor },
  ]

  const handleViewDetailsClick = () => {
    setViewPreviousTrayMap(false)
    setViewDetails((prevValue) => !prevValue)
  }

  const handleViewPreviousTrayMapClick = () => {
    setViewPreviousTrayMap((prevValue) => !prevValue)
  }

  const handlePreviousTrayLoading = () => {
    setIsPreviousTrayLoading(false)
  }

  const handleSelectManuallyClick = () => {
    navigate('../tray-visualization')
  }

  const handleSelectCurrentTray = () => {
    const updatedProductDetails = JSON.stringify({
      ...trayProductDetails,
      screws: subItemProductDetails?.screws || [],
      plates: subItemProductDetails?.plates || [],
    })

    setSelectedTray({ ...tray, productDetails: updatedProductDetails })
  }

  const currentRoute = window.location.pathname
  const lastSlashIndex = currentRoute.lastIndexOf('/')
  const updatedRoute = currentRoute.substring(0, lastSlashIndex)

  return (
    <>
      <Box className="tray-component">
        <Stack className="tray-data_container">
          <img src={TrayIcon} alt="Tray" />

          <Box className="tray-data">
            <Typography className="tray-data_name">{trayName}</Typography>

            {trayData.map((dataField: TrayData) => (
              <Typography
                key={dataField.data}
                variant="body2"
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                  width: '100%',
                }}
              >
                {dataField.fieldName}: {dataField.data}
              </Typography>
            ))}
          </Box>
        </Stack>

        {viewDetails ? (
          <Stack
            className="buttons_container"
            sx={{ mt: { xs: '30px', md: '10px' } }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <button
                className="details_button"
                style={{
                  fontSize: 11,
                  marginRight: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleViewDetailsClick}
              >
                <KeyboardArrowDown
                  sx={{
                    width: '15px',
                    height: '15px',
                    transform: viewDetails ? 'rotate(-180deg)' : 'none',
                  }}
                />{' '}
                Details
              </button>
              {/* <button className="details_button" style={{ fontSize: 11 }}>
                Add Manually
              </button> */}
            </Stack>

            <button
              className="tray-visualization_button"
              style={{
                fontSize: 11,
                backgroundColor: viewPreviousTrayMap ? '#4F34ED' : 'white',
                color: viewPreviousTrayMap ? 'white' : '#4F34ED',
              }}
              onClick={handleViewPreviousTrayMapClick}
            >
              {viewPreviousTrayMap ? 'Hide' : 'View'} Tray
            </button>
          </Stack>
        ) : (
          <Stack
            className="buttons_container"
            sx={{ mt: { xs: '30px', md: '10px' } }}
          >
            <button
              className="details_button"
              style={{
                fontSize: 11,
                marginRight: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleViewDetailsClick}
            >
              <KeyboardArrowDown
                sx={{
                  width: '15px',
                  height: '15px',
                  transform: viewDetails ? 'rotate(-180deg)' : 'none',
                }}
              />{' '}
              Details
            </button>

            <button
              className="manual_button"
              style={{
                fontSize: 11,
                marginRight: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                handleSelectCurrentTray()
                handleSelectManuallyClick()
              }}
            >
              Select Manually
            </button>

            <button
              className="camera_button"
              onClick={() => {
                handleSelectCurrentTray()
                navigate(`${updatedRoute}/capture-tray/${trayID}`)
              }}
            >
              <CameraAlt sx={{ width: '40px', height: '40px' }} />
            </button>
          </Stack>
        )}
      </Box>

      {viewPreviousTrayMap ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            minHeight: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1,
          }}
        >
          {isPreviousTrayLoading ? (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ) : null}

          <img
            alt="Tray Map"
            src={details?.trayImage}
            onLoad={handlePreviousTrayLoading}
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              marginTop: '5px',
            }}
          />
        </Box>
      ) : null}

      {viewDetails ? (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1.5,
            p: 1,
            mt: 1,
          }}
        >
          {products.map((product: BetterIDTrayScrew) => (
            <Box
              key={product.deviceId}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                p: '10px 20px',
                boxShadow: '0px 0px 7px 3px rgba(0,0,0,0.3)',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: 0.5,
                }}
              >
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  color="#11142D"
                  lineHeight={1.3}
                >
                  {product.deviceDescription}
                </Typography>

                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color="#11142D"
                  lineHeight={1.3}
                >
                  Expiration:{' '}
                  {moment(product.expirationDate as string).format('DD-MM-YY')}
                </Typography>

                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color="#11142D"
                  lineHeight={1.3}
                >
                  Manufacturer: {product.company?.name}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color="#11142D"
                  lineHeight={1.3}
                >
                  Device-ID: {product.deviceId}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  )
}

export default TrayComponent
