export const DASHBOARD = 'dashboard'
export const ANALYTICS = 'analytics'
export const USAGE_REPORTS = 'usage-reports'
export const ASSET_REPORTS = 'product-reports'

// DTM
export const DTM = 'dtm-options'
export const DTM_RELOADING = 'dtm-tray-reloading'

// Inventory and receiving
export const RECEIVE = 'receive'
export const SCAN = (id: string) => `scan/${id}`
export const PRODUCT_REQUESTS = 'product-requests'
export const INVENTORY_REPORT = 'inventory-report'

// Location Management
export const LOCATION_MANAGEMENT = 'location-management'
export const REFERRING_PHYSICIANS = 'referring-physicians'
export const LABOR_COST = 'labor-cost'
