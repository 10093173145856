export const rawLocations = [
  {
    createdAt: '2024-06-05T08:33:04.023Z',
    description: 'Test description',
    id: 506,
    isAssignableToDepartment: true,
    name: 'Playwright test 1',
    order: 4,
    parentLocationId: null,
    updatedAt: '2024-06-05T08:33:04.023Z',
  },
  {
    createdAt: '2024-06-05T08:33:10.438Z',
    description: 'Test description',
    id: 507,
    isAssignableToDepartment: false,
    name: 'Room test 1',
    order: 4,
    parentLocationId: 506,
    updatedAt: '2024-06-05T08:33:10.438Z',
  },
  {
    createdAt: '2024-06-05T08:33:16.722Z',
    description: 'Test description',
    id: 508,
    isAssignableToDepartment: false,
    name: 'Room test 2',
    order: 4,
    parentLocationId: 506,
    updatedAt: '2024-06-05T08:33:16.722Z',
  },
  {
    createdAt: '2024-06-05T08:33:22.965Z',
    description: 'Test description',
    id: 509,
    isAssignableToDepartment: false,
    name: 'Location test 1',
    order: 4,
    parentLocationId: 508,
    updatedAt: '2024-06-05T08:33:22.965Z',
  },
  {
    createdAt: '2024-06-05T08:33:29.237Z',
    description: 'Test description',
    id: 510,
    isAssignableToDepartment: false,
    name: 'Location test 2',
    order: 4,
    parentLocationId: 508,
    updatedAt: '2024-06-05T08:33:29.237Z',
  },
  {
    createdAt: '2024-04-27T13:05:39.457Z',
    description: 'Vail Health Surgery Center',
    id: 144,
    isAssignableToDepartment: true,
    name: 'Vail Health',
    order: 7,
    parentLocationId: null,
    updatedAt: '2024-04-27T13:05:39.457Z',
  },
  {
    createdAt: '2024-04-27T13:06:50.407Z',
    description: 'Vail Health Surgery Center',
    id: 145,
    isAssignableToDepartment: false,
    name: 'Vail Valley Surgery Center 1',
    order: 7,
    parentLocationId: 144,
    updatedAt: '2024-04-27T13:06:50.407Z',
  },
  {
    createdAt: '2024-04-27T13:06:56.647Z',
    description: 'Vail Health Surgery Center',
    id: 146,
    isAssignableToDepartment: false,
    name: 'Vail Valley Surgery Center 2',
    order: 7,
    parentLocationId: 144,
    updatedAt: '2024-04-27T13:06:56.647Z',
  },
  {
    createdAt: '2024-04-27T13:07:36.316Z',
    description: 'Vail Health Surgery Center',
    id: 147,
    isAssignableToDepartment: true,
    name: 'Dillon Surgery Center ',
    order: 7,
    parentLocationId: 144,
    updatedAt: '2024-04-27T13:07:36.316Z',
  },
  {
    createdAt: '2024-04-27T13:08:22.371Z',
    description: 'Vail Health Surgery Center',
    id: 148,
    isAssignableToDepartment: false,
    name: 'Steadman Philippon Surgery Center ',
    order: 7,
    parentLocationId: 144,
    updatedAt: '2024-04-27T13:08:22.371Z',
  },
  {
    createdAt: '2024-06-05T08:33:35.484Z',
    description: 'Test description',
    id: 511,
    isAssignableToDepartment: false,
    name: 'Shelf test 1',
    order: 4,
    parentLocationId: 510,
    updatedAt: '2024-06-05T08:33:35.484Z',
  },
  {
    createdAt: '2024-06-05T08:33:41.760Z',
    description: 'Test description',
    id: 512,
    isAssignableToDepartment: false,
    name: 'Shelf test 2',
    order: 4,
    parentLocationId: 510,
    updatedAt: '2024-06-05T08:33:41.760Z',
  },
  {
    createdAt: '2024-06-05T08:34:58.736Z',
    description: 'Test description',
    id: 513,
    isAssignableToDepartment: false,
    name: 'Location test 1',
    order: 4,
    parentLocationId: 13,
    updatedAt: '2024-06-05T08:34:58.736Z',
  },
  {
    createdAt: '2024-03-21T13:40:08.016Z',
    description: null,
    id: 10,
    isAssignableToDepartment: true,
    name: 'PCOMS',
    order: 1,
    parentLocationId: null,
    updatedAt: '2024-03-21T13:40:08.016Z',
  },
  {
    createdAt: '2024-06-05T08:35:02.735Z',
    description: 'Test description',
    id: 514,
    isAssignableToDepartment: false,
    name: 'Location test 2',
    order: 4,
    parentLocationId: 13,
    updatedAt: '2024-06-05T08:35:02.735Z',
  },
  {
    createdAt: '2024-06-05T08:35:07.093Z',
    description: 'Test description',
    id: 515,
    isAssignableToDepartment: true,
    name: 'Location test 3',
    order: 4,
    parentLocationId: 13,
    updatedAt: '2024-06-05T08:35:07.093Z',
  },
  {
    createdAt: '2024-06-05T08:35:21.612Z',
    description: 'Test description',
    id: 516,
    isAssignableToDepartment: false,
    name: 'Shelf test 1',
    order: 4,
    parentLocationId: 10,
    updatedAt: '2024-06-05T08:35:36.534Z',
  },
  {
    createdAt: '2024-06-05T08:35:25.625Z',
    description: 'Test description',
    id: 517,
    isAssignableToDepartment: false,
    name: 'Shelf test 2',
    order: 4,
    parentLocationId: 13,
    updatedAt: '2024-06-05T08:35:36.534Z',
  },
  {
    createdAt: '2024-06-05T10:45:26.740Z',
    description: 'Test description',
    id: 543,
    isAssignableToDepartment: true,
    name: 'Playwright test 4',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-06-05T10:45:26.740Z',
  },
  {
    createdAt: '2024-03-22T16:45:59.907Z',
    description: null,
    id: 11,
    isAssignableToDepartment: false,
    name: 'Glendale',
    order: 1,
    parentLocationId: 10,
    updatedAt: '2024-03-21T13:40:08.016Z',
  },
  {
    createdAt: '2024-03-22T16:46:00.066Z',
    description: null,
    id: 12,
    isAssignableToDepartment: false,
    name: 'Arcadia',
    order: 2,
    parentLocationId: 10,
    updatedAt: '2024-03-21T13:40:08.016Z',
  },
  {
    createdAt: '2024-03-22T16:46:00.303Z',
    description: null,
    id: 13,
    isAssignableToDepartment: false,
    name: 'Mission Hills',
    order: 3,
    parentLocationId: 10,
    updatedAt: '2024-03-21T13:40:08.016Z',
  },
  {
    createdAt: '2024-06-05T10:45:33.115Z',
    description: 'Test description',
    id: 544,
    isAssignableToDepartment: false,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 543,
    updatedAt: '2024-06-05T10:45:33.115Z',
  },
  {
    createdAt: '2024-06-05T10:45:39.345Z',
    description: 'Test description',
    id: 545,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 543,
    updatedAt: '2024-06-05T10:45:39.345Z',
  },
  {
    createdAt: '2024-06-05T10:45:50.537Z',
    description: 'Test description',
    id: 546,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 545,
    updatedAt: '2024-06-05T10:45:50.537Z',
  },
  {
    createdAt: '2024-06-05T10:45:56.777Z',
    description: 'Test description',
    id: 547,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 545,
    updatedAt: '2024-06-05T10:45:56.777Z',
  },
  {
    createdAt: '2024-06-05T10:46:02.983Z',
    description: 'Test description',
    id: 548,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 547,
    updatedAt: '2024-06-05T10:46:02.983Z',
  },
  {
    createdAt: '2024-06-05T10:46:09.177Z',
    description: 'Test description',
    id: 549,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 547,
    updatedAt: '2024-06-05T10:46:09.177Z',
  },
  {
    createdAt: '2024-06-05T10:46:13.537Z',
    description: 'Test description',
    id: 550,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-06-05T10:46:13.537Z',
  },
  {
    createdAt: '2024-06-05T10:46:17.567Z',
    description: 'Test description',
    id: 551,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-06-05T10:46:17.567Z',
  },
  {
    createdAt: '2024-06-05T10:46:21.894Z',
    description: 'Test description',
    id: 552,
    isAssignableToDepartment: true,
    name: 'Location test 3',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-06-05T10:46:21.894Z',
  },
  {
    createdAt: '2024-06-05T10:46:36.254Z',
    description: 'Test description',
    id: 553,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 530,
    updatedAt: '2024-06-05T10:46:49.660Z',
  },
  {
    createdAt: '2024-06-05T10:46:40.216Z',
    description: 'Test description',
    id: 554,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-06-05T10:46:49.660Z',
  },
  {
    createdAt: '2024-06-12T09:20:49.510Z',
    description: '',
    id: 555,
    isAssignableToDepartment: true,
    name: 'Shelf testttt',
    order: 20,
    parentLocationId: 509,
    updatedAt: '2024-06-12T09:20:49.510Z',
  },
  {
    createdAt: '2024-07-09T12:51:04.516Z',
    description: 'Test description',
    id: 556,
    isAssignableToDepartment: true,
    name: 'Playwright test 5',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-07-09T12:51:04.516Z',
  },
  {
    createdAt: '2024-07-09T12:51:10.965Z',
    description: 'Test description',
    id: 557,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 556,
    updatedAt: '2024-07-09T12:51:10.965Z',
  },
  {
    createdAt: '2024-07-09T12:51:17.127Z',
    description: 'Test description',
    id: 558,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 556,
    updatedAt: '2024-07-09T12:51:17.127Z',
  },
  {
    createdAt: '2024-07-09T12:51:23.306Z',
    description: 'Test description',
    id: 559,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 558,
    updatedAt: '2024-07-09T12:51:23.306Z',
  },
  {
    createdAt: '2024-07-09T12:51:29.568Z',
    description: 'Test description',
    id: 560,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 558,
    updatedAt: '2024-07-09T12:51:29.568Z',
  },
  {
    createdAt: '2024-07-09T12:51:35.825Z',
    description: 'Test description',
    id: 561,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 560,
    updatedAt: '2024-07-09T12:51:35.825Z',
  },
  {
    createdAt: '2024-07-09T12:51:42.082Z',
    description: 'Test description',
    id: 562,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 560,
    updatedAt: '2024-07-09T12:51:42.082Z',
  },
  {
    createdAt: '2024-07-09T12:52:41.312Z',
    description: 'Test description',
    id: 563,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 530,
    updatedAt: '2024-07-09T12:52:54.947Z',
  },
  {
    createdAt: '2024-07-09T12:52:44.992Z',
    description: 'Test description',
    id: 564,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-07-09T12:52:54.947Z',
  },
  {
    createdAt: '2024-07-12T05:45:28.450Z',
    description: '',
    id: 565,
    isAssignableToDepartment: true,
    name: 'Shelt test 3',
    order: 20,
    parentLocationId: 510,
    updatedAt: '2024-07-12T05:45:28.450Z',
  },
  {
    createdAt: '2024-08-01T12:30:55.944Z',
    description: 'Test description',
    id: 570,
    isAssignableToDepartment: true,
    name: 'Playwright test 6',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-01T12:30:55.944Z',
  },
  {
    createdAt: '2024-08-01T12:31:02.642Z',
    description: 'Test description',
    id: 571,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 570,
    updatedAt: '2024-08-01T12:31:02.642Z',
  },
  {
    createdAt: '2024-08-01T12:31:09.146Z',
    description: 'Test description',
    id: 572,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 570,
    updatedAt: '2024-08-01T12:31:09.146Z',
  },
  {
    createdAt: '2024-08-01T12:31:14.085Z',
    description: 'Test description',
    id: 573,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 571,
    updatedAt: '2024-08-01T12:31:14.085Z',
  },
  {
    createdAt: '2024-08-01T12:31:20.477Z',
    description: 'Test description',
    id: 574,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 571,
    updatedAt: '2024-08-01T12:31:20.477Z',
  },
  {
    createdAt: '2024-08-01T12:31:26.770Z',
    description: 'Test description',
    id: 575,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 574,
    updatedAt: '2024-08-01T12:31:26.770Z',
  },
  {
    createdAt: '2024-08-01T12:31:32.987Z',
    description: 'Test description',
    id: 576,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 574,
    updatedAt: '2024-08-01T12:31:32.987Z',
  },
  {
    createdAt: '2024-08-05T12:30:00.929Z',
    description: 'Test description',
    id: 600,
    isAssignableToDepartment: true,
    name: 'Playwright test 10',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-05T12:30:00.929Z',
  },
  {
    createdAt: '2024-08-05T12:33:47.007Z',
    description: 'Test description',
    id: 601,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 530,
    updatedAt: '2024-08-05T12:34:00.781Z',
  },
  {
    createdAt: '2024-08-05T12:33:50.641Z',
    description: 'Test description',
    id: 602,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-08-05T12:34:00.781Z',
  },
  {
    createdAt: '2024-08-05T12:36:47.815Z',
    description: 'Test description',
    id: 603,
    isAssignableToDepartment: true,
    name: 'Playwright test 11',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-05T12:36:47.815Z',
  },
  {
    createdAt: '2024-08-05T12:37:54.644Z',
    description: 'Test description',
    id: 604,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 603,
    updatedAt: '2024-08-05T12:37:54.644Z',
  },
  {
    createdAt: '2024-08-05T12:38:05.858Z',
    description: 'Test description',
    id: 605,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 603,
    updatedAt: '2024-08-05T12:38:05.858Z',
  },
  {
    createdAt: '2024-08-05T12:38:12.021Z',
    description: 'Test description',
    id: 606,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 604,
    updatedAt: '2024-08-05T12:38:12.021Z',
  },
  {
    createdAt: '2024-08-05T12:38:22.466Z',
    description: 'Test description',
    id: 607,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 604,
    updatedAt: '2024-08-05T12:38:22.466Z',
  },
  {
    createdAt: '2024-08-05T12:38:28.212Z',
    description: 'Test description',
    id: 608,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 606,
    updatedAt: '2024-08-05T12:38:28.212Z',
  },
  {
    createdAt: '2024-08-13T10:18:46.158Z',
    description: 'Test description',
    id: 616,
    isAssignableToDepartment: true,
    name: 'Playwright test 13',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-13T10:18:46.158Z',
  },
  {
    createdAt: '2024-08-13T10:18:52.507Z',
    description: 'Test description',
    id: 617,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 616,
    updatedAt: '2024-08-13T10:18:52.507Z',
  },
  {
    createdAt: '2024-08-13T10:18:58.713Z',
    description: 'Test description',
    id: 618,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 616,
    updatedAt: '2024-08-13T10:18:58.713Z',
  },
  {
    createdAt: '2024-08-13T10:19:04.898Z',
    description: 'Test description',
    id: 619,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 618,
    updatedAt: '2024-08-13T10:19:04.898Z',
  },
  {
    createdAt: '2024-08-13T10:19:11.191Z',
    description: 'Test description',
    id: 620,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 618,
    updatedAt: '2024-08-13T10:19:11.191Z',
  },
  {
    createdAt: '2024-08-13T10:19:17.549Z',
    description: 'Test description',
    id: 621,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 620,
    updatedAt: '2024-08-13T10:19:17.549Z',
  },
  {
    createdAt: '2024-08-13T10:19:23.745Z',
    description: 'Test description',
    id: 622,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 620,
    updatedAt: '2024-08-13T10:19:23.745Z',
  },
  {
    createdAt: '2024-08-13T10:20:21.851Z',
    description: 'Test description',
    id: 623,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 530,
    updatedAt: '2024-08-13T10:20:35.501Z',
  },
  {
    createdAt: '2024-08-13T10:20:25.838Z',
    description: 'Test description',
    id: 624,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-08-13T10:20:35.501Z',
  },
  {
    createdAt: '2024-08-28T16:58:23.408Z',
    description: 'Test description',
    id: 634,
    isAssignableToDepartment: true,
    name: 'Playwright test 15',
    order: 28,
    parentLocationId: null,
    updatedAt: '2024-08-28T16:58:23.408Z',
  },
  {
    createdAt: '2024-08-28T16:58:29.653Z',
    description: 'Test description',
    id: 635,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 28,
    parentLocationId: 634,
    updatedAt: '2024-08-28T16:58:29.653Z',
  },
  {
    createdAt: '2024-08-28T16:58:35.898Z',
    description: 'Test description',
    id: 636,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 28,
    parentLocationId: 634,
    updatedAt: '2024-08-28T16:58:35.898Z',
  },
  {
    createdAt: '2024-08-28T16:58:42.167Z',
    description: 'Test description',
    id: 637,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 28,
    parentLocationId: 636,
    updatedAt: '2024-08-28T16:58:42.167Z',
  },
  {
    createdAt: '2024-08-28T16:58:48.405Z',
    description: 'Test description',
    id: 638,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 28,
    parentLocationId: 636,
    updatedAt: '2024-08-28T16:58:48.405Z',
  },
  {
    createdAt: '2024-08-28T16:58:54.621Z',
    description: 'Test description',
    id: 639,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 28,
    parentLocationId: 638,
    updatedAt: '2024-08-28T16:58:54.621Z',
  },
  {
    createdAt: '2024-06-05T09:07:59.049Z',
    description: 'Test description',
    id: 518,
    isAssignableToDepartment: true,
    name: 'Playwright test 2',
    order: 4,
    parentLocationId: null,
    updatedAt: '2024-06-05T09:07:59.049Z',
  },
  {
    createdAt: '2024-06-05T09:08:05.428Z',
    description: 'Test description',
    id: 519,
    isAssignableToDepartment: false,
    name: 'Room test 1',
    order: 5,
    parentLocationId: 518,
    updatedAt: '2024-06-05T09:08:05.428Z',
  },
  {
    createdAt: '2024-06-05T09:08:12.124Z',
    description: 'Test description',
    id: 520,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 6,
    parentLocationId: 518,
    updatedAt: '2024-06-05T09:08:12.124Z',
  },
  {
    createdAt: '2024-06-05T09:08:16.626Z',
    description: 'Test description',
    id: 521,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 7,
    parentLocationId: 519,
    updatedAt: '2024-06-05T09:08:16.626Z',
  },
  {
    createdAt: '2024-06-05T09:08:22.986Z',
    description: 'Test description',
    id: 522,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 8,
    parentLocationId: 519,
    updatedAt: '2024-06-05T09:08:22.986Z',
  },
  {
    createdAt: '2024-06-05T09:08:29.228Z',
    description: 'Test description',
    id: 523,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 9,
    parentLocationId: 522,
    updatedAt: '2024-06-05T09:08:29.228Z',
  },
  {
    createdAt: '2024-06-05T09:08:35.626Z',
    description: 'Test description',
    id: 524,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 10,
    parentLocationId: 522,
    updatedAt: '2024-06-05T09:08:35.626Z',
  },
  {
    createdAt: '2024-06-05T09:08:56.902Z',
    description: 'Test description',
    id: 525,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 8,
    parentLocationId: 520,
    updatedAt: '2024-06-05T09:08:56.902Z',
  },
  {
    createdAt: '2024-06-05T09:09:00.854Z',
    description: 'Test description',
    id: 526,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 9,
    parentLocationId: 520,
    updatedAt: '2024-06-05T09:09:00.854Z',
  },
  {
    createdAt: '2024-06-05T09:09:05.167Z',
    description: 'Test description',
    id: 527,
    isAssignableToDepartment: true,
    name: 'Location test 3',
    order: 10,
    parentLocationId: 520,
    updatedAt: '2024-06-05T09:09:05.167Z',
  },
  {
    createdAt: '2024-06-05T09:09:19.734Z',
    description: 'Test description',
    id: 528,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 11,
    parentLocationId: 518,
    updatedAt: '2024-06-05T09:09:33.352Z',
  },
  {
    createdAt: '2024-06-05T09:09:23.687Z',
    description: 'Test description',
    id: 529,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 12,
    parentLocationId: 520,
    updatedAt: '2024-06-05T09:09:33.352Z',
  },
  {
    createdAt: '2024-07-22T16:43:49.737Z',
    description: 'This i a test to create location',
    id: 567,
    isAssignableToDepartment: true,
    name: 'New test',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-07-22T16:43:49.737Z',
  },
  {
    createdAt: '2024-07-22T16:44:51.399Z',
    description: 'This is to test New Room',
    id: 568,
    isAssignableToDepartment: false,
    name: 'New Room test',
    order: 20,
    parentLocationId: 567,
    updatedAt: '2024-07-22T16:44:51.399Z',
  },
  {
    createdAt: '2024-07-22T16:45:06.901Z',
    description: 'New Location test for testing',
    id: 569,
    isAssignableToDepartment: true,
    name: 'New Location Test',
    order: 20,
    parentLocationId: 568,
    updatedAt: '2024-07-22T16:45:06.901Z',
  },
  {
    createdAt: '2024-08-01T12:36:00.049Z',
    description: 'Test description',
    id: 577,
    isAssignableToDepartment: true,
    name: 'Playwright test 7',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-01T12:36:00.049Z',
  },
  {
    createdAt: '2024-08-01T12:36:06.479Z',
    description: 'Test description',
    id: 578,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 577,
    updatedAt: '2024-08-01T12:36:06.479Z',
  },
  {
    createdAt: '2024-08-01T12:36:12.758Z',
    description: 'Test description',
    id: 579,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 577,
    updatedAt: '2024-08-01T12:36:12.758Z',
  },
  {
    createdAt: '2024-08-01T12:36:19.012Z',
    description: 'Test description',
    id: 580,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 579,
    updatedAt: '2024-08-01T12:36:19.012Z',
  },
  {
    createdAt: '2024-08-01T12:36:25.416Z',
    description: 'Test description',
    id: 581,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 579,
    updatedAt: '2024-08-01T12:36:25.416Z',
  },
  {
    createdAt: '2024-08-01T12:36:31.548Z',
    description: 'Test description',
    id: 582,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 581,
    updatedAt: '2024-08-01T12:36:31.548Z',
  },
  {
    createdAt: '2024-08-01T12:36:37.806Z',
    description: 'Test description',
    id: 583,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 581,
    updatedAt: '2024-08-01T12:36:37.806Z',
  },
  {
    createdAt: '2024-08-01T12:37:41.249Z',
    description: 'Test description',
    id: 584,
    isAssignableToDepartment: true,
    name: 'Playwright test 8',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-01T12:37:41.249Z',
  },
  {
    createdAt: '2024-08-01T12:37:47.675Z',
    description: 'Test description',
    id: 585,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 584,
    updatedAt: '2024-08-01T12:37:47.675Z',
  },
  {
    createdAt: '2024-08-01T12:37:53.929Z',
    description: 'Test description',
    id: 586,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 584,
    updatedAt: '2024-08-01T12:37:53.929Z',
  },
  {
    createdAt: '2024-08-01T12:38:00.205Z',
    description: 'Test description',
    id: 587,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 586,
    updatedAt: '2024-08-01T12:38:00.205Z',
  },
  {
    createdAt: '2024-08-01T12:38:06.534Z',
    description: 'Test description',
    id: 588,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 586,
    updatedAt: '2024-08-01T12:38:06.534Z',
  },
  {
    createdAt: '2024-08-01T12:38:11.665Z',
    description: 'Test description',
    id: 589,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 587,
    updatedAt: '2024-08-01T12:38:11.665Z',
  },
  {
    createdAt: '2024-08-01T12:38:18.143Z',
    description: 'Test description',
    id: 590,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 587,
    updatedAt: '2024-08-01T12:38:18.143Z',
  },
  {
    createdAt: '2024-08-01T12:40:39.588Z',
    description: 'Test description',
    id: 591,
    isAssignableToDepartment: true,
    name: 'Playwright test 9',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-01T12:40:39.588Z',
  },
  {
    createdAt: '2024-08-01T12:40:45.926Z',
    description: 'Test description',
    id: 592,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 591,
    updatedAt: '2024-08-01T12:40:45.926Z',
  },
  {
    createdAt: '2024-08-01T12:40:52.164Z',
    description: 'Test description',
    id: 593,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 591,
    updatedAt: '2024-08-01T12:40:52.164Z',
  },
  {
    createdAt: '2024-08-01T12:40:58.437Z',
    description: 'Test description',
    id: 594,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 593,
    updatedAt: '2024-08-01T12:40:58.437Z',
  },
  {
    createdAt: '2024-08-01T12:41:04.711Z',
    description: 'Test description',
    id: 595,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 593,
    updatedAt: '2024-08-01T12:41:04.711Z',
  },
  {
    createdAt: '2024-08-01T12:41:11.021Z',
    description: 'Test description',
    id: 596,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 595,
    updatedAt: '2024-08-01T12:41:11.021Z',
  },
  {
    createdAt: '2024-08-01T12:41:17.203Z',
    description: 'Test description',
    id: 597,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 595,
    updatedAt: '2024-08-01T12:41:17.203Z',
  },
  {
    createdAt: '2024-08-01T12:42:59.841Z',
    description: 'Test description',
    id: 598,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 530,
    updatedAt: '2024-08-01T12:43:13.421Z',
  },
  {
    createdAt: '2024-08-01T12:43:03.870Z',
    description: 'Test description',
    id: 599,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 533,
    updatedAt: '2024-08-01T12:43:13.421Z',
  },
  {
    createdAt: '2024-08-05T12:47:41.173Z',
    description: 'Test description',
    id: 609,
    isAssignableToDepartment: true,
    name: 'Playwright test 12',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-05T12:47:41.173Z',
  },
  {
    createdAt: '2024-08-05T12:47:47.540Z',
    description: 'Test description',
    id: 610,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 609,
    updatedAt: '2024-08-05T12:47:47.540Z',
  },
  {
    createdAt: '2024-08-05T12:47:53.795Z',
    description: 'Test description',
    id: 611,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 20,
    parentLocationId: 609,
    updatedAt: '2024-08-05T12:47:53.795Z',
  },
  {
    createdAt: '2024-08-05T12:48:00.119Z',
    description: 'Test description',
    id: 612,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 20,
    parentLocationId: 611,
    updatedAt: '2024-08-05T12:48:00.119Z',
  },
  {
    createdAt: '2024-08-05T12:48:07.236Z',
    description: 'Test description',
    id: 613,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 20,
    parentLocationId: 611,
    updatedAt: '2024-08-05T12:48:07.236Z',
  },
  {
    createdAt: '2024-08-05T12:48:13.519Z',
    description: 'Test description',
    id: 614,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 20,
    parentLocationId: 613,
    updatedAt: '2024-08-05T12:48:13.519Z',
  },
  {
    createdAt: '2024-08-05T12:48:19.934Z',
    description: 'Test description',
    id: 615,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 20,
    parentLocationId: 613,
    updatedAt: '2024-08-05T12:48:19.934Z',
  },
  {
    createdAt: '2024-08-13T11:36:57.944Z',
    description: 'Test description',
    id: 625,
    isAssignableToDepartment: true,
    name: 'Playwright test 14',
    order: 20,
    parentLocationId: null,
    updatedAt: '2024-08-13T11:36:57.944Z',
  },
  {
    createdAt: '2024-08-28T16:59:00.904Z',
    description: 'Test description',
    id: 640,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 28,
    parentLocationId: 638,
    updatedAt: '2024-08-28T16:59:00.904Z',
  },
  {
    createdAt: '2024-06-05T09:13:13.456Z',
    description: 'Test description',
    id: 530,
    isAssignableToDepartment: true,
    name: 'Playwright test 3',
    order: 13,
    parentLocationId: null,
    updatedAt: '2024-06-05T09:13:13.456Z',
  },
  {
    createdAt: '2024-06-05T09:13:20.114Z',
    description: 'Test description',
    id: 531,
    isAssignableToDepartment: false,
    name: 'Room test 1',
    order: 14,
    parentLocationId: 530,
    updatedAt: '2024-06-05T09:13:20.114Z',
  },
  {
    createdAt: '2024-06-05T09:13:20.533Z',
    description: 'Test description',
    id: 532,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 14,
    parentLocationId: 530,
    updatedAt: '2024-06-05T09:13:20.533Z',
  },
  {
    createdAt: '2024-06-05T09:13:26.620Z',
    description: 'Test description',
    id: 533,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 15,
    parentLocationId: 530,
    updatedAt: '2024-06-05T09:13:26.620Z',
  },
  {
    createdAt: '2024-06-05T09:13:31.019Z',
    description: 'Test description',
    id: 534,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 16,
    parentLocationId: 531,
    updatedAt: '2024-06-05T09:13:31.019Z',
  },
  {
    createdAt: '2024-06-05T09:13:37.354Z',
    description: 'Test description',
    id: 535,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 17,
    parentLocationId: 531,
    updatedAt: '2024-06-05T09:13:37.354Z',
  },
  {
    createdAt: '2024-06-05T09:13:43.577Z',
    description: 'Test description',
    id: 536,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 18,
    parentLocationId: 535,
    updatedAt: '2024-06-05T09:13:43.577Z',
  },
  {
    createdAt: '2024-06-05T09:13:49.818Z',
    description: 'Test description',
    id: 537,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 19,
    parentLocationId: 535,
    updatedAt: '2024-06-05T09:13:49.818Z',
  },
  {
    createdAt: '2024-06-05T09:13:58.664Z',
    description: 'Test description',
    id: 538,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 15,
    parentLocationId: 532,
    updatedAt: '2024-06-05T09:13:58.664Z',
  },
  {
    createdAt: '2024-06-05T09:14:02.677Z',
    description: 'Test description',
    id: 539,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 16,
    parentLocationId: 532,
    updatedAt: '2024-06-05T09:14:02.677Z',
  },
  {
    createdAt: '2024-06-05T09:14:06.982Z',
    description: 'Test description',
    id: 540,
    isAssignableToDepartment: true,
    name: 'Location test 3',
    order: 17,
    parentLocationId: 532,
    updatedAt: '2024-06-05T09:14:06.982Z',
  },
  {
    createdAt: '2024-06-05T09:14:21.462Z',
    description: 'Test description',
    id: 541,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 18,
    parentLocationId: 540,
    updatedAt: '2024-06-05T09:14:21.462Z',
  },
  {
    createdAt: '2024-06-05T09:14:25.367Z',
    description: 'Test description',
    id: 542,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 19,
    parentLocationId: 540,
    updatedAt: '2024-06-05T09:14:25.367Z',
  },
  {
    createdAt: '2024-08-13T11:37:04.235Z',
    description: 'Test description',
    id: 626,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 20,
    parentLocationId: 625,
    updatedAt: '2024-08-13T11:37:04.235Z',
  },
  {
    createdAt: '2024-08-13T11:37:10.448Z',
    description: 'Test description',
    id: 627,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 21,
    parentLocationId: 625,
    updatedAt: '2024-08-13T11:37:10.448Z',
  },
  {
    createdAt: '2024-08-13T11:37:16.693Z',
    description: 'Test description',
    id: 628,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 22,
    parentLocationId: 627,
    updatedAt: '2024-08-13T11:37:16.693Z',
  },
  {
    createdAt: '2024-08-13T11:37:22.950Z',
    description: 'Test description',
    id: 629,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 23,
    parentLocationId: 627,
    updatedAt: '2024-08-13T11:37:22.950Z',
  },
  {
    createdAt: '2024-08-13T11:37:29.237Z',
    description: 'Test description',
    id: 630,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 24,
    parentLocationId: 629,
    updatedAt: '2024-08-13T11:37:29.237Z',
  },
  {
    createdAt: '2024-08-13T11:37:35.502Z',
    description: 'Test description',
    id: 631,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 25,
    parentLocationId: 629,
    updatedAt: '2024-08-13T11:37:35.502Z',
  },
  {
    createdAt: '2024-08-13T11:38:35.903Z',
    description: 'Test description',
    id: 632,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 26,
    parentLocationId: 599,
    updatedAt: '2024-08-13T11:38:35.903Z',
  },
  {
    createdAt: '2024-08-13T11:38:39.340Z',
    description: 'Test description',
    id: 633,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 27,
    parentLocationId: 599,
    updatedAt: '2024-08-13T11:38:39.340Z',
  },
  {
    createdAt: '2024-09-02T12:35:10.346Z',
    description: 'Test description',
    id: 641,
    isAssignableToDepartment: true,
    name: 'Playwright test 16',
    order: 28,
    parentLocationId: null,
    updatedAt: '2024-09-02T12:35:10.346Z',
  },
  {
    createdAt: '2024-09-02T12:35:16.228Z',
    description: 'Test description',
    id: 642,
    isAssignableToDepartment: false,
    name: 'Room test 1',
    order: 29,
    parentLocationId: 641,
    updatedAt: '2024-09-02T12:35:16.228Z',
  },
  {
    createdAt: '2024-09-02T12:35:16.635Z',
    description: 'Test description',
    id: 643,
    isAssignableToDepartment: true,
    name: 'Room test 1',
    order: 29,
    parentLocationId: 641,
    updatedAt: '2024-09-02T12:35:16.635Z',
  },
  {
    createdAt: '2024-09-02T12:35:20.170Z',
    description: 'Test description',
    id: 644,
    isAssignableToDepartment: true,
    name: 'Room test 2',
    order: 30,
    parentLocationId: 641,
    updatedAt: '2024-09-02T12:35:20.170Z',
  },
  {
    createdAt: '2024-09-02T12:35:22.818Z',
    description: 'Test description',
    id: 645,
    isAssignableToDepartment: true,
    name: 'Room test 3',
    order: 30,
    parentLocationId: 641,
    updatedAt: '2024-09-02T12:35:22.818Z',
  },
  {
    createdAt: '2024-09-02T12:35:28.988Z',
    description: 'Test description',
    id: 646,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 31,
    parentLocationId: 645,
    updatedAt: '2024-09-02T12:35:28.988Z',
  },
  {
    createdAt: '2024-09-02T12:35:35.176Z',
    description: 'Test description',
    id: 647,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 32,
    parentLocationId: 645,
    updatedAt: '2024-09-02T12:35:35.176Z',
  },
  {
    createdAt: '2024-09-02T12:35:41.400Z',
    description: 'Test description',
    id: 648,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 33,
    parentLocationId: 647,
    updatedAt: '2024-09-02T12:35:41.400Z',
  },
  {
    createdAt: '2024-09-02T12:35:47.604Z',
    description: 'Test description',
    id: 649,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 34,
    parentLocationId: 647,
    updatedAt: '2024-09-02T12:35:47.604Z',
  },
  {
    createdAt: '2024-09-02T12:35:58.378Z',
    description: 'Test description',
    id: 650,
    isAssignableToDepartment: true,
    name: 'Location test 1',
    order: 31,
    parentLocationId: 644,
    updatedAt: '2024-09-02T12:35:58.378Z',
  },
  {
    createdAt: '2024-09-02T12:36:02.302Z',
    description: 'Test description',
    id: 651,
    isAssignableToDepartment: true,
    name: 'Location test 2',
    order: 32,
    parentLocationId: 644,
    updatedAt: '2024-09-02T12:36:02.302Z',
  },
  {
    createdAt: '2024-09-02T12:36:06.640Z',
    description: 'Test description',
    id: 652,
    isAssignableToDepartment: true,
    name: 'Location test 3',
    order: 33,
    parentLocationId: 644,
    updatedAt: '2024-09-02T12:36:06.640Z',
  },
  {
    createdAt: '2024-09-02T12:36:21.646Z',
    description: 'Test description',
    id: 653,
    isAssignableToDepartment: true,
    name: 'Shelf test 1',
    order: 34,
    parentLocationId: 641,
    updatedAt: '2024-09-02T12:36:35.186Z',
  },
  {
    createdAt: '2024-09-02T12:36:25.493Z',
    description: 'Test description',
    id: 654,
    isAssignableToDepartment: true,
    name: 'Shelf test 2',
    order: 35,
    parentLocationId: 644,
    updatedAt: '2024-09-02T12:36:35.186Z',
  },
]
