// Components
import Modal from 'components/molecules/Modal/Modal'
import Select from 'components/molecules/Select/Select'
import Button from 'components/molecules/Button/Button'
import TextInput from 'components/molecules/TextInput/TextInput'
import { IconButton, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FileUpload } from '@mui/icons-material'
import { DataTable } from 'components/molecules/DataTable/DataTable'

// Types
import { AddNewLaborCostProps } from '../LaborCost.types'

// Logic
import useAddNewLaborCostLogic from './AddNewLaborCost.logic'

// Style
import './AddNewLaborCost.scss'

// Other
import { uploadedLaborCostTableHeaders } from '../tableData'
import classNames from 'classnames'

const AddNewLaborCost = ({
  open,
  mode,
  data,
  isLoading,
  onSave,
  onClose,
}: AddNewLaborCostProps) => {
  const {
    errors,
    control,
    isManual,
    isUpdate,
    isUpload,
    procedures,
    isDisabled,
    fileInputRef,
    uploadedData,
    proceduresData,
    isLoadingProcedures,
    onSubmit,
    handleSubmit,
    setUploadedData,
    handleFileUpload,
    triggerFileUpload,
    handleChangeUploadOption,
    handleSubmitUploadedData,
  } = useAddNewLaborCostLogic({
    open,
    mode,
    data,
    isLoading,
    onSave,
    onClose,
  })

  const Actions = () => (
    <div className="actions">
      <Button
        onClick={(e) => onClose && onClose(e, 'backdropClick')}
        variant="outlined"
      >
        Cancel
      </Button>
      {isUpload && uploadedData.length > 0 ? (
        <Button onClick={() => setUploadedData([])} variant="outlined">
          Delete
        </Button>
      ) : null}
      <Button
        isLoading={isLoading}
        type={isManual || isUpdate ? 'submit' : 'button'}
        variant="contained"
        onClick={handleSubmitUploadedData}
        disabled={isDisabled}
      >
        {isUpdate ? 'Update' : 'Add'}
      </Button>
    </div>
  )

  return (
    <Modal open={open} onClose={onClose}>
      <form
        className={classNames('add-new-labor-cost-container', {
          'is-upload-mode': isUpload,
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="header">
          <Typography variant="h2">{`${
            isUpdate ? 'Edit' : 'Add New'
          } Labor Cost`}</Typography>
          {!isUpdate && (
            <Button onClick={handleChangeUploadOption} variant="contained">
              {isUpload ? 'Add Manually' : 'Upload File'}
            </Button>
          )}
        </div>
        {isUpload ? (
          <div
            className={classNames('upload-body', {
              hasData: uploadedData.length > 0,
            })}
          >
            {uploadedData.length > 0 ? (
              <div className="table-container">
                <DataTable
                  data-testid="labor-cost-table"
                  tableHeaders={uploadedLaborCostTableHeaders}
                  tableRows={proceduresData || []}
                  isLoading={false}
                  isErrored={false}
                  disableSorting
                />

                <Actions />
              </div>
            ) : (
              <div className="empty-state">
                <Typography className="helper-text">
                  You can use the <span>MedGEO</span> labor cost template <br />
                  to fill your data and upload it easily.
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    const baseURL =
                      'https://s3.us-east-1.amazonaws.com/medgeo.com/MedGEO_LaborCost_Template.xlsx'
                    window.open(baseURL, '_blank')
                  }}
                >
                  Download Excel Template
                </Button>

                <div className="upload-container">
                  <IconButton
                    color="primary"
                    className="upload-icon"
                    onClick={triggerFileUpload}
                  >
                    <FileUpload />
                    <Typography variant="caption">Upload File</Typography>
                  </IconButton>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="manual-body">
            <Controller
              name="procedureType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Procedure name cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <Select
                  {...field}
                  selectedValue={value?.name}
                  onSelect={onChange}
                  label="Procedure Name*"
                  placeholder="Procedure Name"
                  options={procedures}
                  error={!!errors.procedureType}
                  helperText={errors.procedureType?.message as string}
                  isLoading={isLoadingProcedures}
                  disabled={isUpdate}
                  shrink={!!value}
                />
              )}
            />

            <Controller
              name="rnCost"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: 'RN Cost cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <TextInput
                  {...field}
                  value={value}
                  handleChangeFormData={onChange}
                  variant="outlined"
                  placeholder="RN Cost"
                  label="RN Cost*"
                  type="number"
                  error={!!errors.rnCost}
                  helperText={errors.rnCost?.message}
                  shrink={!!value}
                />
              )}
            />

            <Controller
              name="scrubTechCost"
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: 'Scrub Tech Cost cannot be empty',
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <TextInput
                  {...field}
                  value={value}
                  handleChangeFormData={onChange}
                  variant="outlined"
                  placeholder="Scrub Tech Cost"
                  label="Scrub Tech Cost*"
                  type="number"
                  error={!!errors.scrubTechCost}
                  helperText={errors.scrubTechCost?.message}
                  shrink={!!value}
                />
              )}
            />
            <Actions />
          </div>
        )}
      </form>
    </Modal>
  )
}

export default AddNewLaborCost
