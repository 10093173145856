import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { ArrowBack, CameraAlt, FlipCameraIos } from '@mui/icons-material'
import classNames from 'classnames'

import useAnalyzeTrayLogic from './AnalyzeTray.logic'
import { useAddTrayContext } from '../AddTray.context'

import { LoadingButton } from 'components/mui'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import TrayAnalysisContent from 'components/organisms/TrayAnalysisContent/TrayAnalysisContent'

import './AnalyzeTray.scss'
import { formatToTitleCase } from 'lib/utils/formatToTitleCase'

const AnalyzeTray = () => {
  const {
    handleImageCapture,
    videoRef,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    isTrayMapDrawerOpen,
    handleTrayMapDrawerClose,
    isSubmitting,
    handleAnalysisClick,
    handleTrayTypeChange,
    handleBackClick,
    hasPlates,
    shouldConfirmButtonBeDisabled,
    handleConfirm,
    parLevel,
    handleTrayParLevelChange,
    isInstrumentTray,
    description,
    handleDescriptionChange,
  } = useAnalyzeTrayLogic()
  const { trayImage, selectedTrayType, isAnalysisComplete } =
    useAddTrayContext()

  return (
    <div className="analyze-tray-page_container">
      <div className="header_wrapper">
        <Button size="small" className="back-button" onClick={handleBackClick}>
          <ArrowBack sx={{ mr: 0.5 }} /> BACK
        </Button>

        <Typography variant="h1">
          Capture {!isInstrumentTray && '& Analyze '}Tray
        </Typography>
      </div>

      <div className="main_content">
        <div className="image_container">
          {!trayImage ? (
            <Typography variant="body1">
              Capture {!isInstrumentTray && 'and Analyze '}Tray
            </Typography>
          ) : (
            <div className="tray_img">
              {trayImage.isUploading && (
                <div className="loading_container">
                  <CircularProgress className="loader" />
                </div>
              )}

              <img src={trayImage.src} alt="Tray" />
            </div>
          )}

          {trayImage && !isInstrumentTray && (
            <LoadingButton
              dataTestId="add-tray-submit_button"
              loading={isSubmitting}
              disabled={!trayImage || isSubmitting || trayImage.isUploading}
              onClick={handleAnalysisClick}
              variant="outlined"
            >
              {isAnalysisComplete
                ? 'View Analysis'
                : 'Upload Implantable Tray Image for Analysis'}
            </LoadingButton>
          )}

          <DialogDrawer
            open={isTrayMapDrawerOpen}
            onClose={handleTrayMapDrawerClose}
            height="95%"
            overflowY
          >
            <TrayAnalysisContent
              trayType={selectedTrayType}
              onClose={handleTrayMapDrawerClose}
              trayImg={trayImage?.src}
            />
          </DialogDrawer>
        </div>

        <div className="capture_wrapper">
          <div className="camera_container">
            <Skeleton className="skeleton" />

            <div className="camera-select_container">
              <LoadingButton
                className="camera-menu_button"
                onClick={handleToggleCameraMenu}
              >
                <FlipCameraIos />
              </LoadingButton>

              <div
                className="camera-options"
                style={{
                  display: isCameraMenuOpen ? 'flex' : 'none',
                  opacity: isCameraMenuOpen ? 1 : 0,
                }}
              >
                {cameras.map((camera) => (
                  <div
                    key={camera.deviceId}
                    className={classNames('camera', {
                      active: camera.deviceId === activeCamera?.deviceId,
                    })}
                    onClick={() => handleSelectCamera(camera)}
                  >
                    <Typography variant="body2" className="camera-text">
                      {camera.label}
                    </Typography>

                    <Typography variant="body2" className="camera-text">
                      {camera?.cameraType}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>

            <LoadingButton
              disabled={trayImage?.isUploading || !selectedTrayType}
              loading={trayImage?.isUploading}
              onClick={() => handleImageCapture(true)}
              className="capture-photo_button"
            >
              {!trayImage?.isUploading && <CameraAlt />}
            </LoadingButton>

            <video className="feed" ref={videoRef} autoPlay muted playsInline />
          </div>

          <FormControl fullWidth>
            <Select
              fullWidth
              variant="outlined"
              displayEmpty
              renderValue={(selected) =>
                !selected ? (
                  <Typography variant="body1" color="gray">
                    Select Tray Type (required)
                  </Typography>
                ) : (
                  formatToTitleCase(selected)
                )
              }
              value={selectedTrayType || ''}
              onChange={handleTrayTypeChange}
            >
              <MenuItem disabled value="">
                Select Tray Type
              </MenuItem>
              {[
                'Stryker Screw Caddy',
                'Stryker Upper Face',
                'Stryker Middle Face',
                'Instrument',
              ].map((trayType) => (
                <MenuItem
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  key={trayType}
                  value={trayType}
                >
                  {trayType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(hasPlates || isInstrumentTray) && (
            <FormControl className="par-level_container" fullWidth>
              <Typography variant="h3" component="label">
                Tray Par Level
              </Typography>

              <TextField
                autoComplete="off"
                placeholder="Tray Par Level"
                fullWidth
                type="number"
                value={parLevel}
                onChange={handleTrayParLevelChange}
                variant="outlined"
              />
            </FormControl>
          )}

          <TextField
            autoComplete="off"
            placeholder="Enter Tray Details/ Description (required)"
            fullWidth
            multiline
            minRows={4}
            maxRows={7}
            type="text"
            value={description}
            onChange={handleDescriptionChange}
            variant="outlined"
          />

          <LoadingButton
            disabled={shouldConfirmButtonBeDisabled}
            onClick={handleConfirm}
          >
            Confirm Tray
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default AnalyzeTray
