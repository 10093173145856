import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider, getOrgId, PROVIDER_PROPS } from 'app/Auth'
import ApolloProvider from 'lib/apollo'
import { UserProvider } from './User'
import { ThemeProvider } from '@mui/material/styles'
import { getSubdomainName } from 'lib/utils/getSubdomainName'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import customTheme from 'assets/styles/customTheme'
import { DashboardProvider } from 'lib/context/DashboardContext'
import { withLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'
import ToastProvider from 'common/ToastProvider'

const { subdomainName } = getSubdomainName()
const orgId = getOrgId(subdomainName)

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID ?? '',
  context: {
    kind: 'user',
    key: orgId ?? PROVIDER_PROPS.organization ?? 'default_key',
  },
  options: {
    logger: basicLogger({ level: 'none' }),
  },
})(({ children }) => <>{children}</>)

function AfterRouterProviders({ children }: { children: React.ReactNode }) {
  const { subdomainName } = getSubdomainName()

  const urlParams = new URLSearchParams(window.location.search)
  const email = urlParams.get('email') ?? undefined

  return (
    <Auth0Provider email={email} orgId={getOrgId(subdomainName)}>
      <ApolloProvider>
        <UserProvider>
          <ToastProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DashboardProvider>{children}</DashboardProvider>
            </LocalizationProvider>
          </ToastProvider>
        </UserProvider>
      </ApolloProvider>
    </Auth0Provider>
  )
}

function Providers({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <ThemeProvider theme={customTheme}>
          <BrowserRouter>
            <LDProvider>
              <AfterRouterProviders>{children}</AfterRouterProviders>
            </LDProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ToastProvider>
    </QueryClientProvider>
  )
}

export default Providers
